import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPresentationState } from './presentation.state';

export const getPresentationSelectorsState =
  createFeatureSelector<IPresentationState>('presentationReduce');

export const getPresentationSelectors = createSelector(
  getPresentationSelectorsState,
  (store: any) => {
    return store;
  }
);
