import { Component, OnInit } from '@angular/core';
import { ISettingsState } from '../../../store/settings.state';
import { Observable } from 'rxjs';
import { getSettingsSelectors } from '../../../store/settings.selectors';
import { Store } from '@ngrx/store';
import { setSettingsAction } from '../../../store/settings.actions';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { InputToastComponent } from 'src/app/components/input-toast/input-toast.component';

@Component({
  selector: 'app-in-cash-payment',
  templateUrl: './in-cash-payment.component.html',
  styleUrls: ['./in-cash-payment.component.scss'],
})
export class InCashPaymentComponent implements OnInit {
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 2;

  // consts default
  percentageLimit: number = 30;

  // string values
  financialConsultantValueBr = '';
  advisorValueBr = '';

  financialConsultantValueSimulated: string;
  advisorValueSimulated: string;

  financialConsultantValueWithDiscountBr = '';
  advisorValueWithDiscountBr = '';

  constructor(private store: Store, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };

      this.financialConsultantValueSimulated = this.formatToBRL(
        this.dataStoreSetting.financialConsultantSimulatedValue
      );
      this.advisorValueSimulated = this.formatToBRL(
        this.dataStoreSetting.financialConsultantSimulatedValue +
          this.dataStoreSetting.followUpServiceValue
      );

      if (
        this.dataStoreSetting.financialConsultantSimulatedValue <
        this.dataStoreSetting.manualValue
      ) {
        this.financialConsultantValueSimulated = this.formatToBRL(
          this.dataStoreSetting.manualValue
        );
        this.advisorValueSimulated = this.formatToBRL(
          this.dataStoreSetting.manualValue +
            this.dataStoreSetting.followUpServiceValue
        );
      }

      this.calculatePercentage();
    });
  }

  setState(data: ISettingsState) {
    this.store.dispatch(setSettingsAction(data));
  }

  async applyingRule(): Promise<void> {
    // default
    this.financialConsultantValueBr = this.formatToBRL(
      this.dataStoreSetting.financialConsultantValue
    );
    this.advisorValueBr = this.formatToBRL(this.dataStoreSetting.advisorValue);

    // discount
    this.financialConsultantValueWithDiscountBr = this.formatToBRL(
      this.dataStoreSetting.financialConsultantValueWithDiscount
    );
    this.advisorValueWithDiscountBr = this.formatToBRL(
      this.dataStoreSetting.advisorValueWithDiscount
    );

    this.setState(this.dataStoreSetting);
  }

  applyingDiscountPercentage(percentage: number, value: number): number {
    if (percentage < 0 || percentage > 100) {
      return value;
    }

    if (value < 0) {
      return value;
    }

    const discount = (percentage / 100) * value;
    const discountedValue = value - discount;
    return discountedValue;
  }

  calculatePercentage() {
    if (this.dataStoreSetting.percentualDiscount > this.percentageLimit) {
      this.dataStoreSetting.percentualDiscount = this.percentageLimit;
      this.discountValueAlert();
    } else if (this.dataStoreSetting.percentualDiscount < 0) {
      this.dataStoreSetting.percentualDiscount = 0;
      this.discountValueAlert();
    } else if (this.dataStoreSetting.percentualDiscount.toString().length > 2) {
      this.dataStoreSetting.percentualDiscount = parseInt(
        this.dataStoreSetting.percentualDiscount.toString().substring(0, 2),
        10
      );
    }

    this.financialConsultantValueWithDiscountBr = this.formatToBRL(
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.financialConsultantSimulatedValue
      )
    );

    this.advisorValueWithDiscountBr = this.formatToBRL(
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.financialConsultantSimulatedValue +
          this.dataStoreSetting.followUpServiceValue
      )
    );

    if (
      this.dataStoreSetting.financialConsultantSimulatedValue <
      this.dataStoreSetting.manualValue
    ) {
      this.financialConsultantValueWithDiscountBr = this.formatToBRL(
        this.applyingDiscountPercentage(
          this.dataStoreSetting.percentualDiscount,
          this.dataStoreSetting.manualValue
        )
      );

      this.advisorValueWithDiscountBr = this.formatToBRL(
        this.applyingDiscountPercentage(
          this.dataStoreSetting.percentualDiscount,
          this.dataStoreSetting.manualValue +
            this.dataStoreSetting.followUpServiceValue
        )
      );
    }
  }

  changingPercentage() {
    this.calculatePercentage();
    this.applyingRule();
  }

  formatToBRL(value: number): string {
    if (isNaN(value)) {
      value = 0;
    }

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  discountValueAlert() {
    let title = 'Porcentagem de desconto inválida';
    let message = 'A porcentagem de desconto deve ser um número de 1% a 30%.';
    this._snackBar.openFromComponent(InputToastComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000,
      panelClass: 'alert-snackbar',
      data: { title, message },
    });
    this.dataStoreSetting.installments = 12;
    this.setState(this.dataStoreSetting);
  }
}
