<div class="input-group mb-3 mt-2">
  <span class="input-group-text" id="basic-addon1">{{ pref }}</span>
  <input
    type="text"
    class="form-control"
    [id]="elementID"
    name="current"
    [(ngModel)]="valueInput"
    (ngModelChange)="rewrite()"
  />
</div>
