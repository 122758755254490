import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IContactState } from './store/contact.state';
import { getContactSelectors } from './store/contact.selectors';
import { Store } from '@ngrx/store';
import { setContactAction } from './store/contact.actions';

@Component({
  selector: 'app-end-of-collection',
  templateUrl: './end-of-collection.component.html',
  styleUrls: ['./end-of-collection.component.scss'],
})
export class EndOfCollectionComponent implements OnInit {
  dataState$: Observable<IContactState>;
  dataState: IContactState = {
    check: false,
    listContacts: [],
  };

  dataLocal: IContactState = { ...this.dataState };

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
    this.addContactWithDelay();
  }

  getState(): void {
    this.dataState$ = this.store.select(getContactSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setState(data: IContactState): void {
    this.store.dispatch(setContactAction(data));
  }

  setValuesForm(): void {
    this.setState(this.dataState);
  }

  updateContact(data: any, index: number): void {
    if (this.isValidIndex(index)) {
      const updatedListContacts = [...this.dataState.listContacts];

      updatedListContacts[index] = { ...data };

      this.dataState.listContacts = updatedListContacts;
      this.setState(this.dataState);
    }
  }

  addContactWithDelay(): void {
    setTimeout(() => {
      this.addContact();
    }, 1000);
  }

  addContact(): void {
    if (!Array.isArray(this.dataState.listContacts)) {
      this.dataState.listContacts = [];
    }
    const newContact = {
      name: '',
      email: '',
      phone: '',
      obsevation: '',
    };

    this.dataState.listContacts = [...this.dataState.listContacts, newContact];
    this.setState(this.dataState);
    this.dataLocal = { ...this.dataState };
  }

  deleteContact(index: number): void {
    if (this.isValidIndex(index)) {
      const updatedListContacts = [...this.dataState.listContacts];
      updatedListContacts.splice(index, 1);
      this.dataState.listContacts = updatedListContacts;
      this.setState(this.dataState);
      this.dataLocal = { ...this.dataState };
    }
  }

  isValidIndex(index: number): boolean {
    return index >= 0 && index < this.dataState.listContacts.length;
  }
}
