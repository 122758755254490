<div class="container-main__slider">
  <div class="slider-content">
    <div>
      <img
        src="../../../assets/img-gray.png"
        width="528px"
        height="339px"
        alt=""
      />
    </div>

    <div class="slider__details">
      <h1>1</h1>
      <h2>Apresentação da consultoria</h2>
      <p>
        Apresente ao seu cliente o processo de consultoria, indicando
        beneficios, entregaveis e resultados possiveis.
      </p>
    </div>
  </div>
</div>
