import { createReducer, on } from '@ngrx/store';
import { setInvestmentsAction } from './investments.actions';
import { IInvestmentsState } from './investments.state';

export const initialInvestmentsState: IInvestmentsState = {
  check: false,
  investments: undefined,
  accessorPayment: undefined,
  amount: 0,
  feeModel: undefined,
  interest: undefined,
};

export const investmentsReduce = createReducer(
  initialInvestmentsState,
  on(
    setInvestmentsAction,
    (
      state,
      { check, investments, accessorPayment, feeModel, amount, interest }
    ) => ({
      ...state,
      check: check,
      investments: investments,
      accessorPayment: accessorPayment,
      amount: amount,
      feeModel: feeModel,
      interest: interest,
    })
  )
);
