import { createReducer, on } from '@ngrx/store';

import { IJobOfferState } from './job-offer.state';
import { initJobOfferAction, setJobOfferAction } from './job-offer.actions';

const initialJobOfferState: IJobOfferState = {
  check: false,
  consultancyProject: '',
  followupService: '',
  amount: 0,
  discountedPrice: 0,
  ticketValue: 0,
  creditCardValue: 3000,
  creditCardValueWithDiscount: 3000,
  inCashValue: 3000,
  inCashValueWithDiscount: 3000,
};

export const jobOfferReduce = createReducer(
  initialJobOfferState,
  on(setJobOfferAction, (state, data: IJobOfferState) => ({
    ...state,
    check: data.check,
    consultancyProject: data.consultancyProject,
    followupService: data.followupService,
    amount: data.amount,
    discountedPrice: data.discountedPrice,
    ticketValue: data.ticketValue,
    creditCardValue: data.creditCardValue,
    creditCardValueWithDiscount: data.creditCardValueWithDiscount,
    inCashValue: data.inCashValue,
    inCashValueWithDiscount: data.inCashValueWithDiscount,
  }))
);
