import { createReducer, on } from '@ngrx/store';
import {
  initHealthInsuranceAction,
  setHealthInsuranceAction,
} from './healthInsurance.actions';
import { IHealthInsuranceState } from './healthInsurance.state';

export const initialHealthInsuranceState: IHealthInsuranceState = {
  check: false,
  insurance: undefined,
  insuranceName: '',
  interest: undefined,
};

export const healthInsuranceReduce = createReducer(
  initialHealthInsuranceState,
  on(
    setHealthInsuranceAction,
    (state, { check, insurance, insuranceName, interest }) => ({
      ...state,
      check: check,
      insurance: insurance,
      insuranceName: insuranceName,
      interest: interest,
    })
  )
);
