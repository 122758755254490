import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IFamilyFormState } from './family-form.state';

export const getFamilyFormSelectorsState =
  createFeatureSelector<IFamilyFormState>('familyFormReduce');

export const getFamilyFormSelectors = createSelector(
  getFamilyFormSelectorsState,
  (store: IFamilyFormState) => {
    return store;
  }
);
