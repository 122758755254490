<mat-accordion class="row">
  <mat-expansion-panel
    id="marriage-panel"
    class="col-12 p-0"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [expanded]="panelOpenState"
  >
    <mat-expansion-panel-header
      class="pt-2 pb-2 mat-expansion-panel-header"
      hideToggle
    >
      <mat-panel-title
        class="d-flex justify-content-between align-items-center pt-4 pb-4"
      >
        <div class="d-flex justify-content-center gap-2 align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 40 40"
            fill="none"
          >
            <path
              d="M27.8125 5C24.5859 5 21.7609 6.3875 20 8.73281C18.2391 6.3875 15.4141 5 12.1875 5C9.6191 5.00289 7.15673 6.02447 5.3406 7.8406C3.52447 9.65673 2.50289 12.1191 2.5 14.6875C2.5 25.625 18.7172 34.4781 19.4078 34.8438C19.5898 34.9417 19.7933 34.9929 20 34.9929C20.2067 34.9929 20.4102 34.9417 20.5922 34.8438C21.2828 34.4781 37.5 25.625 37.5 14.6875C37.4971 12.1191 36.4755 9.65673 34.6594 7.8406C32.8433 6.02447 30.3809 5.00289 27.8125 5ZM20 32.3125C17.1469 30.65 5 23.0766 5 14.6875C5.00248 12.782 5.76053 10.9553 7.10791 9.60791C8.45529 8.26053 10.282 7.50248 12.1875 7.5C15.2266 7.5 17.7781 9.11875 18.8438 11.7188C18.9379 11.948 19.0981 12.1441 19.304 12.2821C19.5099 12.4201 19.7521 12.4938 20 12.4938C20.2479 12.4938 20.4901 12.4201 20.696 12.2821C20.9019 12.1441 21.0621 11.948 21.1562 11.7188C22.2219 9.11406 24.7734 7.5 27.8125 7.5C29.718 7.50248 31.5447 8.26053 32.8921 9.60791C34.2395 10.9553 34.9975 12.782 35 14.6875C35 23.0641 22.85 30.6484 20 32.3125Z"
              fill="#2685CA"
            />
          </svg>
          <h1 class="mt-3 fs-5">Casamento</h1>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              *ngIf="dataStoreMarriage.check"
              d="M16.2806 9.21937C16.3504 9.28903 16.4057 9.37175 16.4434 9.46279C16.4812 9.55384 16.5006 9.65144 16.5006 9.75C16.5006 9.84856 16.4812 9.94616 16.4434 10.0372C16.4057 10.1283 16.3504 10.211 16.2806 10.2806L11.0306 15.5306C10.961 15.6004 10.8783 15.6557 10.7872 15.6934C10.6962 15.7312 10.5986 15.7506 10.5 15.7506C10.4014 15.7506 10.3038 15.7312 10.2128 15.6934C10.1218 15.6557 10.039 15.6004 9.96938 15.5306L7.71938 13.2806C7.57865 13.1399 7.49959 12.949 7.49959 12.75C7.49959 12.551 7.57865 12.3601 7.71938 12.2194C7.86011 12.0786 8.05098 11.9996 8.25 11.9996C8.44903 11.9996 8.6399 12.0786 8.78063 12.2194L10.5 13.9397L15.2194 9.21937C15.289 9.14964 15.3718 9.09432 15.4628 9.05658C15.5538 9.01884 15.6514 8.99941 15.75 8.99941C15.8486 8.99941 15.9462 9.01884 16.0372 9.05658C16.1283 9.09432 16.211 9.14964 16.2806 9.21937ZM21.75 12C21.75 13.9284 21.1782 15.8134 20.1068 17.4168C19.0355 19.0202 17.5127 20.2699 15.7312 21.0078C13.9496 21.7458 11.9892 21.9389 10.0979 21.5627C8.20656 21.1865 6.46928 20.2579 5.10571 18.8943C3.74215 17.5307 2.81355 15.7934 2.43735 13.9021C2.06114 12.0108 2.25422 10.0504 2.99218 8.26884C3.73013 6.48726 4.97982 4.96451 6.58319 3.89317C8.18657 2.82183 10.0716 2.25 12 2.25C14.585 2.25273 17.0634 3.28084 18.8913 5.10872C20.7192 6.93661 21.7473 9.41498 21.75 12ZM20.25 12C20.25 10.3683 19.7661 8.77325 18.8596 7.41655C17.9531 6.05984 16.6646 5.00242 15.1571 4.37799C13.6497 3.75357 11.9909 3.59019 10.3905 3.90852C8.79017 4.22685 7.32016 5.01259 6.16637 6.16637C5.01259 7.32015 4.22685 8.79016 3.90853 10.3905C3.5902 11.9908 3.75358 13.6496 4.378 15.1571C5.00242 16.6646 6.05984 17.9531 7.41655 18.8596C8.77326 19.7661 10.3683 20.25 12 20.25C14.1873 20.2475 16.2843 19.3775 17.8309 17.8309C19.3775 16.2843 20.2475 14.1873 20.25 12Z"
              fill="#00A803"
            />
            <path
              *ngIf="!dataStoreMarriage.check"
              d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM11.25 12.75V7.5C11.25 7.30109 11.329 7.11032 11.4697 6.96967C11.6103 6.82902 11.8011 6.75 12 6.75C12.1989 6.75 12.3897 6.82902 12.5303 6.96967C12.671 7.11032 12.75 7.30109 12.75 7.5V12.75C12.75 12.9489 12.671 13.1397 12.5303 13.2803C12.3897 13.421 12.1989 13.5 12 13.5C11.8011 13.5 11.6103 13.421 11.4697 13.2803C11.329 13.1397 11.25 12.9489 11.25 12.75ZM13.125 16.125C13.125 16.3475 13.059 16.565 12.9354 16.75C12.8118 16.935 12.6361 17.0792 12.4305 17.1644C12.225 17.2495 11.9988 17.2718 11.7805 17.2284C11.5623 17.185 11.3618 17.0778 11.2045 16.9205C11.0472 16.7632 10.94 16.5627 10.8966 16.3445C10.8532 16.1262 10.8755 15.9 10.9606 15.6945C11.0458 15.4889 11.19 15.3132 11.375 15.1896C11.56 15.066 11.7775 15 12 15C12.2984 15 12.5845 15.1185 12.7955 15.3295C13.0065 15.5405 13.125 15.8266 13.125 16.125Z"
              fill="#A31B00"
            />
          </svg>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="container-fluid py-3">
      <div class="row mb-3 pb-3 border-bottom">
        <div class="col-12 col-md-12 d-flex flex-column">
          <label class="col-12">Por que escolheu esse objetivo?</label>
          <input
            id="marriageDescription"
            class="form-control mt-3"
            placeholder="Porque..."
            [(ngModel)]="dataStoreMarriage.description"
            name="description"
            (ngModelChange)="setValuesForm()"
            required=""
          />
        </div>
      </div>
      <div class="row mb-3 pb-3 border-bottom">
        <div class="col-12 col-md-12">
          <label class="w-100 mb-3"
            >Quando pretende realizar esse objetivo?*</label
          >
          <input
            id="marriageDate"
            type="date"
            name="datemarriage"
            class="col-12 col-sm-12 col-md-4 col-lg-4 p-2 rounded-1 form-control w-25"
            [(ngModel)]="dataStoreMarriage.date"
            (ngModelChange)="setValuesForm()"
          />
        </div>
      </div>
      <div class="row mb-3 border-bottom">
        <div class="col-12 col-md-12">
          <label class="col-12 mb-3"
            >Quanto pretende investir nesse objetivo?*</label
          >
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
              <label class="w-100">Minimo</label>
              <app-prefixed-input
                class=""
                id="marriageMinValue"
                [elementID]="'minValuemarriage'"
                [pref]="'R$'"
                (parentValue)="updateAmountMin($event)"
                (ngModelChange)="setValuesForm()"
                [callback]="dataStoreMarriage.minimumInvestment"
              ></app-prefixed-input>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
              <label class="w-100">Máximo</label>
              <app-prefixed-input
                class="w-100"
                id="marriageMaxValue"
                [elementID]="'maxValuemarriage'"
                [pref]="'R$'"
                (parentValue)="updateAmountMax($event)"
                (ngModelChange)="setValuesForm()"
                [callback]="dataStoreMarriage.maximumInvestment"
              ></app-prefixed-input>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 border-bottom">
        <div class="col-12">
          <label class="mb-3">Observações</label>
          <input
            id="marriageObservation"
            class="form-control mb-3"
            placeholder="Aqui você pode colocar qualquer anotação pertinente para o objetivo."
            [(ngModel)]="dataStoreMarriage.observation"
            (ngModelChange)="setValuesForm()"
            name="observation"
          />
        </div>
      </div>
    </div>

    <div class="d-flex flex-row justify-content-between">
      <app-remove-button
        id="marriageRemove"
        (click)="unCheck()"
      ></app-remove-button>
      <button
        id="marriage-save-btn"
        class="btn btn-primary px-2"
        mat-raised-button
        (click)="panelOpenState = false"
      >
        Salvar
        <svg
          class="mb-2"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="blue"
        >
          <path
            d="M21.5306 7.28063L9.53055 19.2806C9.4609 19.3504 9.37818 19.4057 9.28713 19.4434C9.19609 19.4812 9.09849 19.5006 8.99993 19.5006C8.90137 19.5006 8.80377 19.4812 8.71272 19.4434C8.62168 19.4057 8.53896 19.3504 8.4693 19.2806L3.2193 14.0306C3.07857 13.8899 2.99951 13.699 2.99951 13.5C2.99951 13.301 3.07857 13.1101 3.2193 12.9694C3.36003 12.8286 3.55091 12.7496 3.74993 12.7496C3.94895 12.7496 4.13982 12.8286 4.28055 12.9694L8.99993 17.6897L20.4693 6.21938C20.61 6.07865 20.8009 5.99959 20.9999 5.99959C21.199 5.99959 21.3898 6.07865 21.5306 6.21938C21.6713 6.36011 21.7503 6.55098 21.7503 6.75C21.7503 6.94903 21.6713 7.1399 21.5306 7.28063Z"
            fill="#F9F9FB"
          />
        </svg>
      </button>
    </div>
  </mat-expansion-panel>
</mat-accordion>
