import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-saved-toast',
  templateUrl: './saved-toast.component.html',
  styleUrls: ['./saved-toast.component.scss'],
})
export class SavedToastComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {
  }
}
