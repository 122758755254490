import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IJobOfferState } from './job-offer.state';

export const getJobOfferSelectorsState =
  createFeatureSelector<IJobOfferState>('jobOfferReduce');

export const getJobOfferSelectors = createSelector(
  getJobOfferSelectorsState,
  (store: any) => {
    return store;
  }
);

// Retirement
export const getJobOfferRetirementSelectorsState =
  createFeatureSelector<IJobOfferState>('jobOfferReduce');

export const getJobOfferRetirementSelectors = createSelector(
  getJobOfferRetirementSelectorsState,
  (store: any) => {
    return store;
  }
);
