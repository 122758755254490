import { createAction, props } from '@ngrx/store';

export const initPresentationAction = createAction(
  '[Presentation Page] Home page'
);

export const setPresentationAction = createAction(
  '[Presentation Page] set check',
  props<{ check: boolean }>()
);
