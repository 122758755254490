import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IIncomeTaxState } from './store/incomeTax.state';
import { getIncomeTaxSelectors } from './store/incomeTax.selectors';
import { setIncomeTaxAction } from './store/incomeTax.actions';

@Component({
  selector: 'app-income-tax',
  templateUrl: './income-tax.component.html',
  styleUrls: ['./income-tax.component.scss'],
})
export class IncomeTaxComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IIncomeTaxState>;
  dataState: IIncomeTaxState;

  ngOnInit(): void {
    this.getState();
  }

  setState(data: IIncomeTaxState) {
    this.store.dispatch(setIncomeTaxAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getIncomeTaxSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.typeTax != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }

  updateAmount(event): void {
    this.dataState.amount = event;
    this.setValuesForm();
  }
}
