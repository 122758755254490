import { Component, OnInit } from '@angular/core';
import { getBudgetSelectors } from 'src/app/modules/collect-data/components/budget/store/budget.selectors';
import { getCreditCardSelectors } from 'src/app/modules/collect-data/components/credit-card/store/credit-card.selectors';
import { getDebtsSelectors } from 'src/app/modules/collect-data/components/debts/store/debts.selectors';

import { IBudgetState } from 'src/app/modules/collect-data/components/budget/store/budget.state';
import { ICreditCardState } from 'src/app/modules/collect-data/components/credit-card/store/credit-card.state';
import { IDebtsState } from 'src/app/modules/collect-data/components/debts/store/debts.state';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-budget',
  templateUrl: './pdf-budget.component.html',
  styleUrls: ['./pdf-budget.component.scss'],
})
export class PdfBudgetComponent implements OnInit {
  dataStoreBudget$: Observable<any>;
  dataStoreBudget: IBudgetState;
  budget: string;
  montlyCostFormated: string;
  montlyRevenueFormated: string;
  annualRevenueFormated: string;

  dataStoreCreditCard$: Observable<any>;
  dataStoreCreditCard: ICreditCardState;
  creditCard: string;
  miles: string;

  dataStoreDebts$: Observable<any>;
  dataStoreDebts: IDebtsState;
  debts: string;
  confirmDebts: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getStateBudget();
    this.getStateCreditCard();
    this.getStateDebts();
  }

  getStateBudget() {
    this.dataStoreBudget$ = this.store.select(getBudgetSelectors);
    this.dataStoreBudget$.subscribe((value) => {
      this.dataStoreBudget = {
        ...(this.dataStoreBudget = value),
      };
    });

    this.budget = this.dataStoreBudget.idealBudget ? 'Sim' : 'Não';

    this.montlyCostFormated = this.dataStoreBudget.monthlyCost
      ? this.formatarMoeda(this.dataStoreBudget.monthlyCost)
      : '0';
    this.montlyRevenueFormated = this.dataStoreBudget.monthlyIncome
      ? this.formatarMoeda(this.dataStoreBudget.monthlyIncome)
      : '0';
    this.annualRevenueFormated = this.dataStoreBudget.annualRecipe
      ? this.formatarMoeda(this.dataStoreBudget.annualRecipe)
      : '0';
  }

  getStateCreditCard() {
    this.dataStoreCreditCard$ = this.store.select(getCreditCardSelectors);
    this.dataStoreCreditCard$.subscribe((value) => {
      this.dataStoreCreditCard = {
        ...(this.dataStoreCreditCard = value),
      };
    });

    this.creditCard = this.dataStoreCreditCard.creditCard ? 'Sim' : 'Não';
    this.miles = this.dataStoreCreditCard.miles ? 'Sim' : 'Não';
  }

  formatarMoeda(valor: number): string {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  getStateDebts() {
    this.dataStoreDebts$ = this.store.select(getDebtsSelectors);
    this.dataStoreDebts$.subscribe((value) => {
      this.dataStoreDebts = {
        ...(this.dataStoreDebts = value),
      };
    });

    this.debts = this.dataStoreDebts.debts ? 'Sim' : 'Não';
    this.confirmDebts = this.dataStoreDebts.confirmDebts
      ? 'Sim, gostaria de receber'
      : 'Não';
  }
}
