import { createReducer, on } from '@ngrx/store';
import { setOfferAction } from './offer.actions';
import { IOfferState } from './offer.state';

const initialOfferState: IOfferState = {
  manualValue: 3000,
  percentValue: 3,
  followUpServiceValue: 1200,
  simulatedValue: 0,
};

export const OfferReduce = createReducer(
  initialOfferState,
  on(setOfferAction, (state, data: IOfferState) => ({
    ...state,
    manualValue: data.manualValue,
    percentValue: data.percentValue,
    followUpServiceValue: data.followUpServiceValue,
    simulatedValue: data.simulatedValue,
  }))
);
