<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #6b7680;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M9 13C9 12.7348 9.10536 12.4804 9.29289 12.2929C9.48043 12.1054 9.73478 12 10 12H22C22.2652 12 22.5196 12.1054 22.7071 12.2929C22.8946 12.4804 23 12.7348 23 13C23 13.2652 22.8946 13.5196 22.7071 13.7071C22.5196 13.8946 22.2652 14 22 14H10C9.73478 14 9.48043 13.8946 9.29289 13.7071C9.10536 13.5196 9 13.2652 9 13ZM10 18H22C22.2652 18 22.5196 17.8946 22.7071 17.7071C22.8946 17.5196 23 17.2652 23 17C23 16.7348 22.8946 16.4804 22.7071 16.2929C22.5196 16.1054 22.2652 16 22 16H10C9.73478 16 9.48043 16.1054 9.29289 16.2929C9.10536 16.4804 9 16.7348 9 17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM29 7V26C28.9999 26.1704 28.9563 26.338 28.8732 26.4869C28.7901 26.6357 28.6704 26.7608 28.5254 26.8503C28.3803 26.9399 28.2148 26.9909 28.0446 26.9985C27.8743 27.0061 27.7049 26.97 27.5525 26.8937L24 25.1175L20.4475 26.8937C20.3086 26.9633 20.1554 26.9995 20 26.9995C19.8446 26.9995 19.6914 26.9633 19.5525 26.8937L16 25.1175L12.4475 26.8937C12.3086 26.9633 12.1554 26.9995 12 26.9995C11.8446 26.9995 11.6914 26.9633 11.5525 26.8937L8 25.1175L4.4475 26.8937C4.29508 26.97 4.12569 27.0061 3.95543 26.9985C3.78516 26.9909 3.61965 26.9399 3.47463 26.8503C3.32961 26.7608 3.20988 26.6357 3.12681 26.4869C3.04374 26.338 3.00009 26.1704 3 26V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H27C27.5304 5 28.0391 5.21071 28.4142 5.58579C28.7893 5.96086 29 6.46957 29 7ZM27 7H5V24.3825L7.5525 23.105C7.69143 23.0355 7.84465 22.9993 8 22.9993C8.15535 22.9993 8.30857 23.0355 8.4475 23.105L12 24.8825L15.5525 23.105C15.6914 23.0355 15.8446 22.9993 16 22.9993C16.1554 22.9993 16.3086 23.0355 16.4475 23.105L20 24.8825L23.5525 23.105C23.6914 23.0355 23.8446 22.9993 24 22.9993C24.1554 22.9993 24.3086 23.0355 24.4475 23.105L27 24.3825V7Z"
      fill="#6B7680"
    />
  </svg>
  <h4 class="fs-5 mt-3">Imposto de Renda</h4>
</header>

<form class="px-4 border rounded">
  <div class="py-4">
    <label class="form-label fs-6">
      Você realiza a declaração do Imposto de Renda (IR) em qual
      modalidade?</label
    >
    <div class="d-flex flex-column justify-content-start gap-2">
      <div class="d-flex flex-row align-items-start gap-2 form-check">
        <input
          class="mt-1 form-check-input"
          type="radio"
          id="Simplificada"
          name="tax"
          value="Simplificada"
          [(ngModel)]="dataState.typeTax"
          (ngModelChange)="setValuesForm()"
        />
        <label for="Simplificada">Simplificada</label>
      </div>

      <div class="d-flex flex-row align-items-start gap-2 form-check">
        <input
          class="mt-1 form-check-input"
          type="radio"
          id="Completa"
          value="Completa"
          name="tax"
          [(ngModel)]="dataState.typeTax"
          (ngModelChange)="setValuesForm()"
        />
        <label for="Completa">Completa</label>
      </div>

      <div class="d-flex flex-row align-items-start gap-2 form-check">
        <input
          class="mt-1 form-check-input"
          type="radio"
          id="Isento"
          value="Isento"
          name="tax"
          [(ngModel)]="dataState.typeTax"
          (ngModelChange)="setValuesForm()"
        />
        <label for="Isento">Sou isento(a)</label>
      </div>
    </div>
  </div>

  <div class="py-4 border-top">
    <label class="form-label fs-6"
      >Restituiu IR na declaração do ano anterior?</label
    >

    <div class="d-flex flex-column justify-content-start gap-2">
      <div class="d-flex flex-row align-items-start gap-2 form-check">
        <input
          class="mt-1 form-check-input"
          type="radio"
          id="radio-sim"
          value="Sim"
          name="lastIncomeTax"
          [(ngModel)]="dataState.refunded"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-sim">Sim</label>
      </div>

      <div class="d-flex flex-row align-items-start gap-2 form-check">
        <input
          class="mt-1 form-check-input"
          type="radio"
          id="radio-nao"
          value="Não"
          name="lastIncomeTax"
          [(ngModel)]="dataState.refunded"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-nao">Não</label>
      </div>

      <div class="d-flex flex-row align-items-start gap-2 form-check">
        <input
          class="mt-1 form-check-input"
          type="radio"
          id="anterior-isento"
          value="Isento"
          name="lastIncomeTax"
          [(ngModel)]="dataState.refunded"
          (ngModelChange)="setValuesForm()"
        />
        <label for="anterior-isento">Sou isento(a)</label>
      </div>
    </div>
  </div>

  <div class="border-top py-4" *ngIf="dataState.refunded == 'Sim'">
    <label class="form-label fs-6">Qual foi o valor restituído (aprox.)?</label>
    <app-prefixed-input
      [elementID]="'refunded'"
      [pref]="'R$'"
      (parentValue)="updateAmount($event)"
      [callback]="dataState.amount"
    ></app-prefixed-input>
  </div>
</form>
