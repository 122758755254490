import { createReducer, on } from '@ngrx/store';
import { ISettingPlansState } from './setting-plans.state';
import { setSettingPlansAction } from './setting-plans.action';

const initialSettingPlansState: ISettingPlansState = {
  planNameOne: '',
  valuePlanOne: 0,
  paymentChoiceOne: '',
  automaticSelected: false,
  planNameTwo: '',
  valuePlanTwo: 0,
  paymentChoiceTwo: ''
};

export const SettingPlansReduce = createReducer(
  initialSettingPlansState,
  on(setSettingPlansAction, (state, data: ISettingPlansState) => ({
    ...state,
    planNameOne: data.planNameOne,
    valuePlanOne: data.valuePlanOne,
    paymentChoiceOne: data.paymentChoiceOne,
    automaticSelected: data.automaticSelected,
    planNameTwo: data.planNameTwo,
    valuePlanTwo: data.valuePlanTwo,
    paymentChoiceTwo: data.paymentChoiceTwo,
  }))
);
