import { createReducer, on } from '@ngrx/store';

import { IDebtsState } from './debts.state';
import { initDebtsAction, setDebtsAction } from './debts.actions';

export const initialDebtsState: IDebtsState = {
  check: false,
  debts: undefined,
  confirmDebts: undefined,
  listDebts: [],
};

export const debtsReduce = createReducer(
  initialDebtsState,
  on(setDebtsAction, (state, { check, debts, confirmDebts, listDebts }) => ({
    ...state,
    check: check,
    debts: debts,
    confirmDebts: confirmDebts,
    listDebts: listDebts,
  }))
);
