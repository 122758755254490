<div class="mt-4 mb-2">
  <h2>Informações</h2>
  <div class="card">
    <div class="card-body">
      <p class="fw-light fw-lighter"><small>*Campos obrigatórios</small></p>
      <div class="mb-3">
        <label for="consultantName" class="form-label">Seu nome *</label>
        <input
          type="text"
          class="form-control"
          id="consultantName"
          [(ngModel)]="dataStoreSetting.consultantName"
          (change)="inputChange()"
          required
        />
      </div>
      <div class="mb-3">
        <label for="clientName" class="form-label">Nome do(a) cliente *</label>
        <input
          type="text"
          class="form-control"
          id="clientName"
          [(ngModel)]="dataStoreSetting.clientName"
          (change)="inputChange()"
          required
        />
      </div>
    </div>
  </div>
</div>
