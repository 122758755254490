import { createReducer, on } from '@ngrx/store';

import { IBudgetState } from './budget.state';
import { initBudgetAction, setBudgetAction } from './budget.actions';

export const initialBudgetState: IBudgetState = {
  check: false,
  idealBudget: undefined,
  monthlyCost: 0,
  monthlyIncome: 0,
  annualRecipe: 0,
};

export const budgetReduce = createReducer(
  initialBudgetState,
  on(
    setBudgetAction,
    (
      state,
      { check, idealBudget, monthlyCost, annualRecipe, monthlyIncome }
    ) => ({
      ...state,
      check: check,
      idealBudget: idealBudget,
      monthlyCost: monthlyCost,
      monthlyIncome: monthlyIncome,
      annualRecipe: annualRecipe,
    })
  )
);
