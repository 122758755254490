import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { setOnboardingAction } from './store/home.actions';
import { getOnboardingSelectors } from './store/home.selectors';
import { Store } from '@ngrx/store';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ToastComponent } from 'src/app/components/toast/toast.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private store: Store, private _snackBar: MatSnackBar) {}
  ngOnInit(): void {}

  check$: Observable<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 2;

  startTrial(): void {
    this.setStateOnboarding(true);
    this.getStateOnboarding();
    // this.defaultPricingAlert();
  }

  setStateOnboarding(check: boolean) {
    this.store.dispatch(setOnboardingAction({ check }));
  }

  getStateOnboarding() {
    this.check$ = this.store.select(getOnboardingSelectors);
    // Assine ao Observable para obter o valor do seletor
    this.check$.subscribe((value) => {});
  }

  defaultPricingAlert() {
    let title = 'Novo Guia iniciado';
    this._snackBar.openFromComponent(ToastComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000,
      panelClass: 'green-snackbar',
      data: { title },
    });
  }
}
