import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICollectDataState } from './collect-data.state';

export const getCollectDataSelectorsState =
  createFeatureSelector<ICollectDataState>('collectDataReduce');

export const getCollectDataSelectors = createSelector(
  getCollectDataSelectorsState,
  (store: any) => {
    return store;
  }
);
