import { createReducer, on } from '@ngrx/store';
import { setPropertiesAction } from './properties.actions';
import { IPropertiesState } from './properties.state';

export const initialPropertiesState: IPropertiesState = {
  check: false,
  properties: undefined,
  interest: undefined,
  listProperties: [],
};

export const propertiesReduce = createReducer(
  initialPropertiesState,
  on(
    setPropertiesAction,
    (state, { check, properties, interest, listProperties }) => ({
      ...state,
      check: check,
      properties: properties,
      interest: interest,
      listProperties: listProperties,
    })
  )
);
