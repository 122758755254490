import { createReducer, on } from '@ngrx/store';
import { setConsultingAction } from './consulting.actions';
import { IConsultingState } from './consulting.state';

export const initialconsultingState: IConsultingState = {
  interest: false,
};

export const ConsultingReduce = createReducer(
  initialconsultingState,
  on(setConsultingAction, (state, { interest }) => ({
    ...state,
    interest: interest,
  }))
);
