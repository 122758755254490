import { createReducer, on } from '@ngrx/store';
import { setContactAction } from './contact.actions';
import { IContactState } from './contact.state';

export const initialContactState: IContactState = {
  check: false,
  listContacts: [],
};

export const ContactReduce = createReducer(
  initialContactState,
  on(setContactAction, (state, { check, listContacts }) => ({
    ...state,
    check: check,
    listContacts: listContacts,
  }))
);
