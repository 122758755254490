import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IObjectivesRetirementFormState } from '../../../store/objective.state';
import { setObjectiveRetirementAction } from '../../../store/objective.actions';
import { getObjectiveRetirementSelectors } from '../../../store/objective.selectors';

@Component({
  selector: 'app-retirement',
  templateUrl: './retirement.component.html',
  styleUrls: ['./retirement.component.scss'],
})
export class RetirementComponent implements OnInit {
  constructor(private store: Store) {
    this.currentYear = new Date().getFullYear();
  }

  panelOpenState = true;
  dataStoreRetirement$: Observable<any>;
  dataStoreRetirement: IObjectivesRetirementFormState;

  currentYear: number = 0;
  forecastYear: number = 0;

  ngOnInit(): void {
    this.getState();
  }

  retirementCalculation(): void {
    this.dataStoreRetirement = {
      ...this.dataStoreRetirement,
      forecastYear:
        this.currentYear +
        (this.dataStoreRetirement.yearIntention -
          this.dataStoreRetirement.currentAge),
    };

    this.forecastYear =
      this.forecastYear < this.currentYear ? 2023 : this.forecastYear;

    this.setState(this.dataStoreRetirement);
  }

  unCheck(): void {
    this.dataStoreRetirement = {
      ...this.dataStoreRetirement,
      isSelected: !this.dataStoreRetirement.isSelected,
    };
    this.setState(this.dataStoreRetirement);
  }

  setState(data: IObjectivesRetirementFormState) {
    this.store.dispatch(setObjectiveRetirementAction(data));
  }

  getState(): void {
    this.dataStoreRetirement$ = this.store.select(
      getObjectiveRetirementSelectors
    );

    this.dataStoreRetirement$.subscribe((value) => {
      this.dataStoreRetirement = {
        ...(this.dataStoreRetirement = value),
      };
    });
  }

  setValuesForm(): void {
    if (isNaN(this.dataStoreRetirement.maximumInvestment)) {
      this.dataStoreRetirement.maximumInvestment = 0;
    }

    if (isNaN(this.dataStoreRetirement.minimumInvestment)) {
      this.dataStoreRetirement.minimumInvestment = 0;
    }

    this.dataStoreRetirement.check =
      this.dataStoreRetirement.description &&
      this.dataStoreRetirement.date &&
      this.dataStoreRetirement.currentAge &&
      this.dataStoreRetirement.forecastYear &&
      this.dataStoreRetirement.yearIntention &&
      this.dataStoreRetirement.maximumInvestment
        ? true
        : false;
    this.setState(this.dataStoreRetirement);
  }

  updateAmountMin(event): void {
    this.dataStoreRetirement.minimumInvestment = event;
    this.setValuesForm();
  }

  updateAmountMax(event): void {
    this.dataStoreRetirement.maximumInvestment = event;
    this.setValuesForm();
  }
}
