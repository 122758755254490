import { createAction, props } from '@ngrx/store';
import { ICreditCardState } from './credit-card.state';

export const initCreditCardAction = createAction(
  '[CreditCard Page] CreditCard page'
);

export const setCreditCardAction = createAction(
  '[CreditCard Page] set data',
  props<ICreditCardState>()
);
