import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IConsultingState } from './consulting.state';

export const getConsultingSelectorsState =
  createFeatureSelector<IConsultingState>('ConsultingReduce');

export const getConsultingSelectors = createSelector(
  getConsultingSelectorsState,
  (store: IConsultingState) => {
    return store;
  }
);
