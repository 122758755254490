import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rba-percentage',
  templateUrl: './rba-percentage.component.html',
  styleUrls: ['./rba-percentage.component.scss']
})
export class RbaPercentageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
