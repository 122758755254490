import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getSettingSelectors } from 'src/app/modules/settings/settings-general/store/setting.selector';
import { ISettingState } from 'src/app/modules/settings/settings-general/store/setting.state';
import { getJobOfferSelectors } from '../../job-offer/store/job-offer.selectors';
import { setSettingAction } from 'src/app/modules/settings/settings-general/store/setting.action';
import { IJobOfferState } from '../../job-offer/store/job-offer.state';

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss'],
})
export class SettingsGeneralComponent implements OnInit {
  constructor(private store: Store) {}

  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingState;

  buttonSettingName: String = '';
  checkLastPage$: Observable<any>;
  checkLastPage: IJobOfferState;

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };
    });

    this.checkLastPage$ = this.store.select(getJobOfferSelectors);
    this.checkLastPage$.subscribe((value) => {
      this.checkLastPage = { ...(this.checkLastPage = value) };
    });

    this.buttonSettingName = this.checkLastPage.check ? 'ENVIAR' : 'SALVAR';
  }

  setState(data: ISettingState) {
    this.store.dispatch(setSettingAction(data));
  }

  inputChange(): void {
    if (this.dataStoreSetting.consultantName != '') {
      this.dataStoreSetting.check = true;
    }
    this.setState(this.dataStoreSetting);
  }
}
