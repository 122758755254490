import { Component, OnInit } from '@angular/core';

import { getJobFormSelectors } from 'src/app/modules/collect-data/components/job-form/store/job-form.selectors';
import { getFamilyFormSelectors } from 'src/app/modules/collect-data/components/family-form/store/family-form.selectors';
import { getIncomeTaxSelectors } from 'src/app/modules/collect-data/components/income-tax/store/incomeTax.selectors';

import { IJobFormState } from 'src/app/modules/collect-data/components/job-form/store/job-form.state';
import { IFamilyFormState } from 'src/app/modules/collect-data/components/family-form/store/family-form.state';
import { IIncomeTaxState } from 'src/app/modules/collect-data/components/income-tax/store/incomeTax.state';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-information',
  templateUrl: './pdf-information.component.html',
  styleUrls: ['./pdf-information.component.scss'],
})
export class PdfInformationComponent implements OnInit {
  dataStoreProfission$: Observable<any>;
  dataStoreProfission: IJobFormState;

  dataStoreFamily$: Observable<any>;
  dataStoreFamily: IFamilyFormState;
  familyMarried: string;
  familychildren: string;
  familyInvestiments: string;

  dataStoreIncomeTax$: Observable<any>;
  dataStoreIncometax: IIncomeTaxState;
  incomeTaxrefunded: string;
  incomeRefundedFormated: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getStateProfission();
    this.getStateFamily();
    this.getStateIncomeTax();
  }

  getStateProfission() {
    this.dataStoreProfission$ = this.store.select(getJobFormSelectors);
    this.dataStoreProfission$.subscribe((value) => {
      this.dataStoreProfission = {
        ...(this.dataStoreProfission = value),
      };
    });
  }

  getStateFamily() {
    this.dataStoreFamily$ = this.store.select(getFamilyFormSelectors);
    this.dataStoreFamily$.subscribe((value) => {
      this.dataStoreFamily = {
        ...(this.dataStoreFamily = value),
      };
    });
    this.familyMarried = this.dataStoreFamily.married.isSelected
      ? 'Sim'
      : 'Não';
    this.familychildren = this.dataStoreFamily.children.isSelected
      ? 'Sim'
      : 'Não';
    this.familyInvestiments = this.dataStoreFamily.childrenInvestment.isSelected
      ? 'Sim'
      : 'Não';
  }

  getStateIncomeTax() {
    this.dataStoreIncomeTax$ = this.store.select(getIncomeTaxSelectors);
    this.dataStoreIncomeTax$.subscribe((value) => {
      this.dataStoreIncometax = {
        ...(this.dataStoreIncometax = value),
      };
    });
    this.incomeTaxrefunded = this.dataStoreIncometax.refunded ? 'Sim' : 'Não';
    this.incomeRefundedFormated = this.dataStoreIncometax.amount ? this.formatarMoeda(this.dataStoreIncometax.amount) : '0'
  }

  formatarMoeda(valor: number): string {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
