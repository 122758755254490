import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-objectives-to-pdf',
  templateUrl: './objectives-to-pdf.component.html',
  styleUrls: ['./objectives-to-pdf.component.scss']
})
export class ObjectivesToPdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
