import { createAction, props } from '@ngrx/store';
import { ISettingJobState } from './setting-job.state';

export const initSettingJobAction = createAction(
  '[page SettingJob] page SettingJob'
);

export const setSettingJobAction = createAction(
  '[page SettingJob] set data',
  props<ISettingJobState>()
);
