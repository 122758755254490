import { Component, OnInit, ViewChild } from '@angular/core';

import { ISettingsState } from './store/settings.state';
import { setSettingsAction } from './store/settings.actions';
import { getSettingsSelectors } from './store/settings.selectors';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ModalEndComponent } from '../end/components/modal-end/modal-end.component';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DefaultPricingComponent } from './info/default-pricing/default-pricing.component';
import { initialSettingsState } from './store/settings.reducer';
import { ToastComponent } from 'src/app/components/toast/toast.component';
import { SavedToastComponent } from 'src/app/components/saved-toast/saved-toast.component';
import { IBudgetState } from '../collect-data/components/budget/store/budget.state';
import { getBudgetSelectors } from '../collect-data/components/budget/store/budget.selectors';
import { ISettingState } from './settings-general/store/setting.state';
import { getSettingSelectors } from './settings-general/store/setting.selector';
import { InputToastComponent } from 'src/app/components/input-toast/input-toast.component';
import { HideDeliverablesComponent } from './offer/info/hide-deliverables/hide-deliverables.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @ViewChild(ModalEndComponent) modalEndComponent!: ModalEndComponent;

  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  dataStoreGeneralSetting$: Observable<any>;
  dataStoreGeneralSetting: ISettingState;

  dataBudgetState$: Observable<IBudgetState>;
  dataBudgetState: IBudgetState;

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 2;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getState();
    this.getGeneralSettingState();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };
    });
  }

  getBudgetState() {
    this.dataBudgetState$ = this.store.select(getBudgetSelectors);
    this.dataBudgetState$.subscribe((value) => {
      this.dataBudgetState = { ...value };
    });
  }

  getGeneralSettingState(): void {
    this.dataStoreGeneralSetting$ = this.store.select(getSettingSelectors);
    this.dataStoreGeneralSetting$.subscribe((value) => {
      this.dataStoreGeneralSetting = {
        ...(this.dataStoreGeneralSetting = value),
      };
    });
  }

  setState(data: ISettingsState) {
    this.store.dispatch(setSettingsAction(data));
  }

  setDefaultPricing(): void {
    this.dataStoreSetting = {
      ...this.dataStoreSetting,
      defaultPricing: this.dataStoreSetting.defaultPricing,
    };
    this.setState(this.dataStoreSetting);
  }

  setHideDeliverables(): void {
    this.dataStoreSetting = {
      ...this.dataStoreSetting,
      hideDeliverables: !this.dataStoreSetting.hideDeliverables,
    };
    this.setState(this.dataStoreSetting);
  }

  openDialogNewGuide() {
    const dialogRef = this.dialog.open(ModalEndComponent);
    dialogRef.afterClosed().subscribe();
  }

  openDialogDefaultPricing() {
    const dialogRef = this.dialog.open(DefaultPricingComponent);
    dialogRef.afterClosed().subscribe();
  }

  openDialogHideDeliverables() {
    const dialogRef = this.dialog.open(HideDeliverablesComponent);
    dialogRef.afterClosed().subscribe();
  }

  defaultPricingAlert() {
    let title = '';
    if (!this.dataStoreSetting.defaultPricing) {
      title = 'Precificação Padrão ativada';
    } else {
      title = 'Precificação Padrão desativada';
    }

    this._snackBar.openFromComponent(ToastComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000,
      panelClass: 'green-snackbar',
      data: { title },
    });
  }

  savedValueAlert() {
    if (
      !this.dataStoreGeneralSetting.clientName ||
      !this.dataStoreGeneralSetting.consultantName
    ) {
      let title = 'Todos os campos obrigatórios devem ser preenchidos';
      this._snackBar.openFromComponent(InputToastComponent, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.durationInSeconds * 1000,
        panelClass: 'alert-snackbar',
        data: { title },
      });
    } else {
      let title = 'Edições salvas';
      this._snackBar.openFromComponent(ToastComponent, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.durationInSeconds * 1000,
        panelClass: 'green-snackbar',
        data: { title },
      });
      this.router.navigate(['/']);
    }
  }
}
