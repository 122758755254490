import { createAction, props } from '@ngrx/store';
import { IConsultingState } from './consulting.state';

export const initConsultingAction = createAction(
  '[Consulting Page] Consulting page'
);

export const setConsultingAction = createAction(
  '[Consulting Page] set data',
  props<IConsultingState>()
);
