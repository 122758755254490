import { createAction, props } from '@ngrx/store';

export const initCollectDataAction = createAction(
  '[CollectData Page] Home page'
);

export const setCollectDataAction = createAction(
  '[CollectData Page] set check',
  props<{ check: boolean }>()
);
