import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-end',
  templateUrl: './modal-end.component.html',
  styleUrls: ['./modal-end.component.scss'],
})
export class ModalEndComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  // Função para reiniciar a aplicação
  restartApplication(): void {
    // Navegue para a raiz da aplicação
    this.router.navigate(['/home']).then(() => {
      // Recarregue a página para reiniciar a aplicação

      window.location.reload();
    });
  }
}
