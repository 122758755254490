import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IBudgetState } from './store/budget.state';
import { setBudgetAction } from './store/budget.actions';
import { getBudgetSelectors } from './store/budget.selectors';

@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.scss'],
})
export class BudgetComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IBudgetState>;
  dataState: IBudgetState;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IBudgetState) {
    this.store.dispatch(setBudgetAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getBudgetSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.idealBudget != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }

  updateAmount(event: number, field: string): void {
    switch (field) {
      case 'monthlyCost':
        this.dataState.monthlyCost = event;
        break;

      case 'monthlyIncome':
        this.dataState.monthlyIncome = event;
        break;

      case 'annualRecipe':
        this.dataState.annualRecipe = event;
        break;
    }
    this.setValuesForm();
  }
}
