import { Component, ElementRef, ViewChild} from '@angular/core';
import KeenSlider,  { KeenSliderInstance } from 'keen-slider';


@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: [
    '/node_modules/keen-slider/keen-slider.min.css',
    './carousel-slider.component.scss',
  ]
})
export class CarouselSliderComponent {

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>

  currentSlide:number = 0
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null

  ngAfterViewInit() {
  setTimeout(() => {
    this.slider = new KeenSlider(this.sliderRef.nativeElement, {
      initial: this.currentSlide,
      slideChanged: (s) => {
        this.currentSlide = s.track.details.rel
      },
    })
    this.dotHelper = [
      ...Array(this.slider.track.details.slides.length).keys(),
    ]
  })
  }

  constructor() { }

}
