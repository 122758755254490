<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #003c7d;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M30.0006 7V15C30.0006 15.2652 29.8952 15.5196 29.7077 15.7071C29.5201 15.8946 29.2658 16 29.0006 16C28.7353 16 28.481 15.8946 28.2934 15.7071C28.1059 15.5196 28.0006 15.2652 28.0006 15V9.41375L17.7081 19.7075C17.6152 19.8005 17.5049 19.8742 17.3835 19.9246C17.2621 19.9749 17.132 20.0008 17.0006 20.0008C16.8691 20.0008 16.739 19.9749 16.6176 19.9246C16.4962 19.8742 16.3859 19.8005 16.2931 19.7075L12.0006 15.4137L3.70806 23.7075C3.52042 23.8951 3.26592 24.0006 3.00056 24.0006C2.73519 24.0006 2.4807 23.8951 2.29306 23.7075C2.10542 23.5199 2 23.2654 2 23C2 22.7346 2.10542 22.4801 2.29306 22.2925L11.2931 13.2925C11.3859 13.1995 11.4962 13.1258 11.6176 13.0754C11.739 13.0251 11.8691 12.9992 12.0006 12.9992C12.132 12.9992 12.2621 13.0251 12.3835 13.0754C12.5049 13.1258 12.6152 13.1995 12.7081 13.2925L17.0006 17.5863L26.5868 8H21.0006C20.7353 8 20.481 7.89464 20.2934 7.70711C20.1059 7.51957 20.0006 7.26522 20.0006 7C20.0006 6.73478 20.1059 6.48043 20.2934 6.29289C20.481 6.10536 20.7353 6 21.0006 6H29.0006C29.2658 6 29.5201 6.10536 29.7077 6.29289C29.8952 6.48043 30.0006 6.73478 30.0006 7Z"
      fill="#003C7D"
    />
  </svg>
  <h4 class="fs-5 mt-3">Projeção Patrimonial</h4>
</header>

<div class="topic-fieldset">
  <label class="form-label mt-2 ms-2 fw-semibold fs-6"
    >Maior clareza, maior confiança e sem frustrações.</label
  >
</div>
<img src="/assets/img/image-apolo.png" alt="" width="100%" />

<a
  href="https://techfinance.empreenderdinheiro.com.br/"
  target="_blank"
  class="btn btn-link d-flex mx-auto my-3 text-primary text-decoration-none"
  style="
    width: fit-content;
    height: fit-content;
    border: none;
    background: transparent;
  "
>
  <button
    id="open-apolo-btn"
    class="btn btn-outline-primary d-flex align-items-center gap-2 px-4 py-3"
  >
    ABRIR APOLO
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-box-arrow-up-right"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
      />
      <path
        fill-rule="evenodd"
        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
      />
    </svg>
  </button>
</a>
