import { createReducer, on } from '@ngrx/store';

import { IInssState } from './inss.state';
import { initInssAction, setInssAction } from './inss.actions';

export const initialInssState: IInssState = {
  check: false,
  retirement: undefined,
  amountRetirement: 0,
  amountMonth: 0,
  contribute: undefined,
};

export const inssReduce = createReducer(
  initialInssState,
  on(
    setInssAction,
    (
      state,
      { check, retirement, amountRetirement, amountMonth, contribute }
    ) => ({
      ...state,
      check: check,
      retirement: retirement,
      amountRetirement: amountRetirement,
      amountMonth: amountMonth,
      contribute: contribute,
    })
  )
);
