import { Component, OnInit, Output } from '@angular/core';
import { ICollectDataState } from './store/collect-data.state';
import { setCollectDataAction } from './store/collect-data.actions';
import { getCollectDataSelectors } from './store/collect-data.selectors';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-collect-data',
  templateUrl: './collect-data.component.html',
  styleUrls: ['./collect-data.component.scss'],
})
export class CollectDataComponent implements OnInit {
  topicID: number = 1;
  fieldID: number = 1;

  updateTopic() {
    if (this.fieldID <= 3) {
      this.topicID = 1;
    } else if (this.fieldID <= 6) {
      this.topicID = 2;
    } else if (this.fieldID <= 12) {
      this.topicID = 3;
    } else if (this.fieldID <= 16) {
      this.topicID = 4;
    }
  }

  continue() {
    this.fieldID += 1;
    this.updateTopic();
  }

  back() {
    this.fieldID == 1 ? (this.fieldID = 1) : (this.fieldID -= 1);
    this.updateTopic();
  }

  selectTopic(num: number) {
    this.topicID = num;
    if (num == 1) {
      this.fieldID = 1;
    } else if (num == 2) {
      this.fieldID = 4;
    } else if (num == 3) {
      this.fieldID = 7;
    } else if (num == 4) {
      this.fieldID = 11;
    }
  }
  constructor(private store: Store) {}

  dataState$: Observable<ICollectDataState>;
  dataState: ICollectDataState;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: ICollectDataState) {
    this.store.dispatch(setCollectDataAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getCollectDataSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = true;
    this.setState(this.dataState);
  }
}
