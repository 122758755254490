import { Component, OnInit } from '@angular/core';
import { IDebtsState } from './store/debts.state';
import { Observable } from 'rxjs';
import { getDebtsSelectorsState } from './store/debts.selectors';
import { Store } from '@ngrx/store';
import { setDebtsAction } from './store/debts.actions';

@Component({
  selector: 'app-debts',
  templateUrl: './debts.component.html',
  styleUrls: ['./debts.component.scss'],
})
export class DebtsComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IDebtsState>;
  dataState: IDebtsState = {
    check: false,
    debts: false,
    confirmDebts: false,
    listDebts: [],
  };

  listLocal: Array<any> = [];

  async ngOnInit() {
    await this.getState();

    this.listLocal = [...this.dataState.listDebts];

    if (this.listLocal.length === 0) {
      await this.addList();
    }
  }

  getState(): void {
    this.dataState$ = this.store.select(getDebtsSelectorsState);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setState(data: IDebtsState): void {
    this.store.dispatch(setDebtsAction(data));
  }

  setValuesForm(): void {
    this.dataState.check = !!this.dataState.debts;
    this.setState(this.dataState);
  }

  updateList(data, index): void {
    if (this.isValidIndex(index)) {
      const updatedVehicles = [...this.dataState.listDebts];
      updatedVehicles[index] = { ...data };

      this.dataState.listDebts = updatedVehicles;
      this.setState(this.dataState);
    }
  }

  addList(): void {
    if (!Array.isArray(this.listLocal)) {
      this.listLocal = [];
    }

    const newDebt = {
      check: true,
      typeDebt: '',
      contractValue: 0,
      installmentValue: 0,
      interestRate: '',
      interestRateChoose: '',
    };

    this.listLocal = [...this.dataState.listDebts, newDebt];
    this.dataState.listDebts = [...this.dataState.listDebts, newDebt];
  }

  deleteList(index: number): void {
    if (this.isValidIndex(index)) {
      const updatedVehicles = [
        ...this.dataState.listDebts.slice(0, index),
        ...this.dataState.listDebts.slice(index + 1),
      ];

      this.dataState.listDebts = updatedVehicles;
      this.listLocal = updatedVehicles;
      this.setState(this.dataState);
    }
  }

  isValidIndex(index: number): boolean {
    return index >= 0 && index < this.listLocal.length;
  }
}
