import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getPresentationSelectorsState } from './store/presentation.selectors';
import { setPresentationAction } from './store/presentation.actions';
import { IframeSlideComponent } from './iframe-slide/iframe-slide.component';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
})
export class PresentationComponent implements OnInit {
  @ViewChild('meuIframe') meuIframe: ElementRef;

  iframeVisivel: boolean = false;

  ativarModoTelaCheia() {
    this.iframeVisivel = true;
    const iframeElement = this.meuIframe.nativeElement;
    if (iframeElement.requestFullscreen) {
      iframeElement.requestFullscreen();
    } else if (iframeElement.mozRequestFullScreen) {
      iframeElement.mozRequestFullScreen();
    } else if (iframeElement.webkitRequestFullscreen) {
      iframeElement.webkitRequestFullscreen();
    }
  }

  ngOnInit(): void {}
  constructor(public dialog: MatDialog, private store: Store) {}

  check$: Observable<any>;

  openDialog() {
    const dialogRef = this.dialog.open(IframeSlideComponent, {
      panelClass: 'iframe-dialog',
    });
    dialogRef.afterClosed().subscribe();
  }

  validateStatePage(): void {
    this.setStates(true);
    this.getStates();
  }

  setStates(check: boolean) {
    this.store.dispatch(setPresentationAction({ check }));
  }

  getStates() {
    this.check$ = this.store.select(getPresentationSelectorsState);

    // Assine ao Observable para obter o valor do seletor
    this.check$.subscribe((value) => {});
  }
}
