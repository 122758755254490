import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAssetsState } from './assets.state';

export const getAssetsSelectorsState =
  createFeatureSelector<IAssetsState>('assetsReduce');

export const getAssetsSelectors = createSelector(
  getAssetsSelectorsState,
  (store: IAssetsState) => {
    return store;
  }
);
