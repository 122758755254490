import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setFamilyFormAction } from './store/family-form.actions';
import { IFamilyFormState } from './store/family-form.state';
import { getFamilyFormSelectorsState } from './store/family-form.selectors';

@Component({
  selector: 'app-family-form',
  templateUrl: './family-form.component.html',
  styleUrls: ['./family-form.component.scss'],
})
export class FamilyFormComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IFamilyFormState>;
  dataState: IFamilyFormState;

  ngOnInit(): void {
    this.getState();
  }

  setState(data) {
    this.store.dispatch(setFamilyFormAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getFamilyFormSelectorsState);
    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (
      this.dataState.married.isSelected != undefined &&
      this.dataState.children.isSelected != undefined &&
      this.dataState.childrenInvestment.isSelected != undefined
    ) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }

  setOption(item: string, option: string, value: boolean) {
    // Criar uma cópia do estado atual
    this.dataState = {
      ...this.dataState,
      [item]: {
        ...this.dataState[item],
        [option]: value,
      },
    };

    // Atualizar o estado usando o método `setState`
    this.setState(this.dataState);
  }
}
