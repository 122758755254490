import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IVehiclesState } from './store/vehicles.state';
import { Observable } from 'rxjs';
import { setVehiclesAction } from './store/vehicles.actions';
import { getVehiclesSelectors } from './store/vehicles.selectors';

@Component({
  selector: 'app-vehicles-form-collect',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent implements OnInit {
  dataState$: Observable<IVehiclesState>;
  dataState: IVehiclesState = {
    check: true,
    vehicles: undefined,
    listVehicles: [],
  };
  listLocal: Array<any> = [];

  constructor(private store: Store) {}

  async ngOnInit() {
    await this.getState();

    this.listLocal = [...this.dataState.listVehicles];

    if (this.listLocal.length === 0) {
      await this.addList();
    }
  }

  setState(data: IVehiclesState): void {
    this.store.dispatch(setVehiclesAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getVehiclesSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = !!this.dataState.vehicles;
    this.setState(this.dataState);
  }

  updateList(data, index): void {
    if (this.isValidIndex(index)) {
      const updatedVehicles = [...this.dataState.listVehicles];
      updatedVehicles[index] = { ...data };

      this.dataState.listVehicles = updatedVehicles;
      this.setState(this.dataState);
    }
  }

  addList(): void {
    if (!Array.isArray(this.listLocal)) {
      this.listLocal = [];
    }

    const newVehicle = {
      check: true,
      model: '',
      yearOfPurchase: undefined,
      estimatedValue: undefined,
    };

    this.listLocal = [...this.dataState.listVehicles, newVehicle];
    this.dataState.listVehicles = [...this.dataState.listVehicles, newVehicle];
  }

  deleteList(index: number): void {
    if (this.isValidIndex(index)) {
      const updatedVehicles = [
        ...this.dataState.listVehicles.slice(0, index),
        ...this.dataState.listVehicles.slice(index + 1),
      ];

      this.dataState.listVehicles = updatedVehicles;
      this.listLocal = updatedVehicles;
      this.setState(this.dataState);
    }
  }

  isValidIndex(index: number): boolean {
    return index >= 0 && index < this.listLocal.length;
  }
}
