// Updated Component Class
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPropertiesState } from './store/properties.state';
import { Observable } from 'rxjs';
import { setPropertiesAction } from './store/properties.actions';
import { getPropertiesSelectors } from './store/properties.selectors';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
})
export class PropertiesComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IPropertiesState>;
  dataState: IPropertiesState = {
    check: true,
    properties: undefined,
    interest: false,
    listProperties: [],
  };

  listLocal: Array<any> = [];

  async ngOnInit() {
    await this.getState();

    this.listLocal = [...this.dataState.listProperties];

    if (this.listLocal.length === 0) {
      await this.addList();
    }
  }

  setState(data: IPropertiesState) {
    this.store.dispatch(setPropertiesAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getPropertiesSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = !!this.dataState.properties;
    this.setState(this.dataState);
  }

  updateList(data, index): void {
    if (this.isValidIndex(index)) {
      const updatedProperties = [...this.dataState.listProperties];
      updatedProperties[index] = { ...data };

      this.dataState.listProperties = updatedProperties;
      this.setState(this.dataState);
    }
  }

  addList(): void {
    if (!Array.isArray(this.listLocal)) {
      this.listLocal = [];
    }

    const newProperty = {
      check: true,
      estimatedValue: 0,
      propertyFinanced: undefined,
      notes: '',
    };

    this.listLocal = [...this.dataState.listProperties, newProperty];
    this.dataState.listProperties = [
      ...this.dataState.listProperties,
      newProperty,
    ];
  }

  deleteList(index: number): void {
    if (this.isValidIndex(index)) {
      const updatedProperties = [
        ...this.dataState.listProperties.slice(0, index),
        ...this.dataState.listProperties.slice(index + 1),
      ];

      this.dataState.listProperties = updatedProperties;
      this.listLocal = updatedProperties;

      this.setState(this.dataState);
    }
  }

  isValidIndex(index: number): boolean {
    return index >= 0 && index < this.listLocal.length;
  }

  // updateAmount(event): void {
  //   this.dataState.amount = event;
  //   this.setValuesForm();
  // }
}
