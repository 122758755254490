import { createReducer, on } from '@ngrx/store';
import {
  IObjectiveState,
  IObjectivesFormState,
  IObjectivesRetirementFormState,
} from './objective.state';

import {
  setObjectiveAction,
  setObjectiveRetirementAction,
  setObjectiveTripsAction,
  setObjectivePropertyAction,
  setObjectiveVehiclesAction,
  setObjectiveEntrepreneurshipAction,
  setObjectiveMarriageAction,
  setObjectiveOthersAction,
} from './objective.actions';

const initialObjectiveState: IObjectiveState = {
  check: false,
};

export const objectiveReduce = createReducer(
  initialObjectiveState,
  on(setObjectiveAction, (state, { check }) => ({
    ...state,
    check: check,
  }))
);

export const initialObjectivesFormState: IObjectivesFormState = {
  isSelected: false,
  check: false,
  objectiveName: '',
  description: '',
  date: new Date(),
  minimumInvestment: 0,
  maximumInvestment: 0,
  observation: '',
};

export const initialObjectivesRetirementState: IObjectivesRetirementFormState =
  {
    isSelected: false,
    check: false,
    objectiveName: '',
    description: '',
    date: new Date(),
    minimumInvestment: 0,
    maximumInvestment: 0,
    observation: '',
    yearIntention: 0,
    forecastYear: 0,
    currentAge: 0,
  };

// Retirement
export const objectiveRetirementReduce = createReducer(
  initialObjectivesFormState,
  on(setObjectiveRetirementAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);

// Trips
export const objectiveTripsReduce = createReducer(
  initialObjectivesFormState,
  on(setObjectiveTripsAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);

// Property
export const objectivePropertyReduce = createReducer(
  initialObjectivesFormState,
  on(setObjectivePropertyAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);

// Vehicles
export const objectiveVehiclesReduce = createReducer(
  initialObjectivesFormState,
  on(setObjectiveVehiclesAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);

//Entrepreneurship
export const objectiveEntrepreneurshipReduce = createReducer(
  initialObjectivesFormState,
  on(setObjectiveEntrepreneurshipAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);

//Marriage
export const objectiveMarriageReduce = createReducer(
  initialObjectivesFormState,
  on(setObjectiveMarriageAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);

//Others
export const objectiveOthersReduce = createReducer(
  initialObjectivesFormState,
  on(setObjectiveOthersAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);
