import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getSettingsSelectors } from 'src/app/modules/settings/store/settings.selectors';
import { ISettingsState } from 'src/app/modules/settings/store/settings.state';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  constructor(
    private store: Store,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {}

  ngOnInit(): void {}
}
