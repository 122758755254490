import { Component, OnInit } from '@angular/core';
import { ISettingsState } from '../../../store/settings.state';
import { Observable } from 'rxjs';
import { getSettingsSelectors } from '../../../store/settings.selectors';
import { Store } from '@ngrx/store';
import { IOfferState } from '../../../offer/store/offer.state';
import { setSettingsAction } from '../../../store/settings.actions';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { InputToastComponent } from 'src/app/components/input-toast/input-toast.component';

@Component({
  selector: 'app-credit-card-payment',
  templateUrl: './credit-card-payment.component.html',
  styleUrls: ['./credit-card-payment.component.scss'],
})
export class CreditCardPaymentComponent implements OnInit {
  dataStoreOffer$: Observable<any>;
  dataStoreOffer: IOfferState;

  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  financialConsultantValueBr: string;
  advisorValueBr: string;

  financialConsultantInstallmentValueBr: string;
  advisorInstallmentValueBr: string;

  financialConsultantValueSimulated: string;
  advisorValueSimulated: string;

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 2;

  constructor(private store: Store, private _snackBar: MatSnackBar) {}
  ngOnInit(): void {
    this.getState();
    this.installmentCalculation();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };
      this.validadeValuesSimulated();
    });
  }

  setState(data: ISettingsState) {
    this.store.dispatch(setSettingsAction(data));
  }

  validadeValuesSimulated() {
    this.financialConsultantValueSimulated = this.convertValue(
      this.dataStoreSetting.manualValue
    );
    this.advisorValueSimulated = this.convertValue(
      this.dataStoreSetting.manualValue +
        this.dataStoreSetting.followUpServiceValue
    );

    this.financialConsultantInstallmentValueBr = this.convertValue(
      this.dataStoreSetting.financialConsultantValue /
        this.dataStoreSetting.installments
    );
    this.advisorInstallmentValueBr = this.convertValue(
      this.dataStoreSetting.advisorValue / this.dataStoreSetting.installments
    );

    if (
      this.dataStoreSetting.manualValue <
      this.dataStoreSetting.financialConsultantSimulatedValue
    ) {
      this.financialConsultantValueSimulated = this.convertValue(
        this.dataStoreSetting.financialConsultantSimulatedValue
      );

      this.advisorValueSimulated = this.convertValue(
        this.dataStoreSetting.financialConsultantSimulatedValue +
          this.dataStoreSetting.followUpServiceValue
      );

      this.financialConsultantInstallmentValueBr = this.convertValue(
        this.dataStoreSetting.financialConsultantSimulatedValue /
          this.dataStoreSetting.installments
      );
      this.advisorInstallmentValueBr = this.convertValue(
        (this.dataStoreSetting.financialConsultantSimulatedValue +
          this.dataStoreSetting.followUpServiceValue) /
          this.dataStoreSetting.installments
      );
    }
  }

  installmentCalculation() {
    if (this.dataStoreSetting && this.dataStoreSetting.installments) {
      let consultantValue = this.dataStoreSetting.financialConsultantValue;
      let advisorValue = this.dataStoreSetting.advisorValue;

      let installmentConsultantValue =
        consultantValue / this.dataStoreSetting.installments;
      this.dataStoreSetting.installmentFinancialConsultantValue =
        this.roundToInteger(installmentConsultantValue);

      let installmentAdvisorValue =
        advisorValue / this.dataStoreSetting.installments;
      this.dataStoreSetting.installmentAdvisorValue = this.roundToInteger(
        installmentAdvisorValue
      );

      this.setState(this.dataStoreSetting);
    }
  }

  roundToInteger(value: number): number {
    return Math.round(value);
  }

  increase() {
    if (this.dataStoreSetting.installments < 12) {
      this.dataStoreSetting.installments++;
      this.installmentCalculation();
    }
  }

  decrease() {
    if (this.dataStoreSetting.installments > 1) {
      this.dataStoreSetting.installments--;
      this.installmentCalculation();
    }
  }

  validateInput() {
    if (this.dataStoreSetting.installments > 12) {
      this.installmentValueAlert();
      this.dataStoreSetting.installments = 12;
    } else if (this.dataStoreSetting.installments < 0) {
      this.installmentValueAlert();
      this.dataStoreSetting.installments = 0;
    }

    this.installmentCalculation();
    this.setState(this.dataStoreSetting);
  }

  checkMaxinstallments() {
    if (this.dataStoreSetting.installments > 12) {
      this.dataStoreSetting.installments = 12;
    }
  }

  convertValue(value: number) {
    let response = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value));

    return response;
  }

  installmentValueAlert() {
    let title = 'Quantidade de parcelas inválida';
    let message = 'A quantidade de parcelas deve ser um número de 1 a 12.';
    this._snackBar.openFromComponent(InputToastComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000,
      panelClass: 'alert-snackbar',
      data: { title, message },
    });
    this.dataStoreSetting.installments = 12;
    this.setState(this.dataStoreSetting);
  }
}
