import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getSettingSelectors } from '../../modules/settings/settings-general/store/setting.selector';
import { ISettingState } from '../../modules/settings/settings-general/store/setting.state';
import { IframeCapitalComponent } from '../iframe-capital/iframe-capital.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingState;

  constructor(public dialog: MatDialog, private store: Store) {}

  openDialogInvestments() {
    const dialogRef = this.dialog.open(IframeCapitalComponent);
    dialogRef.afterClosed().subscribe();
  }

  getState() {
    this.dataStoreSetting$ = this.store.select(getSettingSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };
    });
  }

  ngOnInit() {
    this.getState();
  }
}
