import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { getJobFormSelectors } from './store/job-form.selectors';
import { Store } from '@ngrx/store';
import { IJobFormState } from './store/job-form.state';
import { setJobFormAction } from './store/job-form.actions';
@Component({
  selector: 'app-job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.scss'],
})
export class JobFormComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IJobFormState>;
  dataState: IJobFormState;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IJobFormState) {
    this.store.dispatch(setJobFormAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getJobFormSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.job != '' && this.dataState.typeJob != '') {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }

  setTypeJob(type: string): void {
    this.dataState.typeJob = type;
    this.setValuesForm();
  }
}
