import { createReducer, on } from '@ngrx/store';
import { ISettingState } from './setting.state';
import { setSettingAction } from './setting.action';

const initialSettingState: ISettingState = {
  consultantName: '',
  clientName: '',
  check: false,
};

export const settingReduce = createReducer(
  initialSettingState,
  on(setSettingAction, (state, data) => ({
    ...state,
    ...(state = data),
  }))
);
