import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDebtsState } from './debts.state';

export const getDebtsSelectorsState =
  createFeatureSelector<IDebtsState>('debtsReduce');

export const getDebtsSelectors = createSelector(
  getDebtsSelectorsState,
  (store: IDebtsState) => {
    return store;
  }
);
