import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ICreditCardState } from './credit-card.state';

export const getCreditCardSelectorsState =
  createFeatureSelector<ICreditCardState>('creditCardReduce');

export const getCreditCardSelectors = createSelector(
  getCreditCardSelectorsState,
  (store: ICreditCardState) => {
    return store;
  }
);
