import { Component, OnInit } from '@angular/core';

import { getObjectiveRetirementSelectors } from 'src/app/modules/objective/store/objective.selectors';
import { IObjectivesRetirementFormState } from 'src/app/modules/objective/store/objective.state';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-retirement',
  templateUrl: './pdf-retirement.component.html',
  styleUrls: ['./pdf-retirement.component.scss'],
})
export class PdfRetirementComponent implements OnInit {
  dataStoreRetirement$: Observable<any>;
  dataStoreRetirement: IObjectivesRetirementFormState;
  minInvestimentFormated: string;
  maxInvestimentFormated: string;

  constructor(private store: Store) {}

  getState() {
    this.dataStoreRetirement$ = this.store.select(
      getObjectiveRetirementSelectors
    );
    this.dataStoreRetirement$.subscribe((value) => {
      this.dataStoreRetirement = {
        ...(this.dataStoreRetirement = value),
      };
    });

    this.minInvestimentFormated = this.formatarMoeda(
      this.dataStoreRetirement.minimumInvestment
    );
    this.maxInvestimentFormated = this.formatarMoeda(
      this.dataStoreRetirement.maximumInvestment
    );
  }

  ngOnInit(): void {
    this.getState();
  }

  formatarMoeda(valor: number): string {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
