<div class="objectives-content__marriage">
  <div class="title-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.125 2C9.83438 2 8.70438 2.555 8 3.49312C7.29562 2.555 6.16563 2 4.875 2C3.84764 2.00116 2.86269 2.40979 2.13624 3.13624C1.40979 3.86269 1.00116 4.84764 1 5.875C1 10.25 7.48688 13.7913 7.76312 13.9375C7.83594 13.9767 7.91732 13.9972 8 13.9972C8.08268 13.9972 8.16406 13.9767 8.23687 13.9375C8.51312 13.7913 15 10.25 15 5.875C14.9988 4.84764 14.5902 3.86269 13.8638 3.13624C13.1373 2.40979 12.1524 2.00116 11.125 2ZM8 12.925C6.85875 12.26 2 9.23063 2 5.875C2.00099 5.11281 2.30421 4.38212 2.84316 3.84316C3.38212 3.30421 4.11281 3.00099 4.875 3C6.09062 3 7.11125 3.6475 7.5375 4.6875C7.57517 4.77921 7.63925 4.85764 7.7216 4.91284C7.80396 4.96804 7.90086 4.99752 8 4.99752C8.09914 4.99752 8.19604 4.96804 8.2784 4.91284C8.36075 4.85764 8.42483 4.77921 8.4625 4.6875C8.88875 3.64562 9.90938 3 11.125 3C11.8872 3.00099 12.6179 3.30421 13.1568 3.84316C13.6958 4.38212 13.999 5.11281 14 5.875C14 9.22563 9.14 12.2594 8 12.925Z"
        fill="#206CCF"
      />
    </svg>
    <h2 class="heading-h2">Casamento</h2>
  </div>

  <div class="marriage-reason">
    <h3 class="heading-h3">Escolheu esse objetivo porque</h3>
    <p>
      {{ dataStoreMarriage.description }}
    </p>

    <h3 class="heading-h3">Quando deseja realizar esse objetivo?</h3>
    <p>{{ intendDate }}</p>
  </div>

  <div class="marriage-reason">
    <h3 class="heading-h3">Quanto pretende investir nesse objetivo?</h3>
    <div class="pretend-invest-container">
      <div class="invest__min">
        <h4 class="heading-h4">Minimo</h4>
        <p>{{ minInvestimentFormated }} /mês</p>
      </div>
      <div class="invest__max">
        <h4 class="heading-h4">Maximo</h4>
        <p>{{ maxInvestimentFormated }} /mês</p>
      </div>
    </div>
    <div>
      <h3 class="heading-h3">Observações</h3>
      <p>
        {{ dataStoreMarriage.observation }}
      </p>
    </div>
  </div>
</div>
