import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IObjectivesFormState } from '../../../store/objective.state';
import { setObjectivePropertyAction } from '../../../store/objective.actions';
import { getObjectivePropertySelectors } from '../../../store/objective.selectors';

@Component({
  selector: 'app-own-property',
  templateUrl: './own-property.component.html',
  styleUrls: ['./own-property.component.scss'],
})
export class OwnPropertyComponent implements OnInit {
  panelOpenState = true;
  dataStoreProperty$: Observable<any>;
  dataStoreProperty: IObjectivesFormState;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreProperty$ = this.store.select(getObjectivePropertySelectors);
    this.dataStoreProperty$.subscribe((value) => {
      this.dataStoreProperty = {
        ...(this.dataStoreProperty = value),
      };
    });
  }

  unCheck(): void {
    this.dataStoreProperty = {
      ...this.dataStoreProperty,
      isSelected: !this.dataStoreProperty.isSelected,
    };
    this.setState(this.dataStoreProperty);
  }

  setState(data: IObjectivesFormState) {
    this.store.dispatch(setObjectivePropertyAction(data));
  }

  setValuesForm(): void {
    if (isNaN(this.dataStoreProperty.maximumInvestment)) {
      this.dataStoreProperty.maximumInvestment = 0;
    }

    if (isNaN(this.dataStoreProperty.minimumInvestment)) {
      this.dataStoreProperty.minimumInvestment = 0;
    }

    this.dataStoreProperty.check =
      this.dataStoreProperty.description &&
      this.dataStoreProperty.date &&
      this.dataStoreProperty.maximumInvestment
        ? true
        : false;
    this.setState(this.dataStoreProperty);
  }

  updateAmountMin(event): void {
    this.dataStoreProperty.minimumInvestment = event;
    this.setValuesForm();
  }

  updateAmountMax(event): void {
    this.dataStoreProperty.maximumInvestment = event;
    this.setValuesForm();
  }
}
