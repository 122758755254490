import { createAction, props } from '@ngrx/store';
import { IHealthInsuranceState } from './healthInsurance.state';

export const initHealthInsuranceAction = createAction(
  '[HealthInsurance Page] HealthInsurance page'
);

export const setHealthInsuranceAction = createAction(
  '[HealthInsurance Page] set data',
  props<IHealthInsuranceState>()
);
