<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #6813aa;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M29.9991 15.8287C29.7708 13.2111 28.8133 10.7099 27.2353 8.60899C25.6573 6.50805 23.5219 4.89154 21.0716 3.94291C18.6213 2.99427 15.9541 2.7515 13.3727 3.24216C10.7914 3.73281 8.39929 4.93724 6.46789 6.71875C3.88947 9.08751 2.29647 12.3395 2.00539 15.8287C1.98166 16.1049 2.01559 16.383 2.10502 16.6453C2.19445 16.9077 2.33743 17.1486 2.5249 17.3527C2.71237 17.5569 2.94022 17.7198 3.19401 17.8313C3.4478 17.9427 3.72197 18.0001 3.99914 18H14.9991V25C14.9991 26.0609 15.4206 27.0783 16.1707 27.8284C16.9209 28.5786 17.9383 29 18.9991 29C20.06 29 21.0774 28.5786 21.8276 27.8284C22.5777 27.0783 22.9991 26.0609 22.9991 25C22.9991 24.7348 22.8938 24.4804 22.7062 24.2929C22.5187 24.1054 22.2644 24 21.9991 24C21.7339 24 21.4796 24.1054 21.292 24.2929C21.1045 24.4804 20.9991 24.7348 20.9991 25C20.9991 25.5304 20.7884 26.0391 20.4134 26.4142C20.0383 26.7893 19.5296 27 18.9991 27C18.4687 27 17.96 26.7893 17.5849 26.4142C17.2099 26.0391 16.9991 25.5304 16.9991 25V18H27.9991C28.2768 18.001 28.5517 17.9442 28.8062 17.8332C29.0608 17.7221 29.2894 17.5593 29.4775 17.355C29.6657 17.1508 29.8092 16.9096 29.899 16.6468C29.9888 16.384 30.0229 16.1054 29.9991 15.8287ZM3.99914 16C4.22077 13.424 5.26721 10.9881 6.98311 9.05399C8.69901 7.11989 10.9929 5.7907 13.5241 5.26375C12.0591 7.25 10.2304 10.7637 10.0204 16H3.99914ZM12.0179 16C12.1916 12.1537 13.3341 9.39875 14.3054 7.72C14.7843 6.88606 15.3522 6.10651 15.9991 5.395C16.6449 6.10662 17.2115 6.88617 17.6891 7.72C19.3491 10.5875 19.8741 13.66 19.9766 16H12.0179ZM21.9741 16C21.7641 10.7637 19.9354 7.25 18.4741 5.2575C21.0071 5.78328 23.3025 7.11295 25.0188 9.04859C26.735 10.9842 27.7804 13.4223 27.9991 16H21.9741Z"
      fill="#6813AA"
    />
  </svg>
  <h4 class="fs-5 mt-3">Outros Seguros</h4>
</header>

<form class="px-3 border rounded">
  <div class="py-4">
    <label class="form-label fs-6">Você possui seguro(s)?</label>
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="true"
          id="radio-1"
          name="insurance"
          [(ngModel)]="dataState.insurance"
          (ngModelChange)="setValuesForm()"
        />
        <label class="form-check-label" for="radio-1">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="false"
          id="radio-2"
          name="insurance"
          [(ngModel)]="dataState.insurance"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-2">Não</label>
      </div>
    </div>
  </div>

  <div class="border-top py-4" *ngIf="dataState.insurance">
    <label class="form-label fs-6">Quais seguros você possui?</label>
    <input
      class="form-control mt-2 p-2"
      id="others-insurance"
      type="text"
      placeholder="Caso possua mais de um, informe todos aqui."
      name="insuranceNames"
      [(ngModel)]="dataState.insuranceName"
      (ngModelChange)="setValuesForm()"
    />
    <div class="form-text">Máximo 500 caracteres</div>
  </div>

  <div class="border-top py-4" *ngIf="dataState.insurance">
    <label class="form-label fs-6"
      >Você sente que estes seguros estão adequados as suas necessidades?</label
    >
    <div class="d-flex flex-column justify-content-start gap-2">
      <div class="d-flex flex-row form-check align-items-start gap-2">
        <input
          class="form-check-input"
          type="radio"
          [value]="'Sim'"
          id="radio-3"
          name="safe"
          [(ngModel)]="dataState.safeWithInsurance"
          (ngModelChange)="setValuesForm()"
        />
        <label class="form-check-label" for="radio-3">Sim</label>
      </div>
      <div class="d-flex flex-row form-check align-items-start gap-2">
        <input
          class="form-check-input"
          type="radio"
          [value]="'Não'"
          id="radio-4"
          name="safe"
          [(ngModel)]="dataState.safeWithInsurance"
          (ngModelChange)="setValuesForm()"
        />
        <label class="form-check-label" for="radio-4">Não</label>
      </div>
      <div class="d-flex flex-row form-check align-items-start gap-2">
        <input
          class="form-check-input"
          type="radio"
          [value]="'Não sei'"
          id="radio-5"
          name="safe"
          [(ngModel)]="dataState.safeWithInsurance"
          (ngModelChange)="setValuesForm()"
        />
        <label class="form-check-label" for="radio-5">Não sei</label>
      </div>
    </div>
  </div>

  <div class="border-top py-4" *ngIf="dataState.insurance">
    <label class="form-label mt-2 fs-6"
      >Tem interesse em receber um estudo personalizado visando reduzir o valor
      das suas apólices de seguro?</label
    >
    <div class="mt-2 mb-1 form-check">
      <input
        class="form-check-input me-2"
        type="checkbox"
        id="interesse"
        name="interesse"
        [checked]="dataState.interest"
        [(ngModel)]="dataState.interest"
        (ngModelChange)="setValuesForm()"
      />
      <label class="form-check-label" for="interesse"
        >Sim, tenho interesse</label
      >
    </div>
  </div>
</form>
