import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IVehiclesState } from './vehicles.state';

export const getVehiclesSelectorsState =
  createFeatureSelector<IVehiclesState>('vehiclesReduce');

export const getVehiclesSelectors = createSelector(
  getVehiclesSelectorsState,
  (store: IVehiclesState) => {
    return store;
  }
);
