import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBudgetState } from './budget.state';

export const getBudgetSelectorsState =
  createFeatureSelector<IBudgetState>('budgetReduce');

export const getBudgetSelectors = createSelector(
  getBudgetSelectorsState,
  (store: IBudgetState) => {
    return store;
  }
);
