import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISettingPlansState } from './store/setting-plans.state';
import { getSettingPlansSelectors } from './store/setting-plans.selector';
import { setSettingPlansAction } from './store/setting-plans.action';
import { ISettingJobState } from '../settings-job-offer/store/setting-job.state';
import { getSettingJobSelectors } from '../settings-job-offer/store/setting-job.selector';

@Component({
  selector: 'app-settings-plans',
  templateUrl: './settings-plans.component.html',
  styleUrls: ['./settings-plans.component.scss'],
})
export class SettingsPlansComponent implements OnInit {
  dataStoreSettingPlans$: Observable<ISettingPlansState>;
  dataStoreSettingPlans: ISettingPlansState;

  dataStoreSettingJob$: Observable<ISettingJobState>;
  dataStoreSettingJob: ISettingJobState;
  isEnableSettingJob: boolean = false;

  planNameOne!: string;
  valuePlanOne!: number;
  paymentChoiceOne!: string;

  automaticSelected: boolean = false;
  planNameTwo!: string;
  valuePlanTwo!: number;
  paymentChoiceTwo!: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
    this.getStateSettinsJob();
  }

  getStateSettinsJob(): void {
    this.dataStoreSettingJob$ = this.store.select(getSettingJobSelectors);
    this.dataStoreSettingJob$.subscribe((value) => {
      this.dataStoreSettingJob = {
        ...(this.dataStoreSettingJob = value),
      };

      this.isEnableSettingJob = false;

      if (this.dataStoreSettingJob.automaticCalculation == false) {
        this.isEnableSettingJob = true;
      }
    });
  }

  getState(): void {
    this.dataStoreSettingPlans$ = this.store.select(getSettingPlansSelectors);
    this.dataStoreSettingPlans$.subscribe((value) => {
      this.dataStoreSettingPlans = {
        ...(this.dataStoreSettingPlans = value),
      };

      this.planNameOne = this.dataStoreSettingPlans.planNameOne;
      this.valuePlanOne = this.dataStoreSettingPlans.valuePlanOne;
      this.paymentChoiceOne = this.dataStoreSettingPlans.paymentChoiceOne;
      this.automaticSelected = this.dataStoreSettingPlans.automaticSelected;
      this.planNameTwo = this.dataStoreSettingPlans.planNameTwo;
      this.valuePlanTwo = this.dataStoreSettingPlans.valuePlanTwo;
      this.paymentChoiceTwo = this.dataStoreSettingPlans.paymentChoiceTwo;
    });
  }

  setSettingPlans(data: ISettingPlansState): void {
    this.store.dispatch(setSettingPlansAction(data));
  }

  setPlanNameOneChange(newPlanName: string): void {
    this.dataStoreSettingPlans.planNameOne = newPlanName;
    this.setSettingPlans(this.dataStoreSettingPlans);
  }

  setValuePlanOneChange(newValuePlanOne: number): void {
    this.dataStoreSettingPlans.valuePlanOne = newValuePlanOne;
    this.setSettingPlans(this.dataStoreSettingPlans);

    if (this.automaticSelected) {
      this.replicateValueConsultancyDiscount(
        this.dataStoreSettingPlans.automaticSelected
      );
    }
  }

  setPaymentChoiceOneChange(newPaymentChoiceOne: string): void {
    this.dataStoreSettingPlans.paymentChoiceOne = newPaymentChoiceOne;
    this.setSettingPlans(this.dataStoreSettingPlans);
  }

  setAutomaticSelectedChange(newAutomaticSelected: boolean): void {
    this.dataStoreSettingPlans.automaticSelected = newAutomaticSelected;
    this.setSettingPlans(this.dataStoreSettingPlans);

    this.replicateValueConsultancyDiscount(newAutomaticSelected);
  }

  setPlanNameTwoChange(newPlanNameTwo: string): void {
    this.dataStoreSettingPlans.planNameTwo = newPlanNameTwo;
    this.setSettingPlans(this.dataStoreSettingPlans);
  }

  setValuePlanTwoChange(newValuePlanTwo: number): void {
    this.dataStoreSettingPlans.valuePlanTwo = newValuePlanTwo;
    this.setSettingPlans(this.dataStoreSettingPlans);
  }

  setPaymentChoiceTwoChange(newPaymentChoiceTwo: string): void {
    this.dataStoreSettingPlans.paymentChoiceTwo = newPaymentChoiceTwo;
    this.setSettingPlans(this.dataStoreSettingPlans);
  }

  replicateValueConsultancyDiscount(applydiscount: boolean) {
    let newValue = this.valuePlanOne;
    if (applydiscount) {
      newValue = this.applyDiscount(this.valuePlanOne, 10);
      this.setValuePlanTwoChange(newValue);
    }
  }

  applyDiscount(value: number, percentage: number): number {
    if (percentage < 0) {
      percentage = 0;
    }

    if (percentage > 100) {
      percentage = 100;
    }

    const discount = value * (percentage / 100);
    const finalValue = value - discount;

    return finalValue;
  }
}
