<div class="d-flex flex-column bg-dark p-4">
  <div class="d-flex justify-content-end align-items-center">
    <button
      class="fs-4 mb-2 pe-auto p-0"
      style="background: none; border: none; color: #f9f9fb"
      id="closeIframe"
      mat-dialog-close
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-x-lg"
        viewBox="0 0 16 16"
      >
        <path
          d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
        />
      </svg>
    </button>
  </div>
  <!-- <h1>Apresentação</h1> -->

  <div class="embed-responsive embed-responsive-16by9">
    <iframe
      class="embed-responsive-item"
      #meuIframe
      src="https://onedrive.live.com/embed?resid=D792B9AFFF0396DD%21116094&amp;authkey=%21AH1NpZpJMA6_rDM&amp;em=2&amp;wdAr=1.7777777777777777"
      width="920"
      height="440"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>

  <div class="d-flex align-items-center justify-content-center w-full">
    <button
      cclass="d-flex mt-4 p-1 px-2 border rounded"
      style="width: fit-content; text-transform: uppercase; color: white"
      id="activedSlide"
      mat-icon-button
      (click)="ativarModoTelaCheia()"
    >
      <svg
        class="me-1 mb-1"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M19.25 7.5V10.5C19.25 10.6989 19.171 10.8897 19.0303 11.0303C18.8897 11.171 18.6989 11.25 18.5 11.25C18.3011 11.25 18.1103 11.171 17.9697 11.0303C17.829 10.8897 17.75 10.6989 17.75 10.5V8.25H15.5C15.3011 8.25 15.1103 8.17098 14.9697 8.03033C14.829 7.88968 14.75 7.69891 14.75 7.5C14.75 7.30109 14.829 7.11032 14.9697 6.96967C15.1103 6.82902 15.3011 6.75 15.5 6.75H18.5C18.6989 6.75 18.8897 6.82902 19.0303 6.96967C19.171 7.11032 19.25 7.30109 19.25 7.5ZM9.5 15.75H7.25V13.5C7.25 13.3011 7.17098 13.1103 7.03033 12.9697C6.88968 12.829 6.69891 12.75 6.5 12.75C6.30109 12.75 6.11032 12.829 5.96967 12.9697C5.82902 13.1103 5.75 13.3011 5.75 13.5V16.5C5.75 16.6989 5.82902 16.8897 5.96967 17.0303C6.11032 17.171 6.30109 17.25 6.5 17.25H9.5C9.69891 17.25 9.88968 17.171 10.0303 17.0303C10.171 16.8897 10.25 16.6989 10.25 16.5C10.25 16.3011 10.171 16.1103 10.0303 15.9697C9.88968 15.829 9.69891 15.75 9.5 15.75ZM22.25 5.25V18.75C22.25 19.1478 22.092 19.5294 21.8107 19.8107C21.5294 20.092 21.1478 20.25 20.75 20.25H4.25C3.85218 20.25 3.47064 20.092 3.18934 19.8107C2.90804 19.5294 2.75 19.1478 2.75 18.75V5.25C2.75 4.85218 2.90804 4.47064 3.18934 4.18934C3.47064 3.90804 3.85218 3.75 4.25 3.75H20.75C21.1478 3.75 21.5294 3.90804 21.8107 4.18934C22.092 4.47064 22.25 4.85218 22.25 5.25ZM20.75 18.75V5.25H4.25V18.75H20.75Z"
          fill="white"
        />
      </svg>
      TELA CHEIA
    </button>
  </div>
</div>
