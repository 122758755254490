import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getSettingsSelectors } from 'src/app/modules/settings/store/settings.selectors';
import { ISettingsState } from 'src/app/modules/settings/store/settings.state';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  panelOpenState = true;
  cards: any[] = [
    {
      title: 'Financial Life Plan',
      icon: '<svg width="25" height="28" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_2531_1234)"><path d="M9.07834 0C4.07245 0 0 4.07245 0 9.07834V10.4639H6.69143V18.6397C6.69143 23.8012 10.9057 28 16.0869 28H17.4441V13.9072H17.4544V10.4639H19.0747C21.985 10.4639 24.353 8.0959 24.353 5.18554V0H9.07834ZM14.7289 25.1575C11.6947 24.5318 9.40668 21.8466 9.40668 18.6397V10.4639H14.7194V13.9072H14.7289V25.1575ZM21.5828 5.18554C21.581 6.56938 20.4594 7.69106 19.0755 7.69278H2.92498C3.55759 4.87868 6.07687 2.77112 9.07834 2.77112H21.5828V5.18554Z" fill="#0FC4DC"/></g><defs><clipPath id="clip0_2531_1234"><rect width="24.5" height="28" fill="white"/></clipPath></defs></svg>',
      content_text: [
        'Tenha o relatório completo da sua vida financeira atual e projeção futura num só lugar.',
      ],
      image: 'assets/img/image-flp.png',
    },
  ];

  constructor(private sanitizer: DomSanitizer, private store: Store) {}

  ngOnInit(): void {
    this.getState();
    this.cards = this.cards.map((card) => ({
      ...card,
      icon: this.sanitizer.bypassSecurityTrustHtml(card.icon),
    }));
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };
    });
  }
}
