<div class="container-main__slider">
  <div class="keen-slider" #sliderRef>
    <!-- Slide 1 -->
    <div class="keen-slider__slide slider-content">
      <img
        src="../../../assets/img-gray.png"
        width="528px"
        height="339px"
        alt=""
      />

      <div class="slider__details">
        <h1>1</h1>
        <h2>Apresentação da consultoria</h2>
        <p>
          Apresente ao seu cliente o processo de consultoria, indicando
          beneficios, entregaveis e resultados possiveis.
        </p>
      </div>
    </div>

    <!-- Slide 2 -->
    <div class="keen-slider__slide slider-content">
      <img
        src="../../../assets/img-gray.png"
        width="528px"
        height="339px"
        alt=""
      />

      <div class="slider__details">
        <h1>2</h1>
        <h2>Seleção de objetivos pessoais</h2>
        <p>
          Junto com seu cliente, mapeie os principais objetivos pessoais dele e
          suas descrições.
        </p>
      </div>
    </div>

    <!-- Slide 3 -->
    <div class="keen-slider__slide slider-content">
      <img
        src="../../../assets/img-gray.png"
        width="528px"
        height="339px"
        alt=""
      />

      <div class="slider__details">
        <h1>3</h1>
        <h2>Coleta de dados sobre cliente e objetivos</h2>
        <p>
          Conheça os comportamentos financeiros do seu cliente através do
          questionário de diagnóstico.
        </p>
      </div>
    </div>

    <!-- Slide 4 -->
    <div class="keen-slider__slide slider-content">
      <img
        src="../../../assets/img-gray.png"
        width="528px"
        height="339px"
        alt=""
      />

      <div class="slider__details">
        <h1>4</h1>
        <h2>Proposta comercial</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur. Nibh vel vitae nulla
          pellentesque varius. Semper commodo venenatis ipsum enim enim nisl.
        </p>
      </div>
    </div>
  </div>

  <div class="dots">
    <button
      (click)="slider.moveToIdx(i, animation)"
      *ngFor="let slide of dotHelper; let i = index"
      [class]="'dot ' + (i === currentSlide ? 'active' : '')"
    ></button>
  </div>
</div>
