import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IObjectivesFormState } from '../../../store/objective.state';
import { setObjectiveVehiclesAction } from '../../../store/objective.actions';
import { getObjectiveVehiclesSelectors } from '../../../store/objective.selectors';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent implements OnInit {
  panelOpenState = true;
  dataStoreVehicles$: Observable<any>;
  dataStoreVehicles: IObjectivesFormState;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreVehicles$ = this.store.select(getObjectiveVehiclesSelectors);

    this.dataStoreVehicles$.subscribe((value) => {
      this.dataStoreVehicles = {
        ...(this.dataStoreVehicles = value),
      };
    });
  }

  unCheck(): void {
    this.dataStoreVehicles = {
      ...this.dataStoreVehicles,
      isSelected: !this.dataStoreVehicles.isSelected,
    };
    this.setState(this.dataStoreVehicles);
  }

  setState(data: IObjectivesFormState) {
    this.store.dispatch(setObjectiveVehiclesAction(data));
  }

  setValuesForm(): void {
    if (isNaN(this.dataStoreVehicles.maximumInvestment)) {
      this.dataStoreVehicles.maximumInvestment = 0;
    }

    if (isNaN(this.dataStoreVehicles.minimumInvestment)) {
      this.dataStoreVehicles.minimumInvestment = 0;
    }

    this.dataStoreVehicles.check =
      this.dataStoreVehicles.description &&
      this.dataStoreVehicles.date &&
      this.dataStoreVehicles.maximumInvestment
        ? true
        : false;
    this.setState(this.dataStoreVehicles);
  }

  updateAmountMin(event): void {
    this.dataStoreVehicles.minimumInvestment = event;
    this.setValuesForm();
  }

  updateAmountMax(event): void {
    this.dataStoreVehicles.maximumInvestment = event;
    this.setValuesForm();
  }
}
