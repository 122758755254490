import { Component, OnInit } from '@angular/core';

import { getObjectiveTripsSelectors } from 'src/app/modules/objective/store/objective.selectors';
import { IObjectivesFormState } from 'src/app/modules/objective/store/objective.state';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-trips',
  templateUrl: './pdf-trips.component.html',
  styleUrls: ['./pdf-trips.component.scss'],
})
export class PdfTripsComponent implements OnInit {
  dataStoreTrips$: Observable<any>;
  dataStoreTrips: IObjectivesFormState;
  intendDate: any;
  minInvestimentFormated: string;
  maxInvestimentFormated: string;

  constructor(private store: Store) {}

  getState() {
    this.dataStoreTrips$ = this.store.select(getObjectiveTripsSelectors);
    this.dataStoreTrips$.subscribe((value) => {
      this.dataStoreTrips = {
        ...(this.dataStoreTrips = value),
      };
    });

    this.intendDate = this.dataStoreTrips.date;

    if (typeof this.intendDate === 'string') {
      this.intendDate = this.formatedDate(this.intendDate);
    } else {
      this.intendDate = 'data não especificada!';
    }

    this.minInvestimentFormated = this.formatarMoeda(
      this.dataStoreTrips.minimumInvestment
    );
    this.maxInvestimentFormated = this.formatarMoeda(
      this.dataStoreTrips.maximumInvestment
    );
  }

  ngOnInit(): void {
    this.getState();
  }

  formatedDate(dataString: string): string {
    const parts = dataString.split('-');
    if (parts.length === 3) {
      const [year, month, day] = parts;
      return `${day}-${month}-${year}`;
    }
  }

  formatarMoeda(valor: number): string {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
