import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IInsuranceState } from './insurance.state';

export const getInsuranceSelectorsState =
  createFeatureSelector<IInsuranceState>('insuranceReduce');

export const getInsuranceSelectors = createSelector(
  getInsuranceSelectorsState,
  (store: IInsuranceState) => {
    return store;
  }
);
