<section>
  <div class="d-flex flex-column">
    <label class="form-label mt-3 fs-6">Tipo de crédito</label>
    <div class="input-group mb-3">
      <select
        class="form-select px-2"
        [(ngModel)]="dataLocal.typeDebt"
        (change)="updateDataValue(dataLocal.typeDebt, 'typeDebt')"
        [value]="dataLocal.typeDebt"
        [id]="typeDebtKey"
      >
        <option [value]="'Financiamento de veículo'">
          Financiamento Veicular
        </option>
        <option [value]="'Financiamento de imóvel'">
          Financiamento Imobiliário
        </option>
        <option [value]="'Cartão de Crédito'">Cartão de Crédito</option>
        <option [value]="'Crédito Consignado'">Crédito Consignado</option>
        <option [value]="'Familiar / Amigo'">Familiar / Amigo</option>
        <option [value]="'Outros'">Outros</option>
        <label class="input-group-text" [for]="typeDebtKey">Options</label>
      </select>
    </div>
  </div>

  <div class="row mt-2 mb-4">
    <div class="col-md-4">
      <label class="form-label mt-2 fs-6">Valor do contrato</label>
      <app-prefixed-input
        [elementID]="contractValueKey"
        [pref]="'R$'"
        (parentValue)="updateDataValue($event, 'contractValue')"
        [callback]="data.contractValue"
      ></app-prefixed-input>
    </div>

    <div class="col-md-4">
      <label class="form-label mt-2 fs-6">Valor da parcela</label>
      <app-prefixed-input
        [elementID]="installmentValueKey"
        [pref]="'R$'"
        (parentValue)="updateDataValue($event, 'installmentValue')"
        [callback]="data.installmentValue"
      ></app-prefixed-input>
    </div>

    <div class="col-md-4">
      <label class="form-label mt-2 fs-6">Taxa de Juros</label>
      <div class="input-group mt-2">
        <label class="input-group-text px-3" for="inputGroupSelect01">%</label>
        <input
          [id]="interestRateKey"
          type="text"
          class="form-control p-1 w-25"
          [(ngModel)]="dataLocal.interestRate"
          (change)="updateDataValue(dataLocal.interestRate, 'interestRate')"
        />
        <select
          class="form-select px-2"
          [(ngModel)]="dataLocal.interestRateChoose"
          (change)="
            updateDataValue(dataLocal.interestRateChoose, 'interestRateChoose')
          "
          [value]="dataLocal.interestRateChoose"
          [id]="interestRateChooseKey"
        >
          <option [value]="'Ao Mês'" selected>Ao mês</option>
          <option [value]="'Ao Ano'">Ao ano</option>
        </select>
      </div>
    </div>
  </div>

  <!-- <div class="">
    <label class="form-label mt-2  fs-6">Taxa de juros</label>
    <app-prefixed-input
      [elementID]="interestRatePerMonthKey"
      [pref]="'%'"
      (parentValue)="updateDataValue($event, 'interestRatePerMonth')"
      [callback]="data.interestRatePerMonth"
    ></app-prefixed-input>
  </div> -->
</section>
