import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISettingPlansState } from './setting-plans.state';


export const getSettingPlansSelectorState =
  createFeatureSelector<ISettingPlansState>('SettingPlansReduce');

export const getSettingPlansSelectors = createSelector(
  getSettingPlansSelectorState,
  (store: any) => {
    return store;
  }
);
