import { Component, OnInit } from '@angular/core';
import { IPensionState } from './store/pension.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { setPensionAction } from './store/pension.actions';
import { getPensionSelectors } from './store/pension.selectors';

@Component({
  selector: 'app-pension',
  templateUrl: './pension.component.html',
  styleUrls: ['./pension.component.scss'],
})
export class PensionComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IPensionState>;
  dataState: IPensionState;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IPensionState) {
    this.store.dispatch(setPensionAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getPensionSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.pension != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }

  updateAmount(event): void {
    this.dataState.amount = event;
    this.setValuesForm();
  }

  updateNotes(event): void {
    this.dataState.notes = event;
    this.setValuesForm();
  }
}
