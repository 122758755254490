<div>
  <div class="d-flex align-items-center align-content-center gap-2">
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0001 1V9C28.0001 9.26522 27.8947 9.51957 27.7072 9.70711C27.5196 9.89464 27.2653 10 27.0001 10C26.7349 10 26.4805 9.89464 26.293 9.70711C26.1054 9.51957 26.0001 9.26522 26.0001 9V3.41375L15.7076 13.7075C15.6147 13.8005 15.5044 13.8742 15.383 13.9246C15.2616 13.9749 15.1315 14.0008 15.0001 14.0008C14.8687 14.0008 14.7385 13.9749 14.6171 13.9246C14.4957 13.8742 14.3854 13.8005 14.2926 13.7075L10.0001 9.41375L1.70757 17.7075C1.51993 17.8951 1.26543 18.0006 1.00007 18.0006C0.734704 18.0006 0.480209 17.8951 0.292568 17.7075C0.104927 17.5199 -0.000488281 17.2654 -0.000488281 17C-0.000488281 16.7346 0.104927 16.4801 0.292568 16.2925L9.29257 7.2925C9.38544 7.19952 9.49573 7.12576 9.61713 7.07544C9.73853 7.02512 9.86865 6.99921 10.0001 6.99921C10.1315 6.99921 10.2616 7.02512 10.383 7.07544C10.5044 7.12576 10.6147 7.19952 10.7076 7.2925L15.0001 11.5863L24.5863 2H19.0001C18.7349 2 18.4805 1.89464 18.293 1.70711C18.1054 1.51957 18.0001 1.26522 18.0001 1C18.0001 0.734784 18.1054 0.48043 18.293 0.292893C18.4805 0.105357 18.7349 0 19.0001 0H27.0001C27.2653 0 27.5196 0.105357 27.7072 0.292893C27.8947 0.48043 28.0001 0.734784 28.0001 1Z"
        fill="#0FC4DC"
      />
    </svg>
    <h2 class="p-0 m-0 fw-medium">Projeto de Consultoria</h2>
  </div>
  <div class="mt-2">
    <span class="ms-2">Selecione uma opção abaixo: </span>
  </div>

  <div class="row gap-4 m-2 mt-4">
    <div
      style="cursor: pointer"
      (click)="selectCreditCard()"
      class="col d-flex flex-column border rounded shadow-sm p-2 ps-3"
      [ngClass]="
        dataJobOfferState.consultancyProject === 'Credit Card'
          ? 'bg-primary-subtle'
          : ''
      "
    >
      <h2 class="p-0 m-0 mb-3">
        {{
          !dataStoreSettingJob.automaticCalculation
            ? dataSettingPlansState.planNameOne
            : "Wealth Planning"
        }}
      </h2>
      <h3
        *ngIf="ticketApplied"
        class="m-0 text-decoration-line-through fw-medium text-secondary"
      >
        {{ dataJobOfferState.creditCardValue }}
      </h3>
      <h2 class="p-0 m-0 mb-2 fw-medium fs-4">
        {{ dataJobOfferState.creditCardValueWithDiscount }}
      </h2>
      <span class=""
        >{{
          dataSettingPlansState.paymentChoiceOne
            ? dataSettingPlansState.paymentChoiceOne
            : "Cartão de Crédito"
        }}
      </span>
      <button
        class="btn text-primary fw-bold border border-black py-2 mt-4 mb-2"
      >
        <span *ngIf="dataJobOfferState.consultancyProject === 'Credit Card'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
          >
            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
          </svg>
          Selecionado</span
        >
        <span *ngIf="dataJobOfferState.consultancyProject != 'Credit Card'"
          >Selecionar</span
        >
      </button>
    </div>

    <div
      style="cursor: pointer"
      (click)="selectInCash()"
      class="col d-flex flex-column border rounded shadow-sm p-2 ps-3"
      [ngClass]="
        dataJobOfferState.consultancyProject === 'In Cash'
          ? 'bg-primary-subtle'
          : ''
      "
    >
      <h2 class="p-0 m-0 mb-3">
        {{
          !dataStoreSettingJob.automaticCalculation
            ? dataSettingPlansState.planNameTwo
            : "Wealth Planning"
        }}
      </h2>
      <div class="d-flex flex-column mb-2">
        <h3
          *ngIf="ticketApplied"
          class="m-0 text-decoration-line-through fw-medium text-secondary"
        >
          {{ dataJobOfferState.inCashValue }}
        </h3>
        <div class="d-flex gap-2 align-items-center">
          <h2 class="p-0 m-0 fw-medium fs-4">
            {{ dataJobOfferState.inCashValueWithDiscount }}
          </h2>
          <p
            *ngIf="discountLabelEnable"
            style="font-size: 12px"
            class="m-0 border px-2 rounded fw-medium"
            [ngClass]="
              dataJobOfferState.consultancyProject === 'In Cash'
                ? 'border-dark-subtle'
                : ''
            "
          >
            10% Desconto
          </p>
        </div>
      </div>
      <span>{{
        dataSettingPlansState.paymentChoiceTwo
          ? dataSettingPlansState.paymentChoiceTwo
          : "À vista (PIX ou Boleto)"
      }}</span>
      <button
        class="btn bg-primary border border-primary text-white fw-bold py-2 mt-4"
      >
        <span *ngIf="dataJobOfferState.consultancyProject === 'In Cash'">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="currentColor"
          >
            <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
          </svg>
          Selecionado</span
        >
        <span *ngIf="dataJobOfferState.consultancyProject != 'In Cash'"
          >Selecionar</span
        >
      </button>
    </div>
  </div>

  <div class="d-flex align-items-end ms-2 mt-4 pb-4 gap-3 border-bottom">
    <button
      (click)="enableTicket()"
      class="btn m-0 p-0 rounded-5"
      [ngClass]="ticketEnabled ? 'bg-primary-subtle' : ''"
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z"
          stroke="#73777F"
        />
        <path
          d="M30.8103 20.75L21.5 11.4397C21.3612 11.2998 21.196 11.1889 21.014 11.1134C20.832 11.0379 20.6368 10.9994 20.4397 11H11.75C11.5511 11 11.3603 11.079 11.2197 11.2197C11.079 11.3603 11 11.5511 11 11.75V20.4397C10.9994 20.6368 11.0379 20.832 11.1134 21.014C11.1889 21.196 11.2998 21.3612 11.4397 21.5L20.75 30.8103C20.8893 30.9496 21.0547 31.0602 21.2367 31.1356C21.4187 31.211 21.6138 31.2498 21.8108 31.2498C22.0078 31.2498 22.2029 31.211 22.3849 31.1356C22.5669 31.0602 22.7323 30.9496 22.8716 30.8103L30.8103 22.8716C30.9496 22.7323 31.0602 22.5669 31.1356 22.3849C31.211 22.2029 31.2498 22.0078 31.2498 21.8108C31.2498 21.6138 31.211 21.4187 31.1356 21.2367C31.0602 21.0547 30.9496 20.8893 30.8103 20.75ZM21.8103 29.75L12.5 20.4397V12.5H20.4397L29.75 21.8103L21.8103 29.75ZM17 15.875C17 16.0975 16.934 16.315 16.8104 16.5C16.6868 16.685 16.5111 16.8292 16.3055 16.9144C16.1 16.9995 15.8738 17.0218 15.6555 16.9784C15.4373 16.935 15.2368 16.8278 15.0795 16.6705C14.9222 16.5132 14.815 16.3127 14.7716 16.0945C14.7282 15.8763 14.7505 15.6501 14.8356 15.4445C14.9208 15.2389 15.065 15.0632 15.25 14.9396C15.435 14.816 15.6525 14.75 15.875 14.75C16.1734 14.75 16.4595 14.8685 16.6705 15.0795C16.8815 15.2905 17 15.5766 17 15.875Z"
          fill="#0A5DA1"
        />
      </svg>
    </button>
    <div
      *ngIf="ticketEnabled"
      class="d-flex flex-column align-content-center gap-1"
    >
      <span class="fw-medium">Cupom</span>
      <div class="d-flex gap-2">
        <input
          class="p-2 form-control focus-ring focus-ring-light border border-dark rounded-1"
          type="text"
          (input)="onInputChange()"
          [(ngModel)]="inputValue"
          (keypress)="validateInput($event)"
          name="ticketInput"
          id="ticketInput"
        />
        <button
          (click)="applyTicket()"
          class="btn border fw-bold px-3 py-2 rounded-1"
          [ngClass]="[
            ticketApplied ? 'text-secondary' : 'text-primary',
            ticketApplied ? 'disabled' : '',
            ticketApplied ? 'border-secondary' : 'border-black'
          ]"
        >
          <span class="d-flex" *ngIf="ticketApplied">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="currentColor"
            >
              <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
            </svg>
            Aplicado</span
          >
          <span *ngIf="!ticketApplied">Aplicar</span>
        </button>
      </div>
    </div>
  </div>
</div>
