import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIncomeTaxState } from './incomeTax.state';

export const getIncomeTaxSelectorsState =
  createFeatureSelector<IIncomeTaxState>('incomeTaxReduce');

export const getIncomeTaxSelectors = createSelector(
  getIncomeTaxSelectorsState,
  (store: IIncomeTaxState) => {
    return store;
  }
);
