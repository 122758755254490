import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-collect-data-pdf',
  templateUrl: './collect-data-pdf.component.html',
  styleUrls: ['./collect-data-pdf.component.scss']
})
export class CollectDataPdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
