import { createAction, props } from '@ngrx/store';
import { IInvestmentsState } from './investments.state';

export const initInvestmentsAction = createAction(
  '[Investments Page] Investments page'
);

export const setInvestmentsAction = createAction(
  '[Investments Page] set data',
  props<IInvestmentsState>()
);
