import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu-collect',
  templateUrl: './menu-collect.component.html',
  styleUrls: ['./menu-collect.component.scss'],
})
export class MenuCollectComponent implements OnInit {
  @Input() menuTopicID: number;
  @Output() topicValue = new EventEmitter<number>();
  maxTopicID: number = 1;

  updateData(num: number): void {
    this.topicValue.emit(num);
    this.maxTopicID = num > this.maxTopicID ? num : this.maxTopicID;
  }

  constructor() {}

  ngOnInit(): void {
    this.maxTopicID = this.menuTopicID;
  }

  ngOnChanges(): void {
    this.maxTopicID =
      this.maxTopicID < this.menuTopicID ? this.menuTopicID : this.maxTopicID;
  }
}
