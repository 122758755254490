import { createReducer, on } from '@ngrx/store';
import { IPresentationState } from './presentation.state';
import {
  initPresentationAction,
  setPresentationAction,
} from './presentation.actions';

export const initialPresentationState: IPresentationState = {
  check: false,
};

export const presentationReduce = createReducer(
  initialPresentationState,
  on(initPresentationAction, (state) => ({
    ...state,
    check: false,
  })),
  on(setPresentationAction, (state, { check }) => ({
    ...state,
    check: check,
  }))
);
