import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-end-of-life',
  templateUrl: './end-of-life.component.html',
  styleUrls: ['./end-of-life.component.scss']
})
export class EndOfLifeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
