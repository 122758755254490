<div class="row">
  <div class="col-4">
    <span style="font-size: 16px" class="p-0 m-0">Desconto à vista</span>
    <p style="font-size: 12px" class="p-0 m-0 fw-light">
      Mínimo 1% - Máximo 30%
    </p>
  </div>
  <div class="col-8">
    <div class="row gap-2">
      <div class="col-auto">
        <div class="input-group">
          <input
            class="form-control w-full rounded-end-0 rounded-1 group-ranger"
            style="width: 100px; border: 1px solid black;"
            type="text"
            [(ngModel)]="this.dataStoreSetting.percentualDiscount"
            (input)="changingPercentage()"
          />
          <span class="input-group-text group-ranger" id="basic-addon1">%</span>
        </div>
      </div>

      <div class="col-8 p-2">
        <input
          type="range"
          min="0"
          max="30"
          [(ngModel)]="this.dataStoreSetting.percentualDiscount"
          (input)="changingPercentage()"
        />
      </div>
    </div>
  </div>
</div>
