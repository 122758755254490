import { createReducer, on } from '@ngrx/store';
import { IOnboardingState } from './home.state';
import { initOnboardingAction, setOnboardingAction } from './home.actions';

export const initialOnboardingState: IOnboardingState = {
  check: false,
};

export const onboardingReduce = createReducer(
  initialOnboardingState,
  on(initOnboardingAction, (state) => ({
    ...state,
    check: false,
  })),
  on(setOnboardingAction, (state, { check }) => ({
    ...state,
    check: check,
  }))
);
