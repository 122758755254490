import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-input-toast',
  templateUrl: './input-toast.component.html',
  styleUrls: ['./input-toast.component.scss'],
})
export class InputToastComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}

  ngOnInit(): void {
  }
}
