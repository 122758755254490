import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-default-pricing',
  templateUrl: './default-pricing.component.html',
  styleUrls: ['./default-pricing.component.scss'],
})
export class DefaultPricingComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
