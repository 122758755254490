import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IJobFormState } from './job-form.state';

export const getJobFormSelectorsState =
  createFeatureSelector<IJobFormState>('jobFormReduce');

export const getJobFormSelectors = createSelector(
  getJobFormSelectorsState,
  (store: IJobFormState) => {
    return store;
  }
);