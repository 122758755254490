<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #cc5120;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M27 7H22V6C22 5.20435 21.6839 4.44129 21.1213 3.87868C20.5587 3.31607 19.7956 3 19 3H13C12.2044 3 11.4413 3.31607 10.8787 3.87868C10.3161 4.44129 10 5.20435 10 6V7H5C4.46957 7 3.96086 7.21071 3.58579 7.58579C3.21071 7.96086 3 8.46957 3 9V25C3 25.5304 3.21071 26.0391 3.58579 26.4142C3.96086 26.7893 4.46957 27 5 27H27C27.5304 27 28.0391 26.7893 28.4142 26.4142C28.7893 26.0391 29 25.5304 29 25V9C29 8.46957 28.7893 7.96086 28.4142 7.58579C28.0391 7.21071 27.5304 7 27 7ZM12 6C12 5.73478 12.1054 5.48043 12.2929 5.29289C12.4804 5.10536 12.7348 5 13 5H19C19.2652 5 19.5196 5.10536 19.7071 5.29289C19.8946 5.48043 20 5.73478 20 6V7H12V6ZM27 9V14.2013C23.6247 16.0385 19.8429 17.0007 16 17C12.1573 17.0007 8.37553 16.0389 5 14.2025V9H27ZM27 25H5V16.455C8.42491 18.1302 12.1873 19.0007 16 19C19.8127 19.0001 23.5751 18.1292 27 16.4538V25ZM13 14C13 13.7348 13.1054 13.4804 13.2929 13.2929C13.4804 13.1054 13.7348 13 14 13H18C18.2652 13 18.5196 13.1054 18.7071 13.2929C18.8946 13.4804 19 13.7348 19 14C19 14.2652 18.8946 14.5196 18.7071 14.7071C18.5196 14.8946 18.2652 15 18 15H14C13.7348 15 13.4804 14.8946 13.2929 14.7071C13.1054 14.5196 13 14.2652 13 14Z"
      fill="#CC5120"
    />
  </svg>
  <h4 class="fs-5 mt-3">INSS</h4>
</header>
<form class="px-3 border rounded">
  <div class="mt-2 mb-2">
    <label class="form-label mt-2 mb-1 fs-6">Você contribui para o INSS?</label>
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="true"
          id="radio-3"
          name="retirement"
          [(ngModel)]="dataState.contribute"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-3">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="false"
          id="radio-4"
          name="retirement"
          [(ngModel)]="dataState.contribute"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-4">Não</label>
      </div>
    </div>
  </div>

  <div class="border-top" *ngIf="dataState.contribute">
    <label class="form-label mt-3 fs-6">Quanto? (Por mês)</label>
    <app-prefixed-input
      [elemtentID]="'valueMonth'"
      [pref]="'R$'"
      (parentValue)="updateAmount($event, 'month')"
      [callback]="dataState.amountMonth"
    ></app-prefixed-input>
  </div>

  <div *ngIf="dataState.contribute" class="mt-2 mb-2">
    <label class="form-label mt-2 mb-1 fs-6"
      >Você acredita que o INSS será suficiente para garantir a aposentadoria
      que você merece?</label
    >
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="true"
          id="radio-1"
          name="retirement-validate"
          [(ngModel)]="dataState.retirement"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-1">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="false"
          id="radio-2"
          name="retirement-validate"
          [(ngModel)]="dataState.retirement"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-2">Não</label>
      </div>
    </div>
  </div>

  <div class="mb-3 border-top">
    <label class="form-label mt-3 fs-6"
      >Quanto de renda passiva você deseja ter ao parar de trabalhar?</label
    >
    <app-prefixed-input
      [elementID]="'amountRetirement'"
      [pref]="'R$'"
      (parentValue)="updateAmount($event, 'retirement')"
      [callback]="dataState.amountRetirement"
    ></app-prefixed-input>
  </div>
</form>
