import { createAction, props } from '@ngrx/store';
import { IInsuranceState } from './insurance.state';

export const initInsuranceAction = createAction(
  '[Insurance Page] Insurance page'
);

export const setInsuranceAction = createAction(
  '[Insurance Page] set data',
  props<IInsuranceState>()
);
