import { Component, OnInit } from '@angular/core';
import { getSettingSelectors } from 'src/app/modules/settings/settings-general/store/setting.selector';
import { ISettingState } from 'src/app/modules/settings/settings-general/store/setting.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-cover-pdf',
  templateUrl: './cover-pdf.component.html',
  styleUrls: ['./cover-pdf.component.scss'],
})
export class CoverPdfComponent implements OnInit {
  constructor(private store: Store) {
    this.currentDay = new Date().getDate();
    this.currentMonth = this.nameMonth[new Date().getMonth()];
    this.currentYear = new Date().getFullYear();
  }
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingState;

  currentDay: Number;
  currentMonth: String;
  currentYear: Number;

  nameMonth: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  ngOnInit(): void {
    this.getStateSetting();
  }

  getStateSetting() {
    this.dataStoreSetting$ = this.store.select(getSettingSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };
    });
  }
}
