<div class="mt-4 mb-2">
  <h3 class="fw-bold">Editar Planos</h3>
  <div class="card">
    <header
      class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
      style="
        background-color: transparent;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        border-left: 4px solid #3199d0;
      "
    >
      <div class="ms-2">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.4999 5.25V11.25C22.4999 11.4489 22.4209 11.6397 22.2803 11.7803C22.1396 11.921 21.9488 12 21.7499 12C21.551 12 21.3603 11.921 21.2196 11.7803C21.0789 11.6397 20.9999 11.4489 20.9999 11.25V7.06031L13.2806 14.7806C13.2109 14.8504 13.1282 14.9057 13.0371 14.9434C12.9461 14.9812 12.8485 15.0006 12.7499 15.0006C12.6514 15.0006 12.5538 14.9812 12.4627 14.9434C12.3717 14.9057 12.289 14.8504 12.2193 14.7806L8.99993 11.5603L2.78055 17.7806C2.63982 17.9214 2.44895 18.0004 2.24993 18.0004C2.05091 18.0004 1.86003 17.9214 1.7193 17.7806C1.57857 17.6399 1.49951 17.449 1.49951 17.25C1.49951 17.051 1.57857 16.8601 1.7193 16.7194L8.4693 9.96937C8.53896 9.89964 8.62168 9.84432 8.71272 9.80658C8.80377 9.76884 8.90137 9.74941 8.99993 9.74941C9.09849 9.74941 9.19609 9.76884 9.28713 9.80658C9.37818 9.84432 9.4609 9.89964 9.53055 9.96937L12.7499 13.1897L19.9396 6H15.7499C15.551 6 15.3603 5.92098 15.2196 5.78033C15.0789 5.63968 14.9999 5.44891 14.9999 5.25C14.9999 5.05109 15.0789 4.86032 15.2196 4.71967C15.3603 4.57902 15.551 4.5 15.7499 4.5H21.7499C21.9488 4.5 22.1396 4.57902 22.2803 4.71967C22.4209 4.86032 22.4999 5.05109 22.4999 5.25Z"
            fill="#3199D0"
          />
        </svg>
      </div>

      <h4 class="fs-6 mt-3 fw-medium">Projeto de Consultoria</h4>
    </header>

    <div class="card-body" *ngIf="isEnableSettingJob">
      <div class="d-flex gap-4 m-3">
        <div class="text-primary fw-bold">1.</div>

        <div class="row w-100">
          <div class="col-12 mb-3">
            <label for="nomePlano1" class="form-label">Nome do plano</label>
            <input
              [(ngModel)]="planNameOne"
              (ngModelChange)="setPlanNameOneChange($event)"
              type="text"
              class="form-control"
              id="nomePlano1"
            />
          </div>
          <div class="col-sm-12 col-md-4 mb-3">
            <label for="valor1" class="form-label">Valor</label>
            <div class="input-group">
              <span class="input-group-text">R$</span>
              <input
                [(ngModel)]="valuePlanOne"
                (ngModelChange)="setValuePlanOneChange($event)"
                type="number"
                class="form-control"
                id="valor1"
              />
            </div>
          </div>
          <div class="col-12 mb-3">
            <label for="meioPagamento1" class="form-label"
              >Meio de Pagamento</label
            >
            <select
              class="form-select"
              id="meioPagamento1"
              [(ngModel)]="paymentChoiceOne"
              (ngModelChange)="setPaymentChoiceOneChange($event)"
            >
              <option selected>Selecione uma opção</option>
              <option value="À vista (PIX ou Boleto)">
                À vista (PIX ou Boleto)
              </option>
              <option value="Cartão de Crédito">Cartão de Crédito</option>
              <option value="Cartão de Débito">Cartão de Débito</option>
            </select>
          </div>
        </div>
      </div>

      <hr class="mt-2 mb-2" />

      <div class="d-flex gap-4 m-3">
        <div class="text-primary fw-bold">2.</div>
        <div class="w-100">
          <div>
            <div class="form-check form-switch mb-3 mt-4">
              <input
                [(ngModel)]="automaticSelected"
                (ngModelChange)="setAutomaticSelectedChange($event)"
                class="form-check-input"
                type="checkbox"
                id="considerar10Automatico"
              />
              <label class="form-check-label" for="considerar10Automatico">
                Considerar 10% Automático
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <label for="nomePlano2" class="form-label">Nome do plano</label>
              <input
                [(ngModel)]="planNameTwo"
                (ngModelChange)="setPlanNameTwoChange($event)"
                type="text"
                class="form-control"
                id="nomePlano2"
              />
            </div>
            <div class="col-sm-12 col-md-4 mb-3">
              <label for="valor2" class="form-label">Valor</label>
              <div class="input-group">
                <span class="input-group-text">R$</span>
                <input
                  [(ngModel)]="valuePlanTwo"
                  type="number"
                  (ngModelChange)="setValuePlanTwoChange($event)"
                  [disabled]="automaticSelected == true"
                  class="form-control"
                  id="valor2"
                />
              </div>
            </div>
            <div class="col-12 mb-3">
              <label for="meioPagamento2" class="form-label"
                >Meio de Pagamento</label
              >
              <select
                class="form-select"
                id="meioPagamento2"
                [(ngModel)]="paymentChoiceTwo"
                (ngModelChange)="setPaymentChoiceTwoChange($event)"
              >
                <option selected>Selecione uma opção</option>
                <option value="À vista (PIX ou Boleto)">
                  À vista (PIX ou Boleto)
                </option>
                <option value="Cartão de Crédito">Cartão de Crédito</option>
                <option value="Cartão de Débito">Cartão de Débito</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
