import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { PdfReportComponent } from '../../modules/pdf-report/pdf-report.component';
import html2pdf from 'html2pdf.js';
import { MatDialog } from '@angular/material/dialog';
import { ModalEndComponent } from './components/modal-end/modal-end.component';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISettingState } from 'src/app/modules/settings/settings-general/store/setting.state';
import { getSettingSelectorState } from 'src/app/modules/settings/settings-general/store/setting.selector';
import { Router } from '@angular/router';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss'],
})
export class EndComponent implements OnInit {
  @ViewChild('pdfReport', { static: true, read: ViewContainerRef })
  pdfReport: ViewContainerRef;

  constructor(
    private readonly resolver: ComponentFactoryResolver,
    public dialog: MatDialog,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getState();
  }

  goToPreviusPage(): void {
    this.router.navigate(['/job-offer']);
  }

  beginRecomendation(): void {
    this.router.navigate(['/recomendation']);
  }

  selectItems(): void {
    this.createPDF();
  }

  createPDF(): void {
    this.pdfReport.clear();

    const factory = this.resolver.resolveComponentFactory(PdfReportComponent);
    const componentRef = this.pdfReport.createComponent(factory);

    componentRef.instance.emitter.subscribe(() => {
      const config = {
        html2canvas: {
          scale: 2,
          scrollX: 0,
          scrollY: 0,
        },
        margin: [4, 4],

        pagebreak: { mode: 'avoid-all', before: '#page2el' },
      };
      this.print(componentRef.location.nativeElement, config);
      componentRef.destroy();
    });
  }

  private print(content: any, config: any): void {
    html2pdf()
      .set(config)
      .from(content)
      .toPdf()
      .outputPdf('blob')
      .then((pdfBlob) => {
        const filename = 'Relatório Guia - ' + this.dataConfigState.clientName;
        const a = document.createElement('a');
        const url = URL.createObjectURL(pdfBlob);

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  }

  checkConfigState$: Observable<ISettingState>;
  dataConfigState: ISettingState;

  getState() {
    this.checkConfigState$ = this.store.select(getSettingSelectorState);
    this.checkConfigState$.subscribe((value) => {
      this.dataConfigState = value;
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(ModalEndComponent);
    dialogRef.afterClosed().subscribe();
  }
}
