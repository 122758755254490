<div>
  <footer>
    <div>
      <div class="pe-2 border-end">
        <svg
          class=""
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M13.9746 8.43388L13.4998 8.90864H11.9447L12.6193 8.2341L10.5483 4.37374V20L6.02246 11.5653L6.49722 11.0905H8.05235L7.37779 11.7651L9.4488 15.6254V0L13.9746 8.43388Z"
            fill="#0C0F27"
          />
          <path
            d="M8.82566 9.44994H4.37462L8.23418 7.37895L8.82566 7.97001V6.41532L8.43439 6.02405L0 10.5494H8.82566V9.44994Z"
            fill="#0C0F27"
          />
          <path
            d="M19.999 9.45026L11.565 13.9752L11.1733 13.5843V12.0292L11.7648 12.6207L15.624 10.5497H11.1733V9.45026H19.999Z"
            fill="#0C0F27"
          />
          <path
            d="M4.72568 2.73093L3.94824 3.50836L5.49755 5.05765L6.27499 4.28022L4.72568 2.73093Z"
            fill="#0092DE"
          />
          <path
            d="M15.2734 2.73025L13.7241 4.27954L14.5016 5.05698L16.0509 3.50769L15.2734 2.73025Z"
            fill="#0092DE"
          />
          <path
            d="M5.49755 14.4165L3.94824 15.9658L4.72568 16.7432L6.27499 15.1939L5.49755 14.4165Z"
            fill="#0092DE"
          />
          <path
            d="M14.5045 14.417L13.7271 15.1945L15.2764 16.7437L16.0538 15.9663L14.5045 14.417Z"
            fill="#0092DE"
          />
        </svg>
      </div>
      <div class="ps-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M13.4069 18.9994H12.5385C9.22453 18.9994 6.52881 16.2999 6.52881 12.9823V5.98419H13.4064V19L13.4069 18.9994ZM8.26569 7.72276V12.9823C8.26569 15.0437 9.72924 16.7693 11.6706 17.1722V7.72276H8.26569Z"
            fill="url(#paint0_linear_3656_16472)"
          />
          <path
            d="M13.4133 5.98419H11.6641V9.94046H13.4133V5.98419Z"
            fill="url(#paint1_linear_3656_16472)"
          />
          <path
            d="M14.4497 7.72663H2.24902V6.83598C2.24902 3.61786 4.85381 1 8.05586 1H17.8261V4.3333C17.8261 6.20436 16.3114 7.72663 14.4497 7.72663ZM4.11989 5.94533H14.4497C15.3343 5.94533 16.0537 5.22233 16.0537 4.3333V2.78183H8.05586C6.13603 2.78183 4.52451 4.137 4.11989 5.94533Z"
            fill="url(#paint2_linear_3656_16472)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3656_16472"
              x1="10.0252"
              y1="9.03359"
              x2="9.9105"
              y2="15.9192"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.25" stop-color="#0D7DB4" />
              <stop offset="1" stop-color="#0A5DA1" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_3656_16472"
              x1="11.7421"
              y1="10.4331"
              x2="13.6001"
              y2="4.72533"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.15" stop-opacity="0" />
              <stop offset="0.29" stop-color="#040404" stop-opacity="0.17" />
              <stop offset="0.45" stop-color="#111111" stop-opacity="0.67" />
              <stop offset="0.52" stop-color="#1A1A1A" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_3656_16472"
              x1="8.2614"
              y1="10.9026"
              x2="13.1046"
              y2="-3.97654"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.15" stop-color="#0D7BB3" />
              <stop offset="0.21" stop-color="#0D7FB6" />
              <stop offset="0.28" stop-color="#0F8CBF" />
              <stop offset="0.35" stop-color="#11A2CF" />
              <stop offset="0.42" stop-color="#14C0E5" />
              <stop offset="0.49" stop-color="#19E4FF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
    <p>
      {{ dataStoreSetting.clientName }} - Resumo do Guia gerado em
      {{ currentDay }} de {{ currentMonth }} de {{ currentYear }}
    </p>
  </footer>
</div>
