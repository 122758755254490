<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #6b7680;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M27 9H7C6.73478 9 6.48043 8.89464 6.29289 8.70711C6.10536 8.51957 6 8.26522 6 8C6 7.73478 6.10536 7.48043 6.29289 7.29289C6.48043 7.10536 6.73478 7 7 7H24C24.2652 7 24.5196 6.89464 24.7071 6.70711C24.8946 6.51957 25 6.26522 25 6C25 5.73478 24.8946 5.48043 24.7071 5.29289C24.5196 5.10536 24.2652 5 24 5H7C6.20435 5 5.44129 5.31607 4.87868 5.87868C4.31607 6.44129 4 7.20435 4 8V24C4 24.7956 4.31607 25.5587 4.87868 26.1213C5.44129 26.6839 6.20435 27 7 27H27C27.5304 27 28.0391 26.7893 28.4142 26.4142C28.7893 26.0391 29 25.5304 29 25V11C29 10.4696 28.7893 9.96086 28.4142 9.58579C28.0391 9.21071 27.5304 9 27 9ZM27 25H7C6.73478 25 6.48043 24.8946 6.29289 24.7071C6.10536 24.5196 6 24.2652 6 24V10.8287C6.32109 10.9426 6.65933 11.0005 7 11H27V25ZM21 17.5C21 17.2033 21.088 16.9133 21.2528 16.6666C21.4176 16.42 21.6519 16.2277 21.926 16.1142C22.2001 16.0007 22.5017 15.9709 22.7926 16.0288C23.0836 16.0867 23.3509 16.2296 23.5607 16.4393C23.7704 16.6491 23.9133 16.9164 23.9712 17.2074C24.0291 17.4983 23.9993 17.7999 23.8858 18.074C23.7723 18.3481 23.58 18.5824 23.3334 18.7472C23.0867 18.912 22.7967 19 22.5 19C22.1022 19 21.7206 18.842 21.4393 18.5607C21.158 18.2794 21 17.8978 21 17.5Z"
      fill="#6B7680"
    />
  </svg>
  <h4 class="fs-5 mt-3">Previdência</h4>
</header>

<form class="px-3 border rounded">
  <div class="py-4">
    <label class="form-label fs-6">Você possui previdência?</label>
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="true"
          id="radio-3"
          name="pensionCheck"
          [(ngModel)]="dataState.pension"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-3">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="false"
          id="radio-4"
          name="pensionCheck"
          [(ngModel)]="dataState.pension"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-4">Não</label>
      </div>
    </div>
  </div>

  <div class="" *ngIf="dataState.pension">
    <div class="border-top py-4">
      <label class="form-label fs-6"> Sua previdência é PGBL ou VGBL?</label>
      <div class="d-flex flex-column justify-content-start gap-2">
        <div class="d-flex flex-row form-check align-items-start gap-2">
          <input
            class="form-check-input"
            type="radio"
            id="pgbl"
            name="pensionType"
            value="PGBL (Plano Gerador de Benefício Livre)"
            [(ngModel)]="dataState.typePension"
            (ngModelChange)="setValuesForm()"
          />
          <label for="pgbl">PGBL (Plano Gerador de Benefício Livre)</label>
        </div>

        <div class="d-flex flex-row form-check align-items-start gap-2">
          <input
            class="form-check-input"
            type="radio"
            id="vgbl"
            name="pensionType"
            value="VGBL (Vida Gerador de Benefício Livre)"
            [(ngModel)]="dataState.typePension"
            (ngModelChange)="setValuesForm()"
          />
          <label for="vgbl">VGBL (Vida Gerador de Benefício Livre)</label>
        </div>

        <div class="d-flex flex-row form-check align-items-start gap-2">
          <input
            class="form-check-input"
            type="radio"
            id="unknown"
            name="pensionType"
            value="Não sei"
            [(ngModel)]="dataState.typePension"
            (ngModelChange)="setValuesForm()"
          />
          <label for="unknown">Não sei</label>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4 border-top" *ngIf="dataState.pension">
    <label class="form-label mt-2 fs-6"
      >Qual valor já investido em sua Previdência complementar?</label
    >
    <app-prefixed-input
      [elementID]="'pensionValue'"
      [pref]="'R$'"
      (parentValue)="updateAmount($event)"
      [callback]="dataState.amount"
    ></app-prefixed-input>
  </div>

  <div class="py-4 border-top" *ngIf="dataState.pension">
    <label class="form-label mt-2 fs-6"
      >Quanto você aporta mensalmente para sua previdência complementar?</label
    >
    <app-prefixed-input
      [elementID]="'pensionNotes'"
      [pref]="'R$'"
      (parentValue)="updateNotes($event)"
      [callback]="dataState.notes"
    ></app-prefixed-input>
  </div>

  <div class="border-top" *ngIf="dataState.pension">
    <label class="form-label mt-3 fs-6"
      >Tem interesse em receber proposta de portabilidade previdenciária para
      obter melhores resultados?</label
    >
    <div class="mt-2 mb-1 form-check">
      <input
        class="form-check-input me-2"
        type="checkbox"
        id="interesse"
        name="interesse"
        [checked]="dataState.interest"
        [(ngModel)]="dataState.interest"
        (ngModelChange)="setValuesForm()"
      />
      <label for="interesse">Sim, tenho interesse</label>
    </div>
  </div>
</form>
