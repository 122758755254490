<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #6b7680;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clip-path="url(#clip0_4582_9889)">
      <path
        d="M31.9997 17C31.9997 17.2652 31.8943 17.5196 31.7068 17.7071C31.5192 17.8946 31.2649 18 30.9997 18H24.9997C24.7345 18 24.4801 17.8946 24.2926 17.7071C24.105 17.5196 23.9997 17.2652 23.9997 17C23.9997 16.7348 24.105 16.4804 24.2926 16.2929C24.4801 16.1054 24.7345 16 24.9997 16H30.9997C31.2649 16 31.5192 16.1054 31.7068 16.2929C31.8943 16.4804 31.9997 16.7348 31.9997 17ZM24.7659 24.3562C24.9367 24.5595 25.0197 24.8222 24.9967 25.0866C24.9737 25.351 24.8466 25.5955 24.6434 25.7662C24.4402 25.937 24.1775 26.02 23.9131 25.997C23.6486 25.974 23.4042 25.847 23.2334 25.6437C20.7184 22.6487 17.2609 21 13.4997 21C9.73843 21 6.28093 22.6487 3.76593 25.6437C3.59519 25.8468 3.35079 25.9737 3.08648 25.9966C2.82217 26.0194 2.55961 25.9364 2.35655 25.7656C2.15349 25.5949 2.02658 25.3505 2.00372 25.0862C1.98087 24.8219 2.06394 24.5593 2.23468 24.3562C4.10218 22.1337 6.42468 20.555 9.00593 19.71C7.43831 18.7336 6.23133 17.2733 5.56759 15.5498C4.90386 13.8264 4.81949 11.9337 5.32724 10.1581C5.83499 8.38242 6.90724 6.82045 8.38176 5.70847C9.85629 4.59649 11.6529 3.995 13.4997 3.995C15.3465 3.995 17.1431 4.59649 18.6176 5.70847C20.0921 6.82045 21.1644 8.38242 21.6721 10.1581C22.1799 11.9337 22.0955 13.8264 21.4318 15.5498C20.768 17.2733 19.561 18.7336 17.9934 19.71C20.5747 20.555 22.8972 22.1337 24.7659 24.3562ZM13.4997 19C14.7853 19 16.042 18.6188 17.1109 17.9045C18.1798 17.1903 19.0129 16.1752 19.5049 14.9874C19.9969 13.7997 20.1256 12.4928 19.8748 11.2319C19.624 9.97103 19.0049 8.81284 18.0959 7.9038C17.1868 6.99476 16.0286 6.37569 14.7678 6.12489C13.5069 5.87409 12.2 6.00281 11.0122 6.49478C9.82451 6.98675 8.80935 7.81987 8.09512 8.88879C7.38089 9.95771 6.99968 11.2144 6.99968 12.5C7.00166 14.2233 7.68712 15.8754 8.90567 17.094C10.1242 18.3126 11.7764 18.998 13.4997 19Z"
        fill="#6B7680"
      />
    </g>
    <defs>
      <clipPath id="clip0_4582_9889">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <h4 class="fs-5 mt-3">Outros Empréstimos e Financiamentos</h4>
</header>

<form class="px-3 border rounded">
  <div class="mt-2 mb-2">
    <label class="form-label mt-2 mb-1 fs-6"
      >Você possui dívidas ou parcelas a pagar?</label
    >
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="true"
          id="radio-1"
          name="debts"
          [(ngModel)]="dataState.debts"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-1">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="false"
          id="radio-2"
          name="debts"
          [(ngModel)]="dataState.debts"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-2">Não</label>
      </div>
    </div>
  </div>

  <div class="mb-2 p-2 border-bottom" *ngIf="dataState.debts">
    <div
      class="border-top border-secondary pt-2 px-3 mb-3"
      *ngFor="let item of listLocal; let i = index"
    >
      <app-forms [data]="item" (value)="updateList($event, i)"></app-forms>

      <button class="btn btn-outline-danger" (click)="deleteList(i)">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path
            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
          />
        </svg>
        EXCLUIR
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <button
      *ngIf="dataState.debts"
      (click)="addList()"
      class="btn btn-outline-primary mb-4 mt-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-plus-lg"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
        />
      </svg>
      Adicionar outro
    </button>
  </div>

  <div *ngIf="dataState.debts" class="border-top">
    <label class="form-label mt-3 fs-6"
      >Você gostaria de receber um estudo visando reduzir o seu custo efetivo
      total?</label
    >
    <div class="mt-2 mb-1 form-check">
      <input
        class="form-check-input me-2"
        type="checkbox"
        id="reduce-costs"
        name="reduce-costs"
        [checked]="dataState.confirmDebts"
        [(ngModel)]="dataState.confirmDebts"
        (ngModelChange)="setValuesForm()"
      />
      <label for="reduce-costs">Sim, gostaria de receber</label>
    </div>
  </div>
</form>
