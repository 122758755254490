<div class="rounded-2">
  <form class="row mt-3 gap-3 rounded-2">
    <fieldset class="pt-2 pb-3 border-bottom">
      <label for="inputPassword5" class="form-label fs-6">Nome</label>
      <input
        [(ngModel)]="datalocal.name"
        (change)="sendValue()"
        type="text"
        name="name"
        placeholder="Somente letras"
        class="form-control"
      />
    </fieldset>

    <fieldset class="pt-2 pb-3 border-bottom">
      <label for="inputPassword5" class="form-label fs-6">Email</label>
      <input
        [(ngModel)]="datalocal.email"
        (change)="sendValue()"
        type="text"
        name="email"
        placeholder="exemplo@email.com"
        class="form-control"
      />
    </fieldset>

    <fieldset class="pt-2 pb-3 border-bottom">
      <label for="inputPassword5" class="form-label fs-6">Telefone</label>
      <input
        [(ngModel)]="datalocal.phone"
        (change)="sendValue()"
        type="text"
        name="phone"
        placeholder="(DDD) 9 XXXX-XXXX"
        class="form-control"
      />
    </fieldset>

    <fieldset class="pt-2 pb-3 border-bottom">
      <label for="inputPassword5" class="form-label fs-6">Observações</label>
      <input
        [(ngModel)]="datalocal.obsevation"
        (change)="sendValue()"
        type="text"
        name="obsevation"
        placeholder="Aqui você pode colocar qualquer observação pertinente ao contato."
        class="form-control"
      />
      <p class="form-text">Máximo 500 caracteres.</p>
    </fieldset>
  </form>
</div>
