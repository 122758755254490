import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  setObjectiveEntrepreneurshipAction,
  setObjectiveMarriageAction,
  setObjectiveOthersAction,
  setObjectivePropertyAction,
  setObjectiveRetirementAction,
  setObjectiveTripsAction,
  setObjectiveVehiclesAction,
} from './store/objective.actions';
import {
  getObjectiveEntrepreneurshipSelectorsState,
  getObjectiveMarriageSelectorsState,
  getObjectiveOthersSelectorsState,
  getObjectivePropertySelectorsState,
  getObjectiveRetirementSelectorsState,
  getObjectiveTripsSelectorsState,
  getObjectiveVehiclesSelectorsState,
} from './store/objective.selectors';
import { Observable } from 'rxjs';
import {
  IObjectiveState,
  IObjectivesFormState,
  IObjectivesRetirementFormState,
} from './store/objective.state';

@Component({
  selector: 'app-objective',
  templateUrl: './objective.component.html',
  styleUrls: ['./objective.component.scss'],
})
export class ObjectiveComponent implements OnInit {
  constructor(private store: Store) {}

  checkRetirementState$: Observable<any>;
  checkTripsState$: Observable<any>;
  checkPropertyState$: Observable<any>;
  checkVehiclesState$: Observable<any>;
  checkEntrepreneurshipState$: Observable<any>;
  checkMarriageState$: Observable<any>;
  checkOthersState$: Observable<any>;

  dataRetirementState: IObjectivesRetirementFormState;
  dataTripsState: IObjectivesFormState;
  dataPropertyState: IObjectivesFormState;
  dataVehiclesState: IObjectivesFormState;
  dataEntrepreneurshipState: IObjectivesFormState;
  dataMarriageState: IObjectivesFormState;
  dataOthersState: IObjectivesFormState;

  enableButton: boolean;

  ngOnInit(): void {
    this.getStates();
  }

  getStates() {
    this.checkRetirementState$ = this.store.select(
      getObjectiveRetirementSelectorsState
    );
    this.checkRetirementState$.subscribe((value) => {
      this.dataRetirementState = value;
    });

    this.checkTripsState$ = this.store.select(getObjectiveTripsSelectorsState);
    this.checkTripsState$.subscribe((value) => {
      this.dataTripsState = value;
    });

    this.checkPropertyState$ = this.store.select(
      getObjectivePropertySelectorsState
    );
    this.checkPropertyState$.subscribe((value) => {
      this.dataPropertyState = value;
    });

    this.checkVehiclesState$ = this.store.select(
      getObjectiveVehiclesSelectorsState
    );
    this.checkVehiclesState$.subscribe((value) => {
      this.dataVehiclesState = value;
    });

    this.checkEntrepreneurshipState$ = this.store.select(
      getObjectiveEntrepreneurshipSelectorsState
    );
    this.checkEntrepreneurshipState$.subscribe((value) => {
      this.dataEntrepreneurshipState = value;
    });

    this.checkMarriageState$ = this.store.select(
      getObjectiveMarriageSelectorsState
    );
    this.checkMarriageState$.subscribe((value) => {
      this.dataMarriageState = value;
    });

    this.checkOthersState$ = this.store.select(
      getObjectiveOthersSelectorsState
    );
    this.checkOthersState$.subscribe((value) => {
      this.dataOthersState = value;
    });
    this.checkContinueButton();
  }

  checkContinueButton() {
    this.enableButton =
      this.dataRetirementState.isSelected ||
      this.dataTripsState.isSelected ||
      this.dataPropertyState.isSelected ||
      this.dataVehiclesState.isSelected ||
      this.dataEntrepreneurshipState.isSelected ||
      this.dataMarriageState.isSelected ||
      this.dataOthersState.isSelected
        ? false
        : true;
  }

  //Retirement
  selectRetirement(): void {
    this.dataRetirementState = {
      ...this.dataRetirementState,
      isSelected: !this.dataRetirementState.isSelected,
    };

    this.store.dispatch(setObjectiveRetirementAction(this.dataRetirementState));
    this.enableButton = this.dataRetirementState.isSelected ? false : true;
    this.checkContinueButton();
  }

  //Trips
  selectTrips(): void {
    this.dataTripsState = {
      ...this.dataTripsState,
      isSelected: !this.dataTripsState.isSelected,
    };

    this.store.dispatch(setObjectiveTripsAction(this.dataTripsState));
    this.enableButton = this.dataTripsState.isSelected ? false : true;
    this.checkContinueButton();
  }

  //Property
  selectProperty(): void {
    this.dataPropertyState = {
      ...this.dataPropertyState,
      isSelected: !this.dataPropertyState.isSelected,
    };

    this.store.dispatch(setObjectivePropertyAction(this.dataPropertyState));
    this.checkContinueButton();
  }

  //Vehicles
  selectVehicles(): void {
    this.dataVehiclesState = {
      ...this.dataVehiclesState,
      isSelected: !this.dataVehiclesState.isSelected,
    };

    this.store.dispatch(setObjectiveVehiclesAction(this.dataVehiclesState));
    this.checkContinueButton();
  }

  //Entrepreneurship
  selectEntrepreneurship(): void {
    this.dataEntrepreneurshipState = {
      ...this.dataEntrepreneurshipState,
      isSelected: !this.dataEntrepreneurshipState.isSelected,
    };

    this.store.dispatch(
      setObjectiveEntrepreneurshipAction(this.dataEntrepreneurshipState)
    );
    this.checkContinueButton();
  }

  //Marriage
  selectMarriage(): void {
    this.dataMarriageState = {
      ...this.dataMarriageState,
      isSelected: !this.dataMarriageState.isSelected,
    };

    this.store.dispatch(setObjectiveMarriageAction(this.dataMarriageState));
    this.checkContinueButton();
  }

  //Other
  selectOthers(): void {
    this.dataOthersState = {
      ...this.dataOthersState,
      isSelected: !this.dataOthersState.isSelected,
    };

    this.store.dispatch(setObjectiveOthersAction(this.dataOthersState));
    this.checkContinueButton();
  }
}
