<div>
  <header
    class="d-flex flex-column w-100 align-items-start gap-2 mb-1 px-2"
    style="
      background-color: transparent;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      border-left: 4px solid #0fc4dc;
    "
  >
    <h4 class="fs-5 mt-3 ms-2 m-0">O Fim da Vida</h4>
    <p class="m-0 ms-2 mb-3">
      Uma reflexão sobre o custo do não planejamento e a importância de dar o
      primeiro passo.
    </p>
  </header>

  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-8 col-lg-6">
      <div class="ratio ratio-16x9">
        <iframe
          src="https://player.vimeo.com/video/886168509"
          title="Fim da Vida"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</div>
