import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

export interface IContactFormsState {
  name: String;
  email: String;
  phone: String;
  obsevation: String;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Input() data: IContactFormsState;
  @Output() value: EventEmitter<IContactFormsState> =
    new EventEmitter<IContactFormsState>();

  datalocal: IContactFormsState;

  constructor() {}

  ngOnInit(): void {
    this.datalocal = { ...this.data };
  }

  getKey(): string {
    return 'componente_' + Math.random().toString(36).substring(7);
  }

  sendValue(): void {
    this.value.emit(this.datalocal);
  }
}
