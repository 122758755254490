import { createAction, props } from '@ngrx/store';
import { IJobFormState } from './job-form.state';

export const initJobFormAction = createAction(
  '[JobForm Page] JobForm page'
);

export const setJobFormAction = createAction(
  '[JobForm Page] set data',
  props<IJobFormState>()
);