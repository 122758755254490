import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IIndividualVehicleState {
  check: boolean;
  model: string;
  yearOfPurchase: number;
  estimatedValue: number;
}

@Component({
  selector: 'app-vehicle-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsVehicleComponent implements OnInit {
  @Input() data: IIndividualVehicleState;
  @Output() value: EventEmitter<IIndividualVehicleState> =
    new EventEmitter<IIndividualVehicleState>();

  constructor() {}

  ngOnInit(): void {
    this.dataLocal = { ...this.data };
  }

  dataLocal: IIndividualVehicleState;

  modelKey = this.getKey();
  yearOfPurchaseKey = this.getKey();
  estimatedValueKey = this.getKey();

  getKey(): string {
    return 'componente_' + Math.random().toString(36).substring(7);
  }

  updateDataValue(value, property: string): void {
    this.dataLocal = {
      ...this.dataLocal,
      [property]: value,
    };
    this.value.emit(this.dataLocal);
  }
}
