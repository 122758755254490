<header
  class="d-flex w-100 align-items-center gap-2 mb-1 rounded"
  style="
    background-color: transparent;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #b010b6;
  "
>
  <svg
    class="ms-2"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M28 6H4C3.46957 6 2.96086 6.21071 2.58579 6.58579C2.21071 6.96086 2 7.46957 2 8V24C2 24.5304 2.21071 25.0391 2.58579 25.4142C2.96086 25.7893 3.46957 26 4 26H28C28.5304 26 29.0391 25.7893 29.4142 25.4142C29.7893 25.0391 30 24.5304 30 24V8C30 7.46957 29.7893 6.96086 29.4142 6.58579C29.0391 6.21071 28.5304 6 28 6ZM28 8V11H4V8H28ZM28 24H4V13H28V24ZM26 21C26 21.2652 25.8946 21.5196 25.7071 21.7071C25.5196 21.8946 25.2652 22 25 22H21C20.7348 22 20.4804 21.8946 20.2929 21.7071C20.1054 21.5196 20 21.2652 20 21C20 20.7348 20.1054 20.4804 20.2929 20.2929C20.4804 20.1054 20.7348 20 21 20H25C25.2652 20 25.5196 20.1054 25.7071 20.2929C25.8946 20.4804 26 20.7348 26 21ZM18 21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H15C14.7348 22 14.4804 21.8946 14.2929 21.7071C14.1054 21.5196 14 21.2652 14 21C14 20.7348 14.1054 20.4804 14.2929 20.2929C14.4804 20.1054 14.7348 20 15 20H17C17.2652 20 17.5196 20.1054 17.7071 20.2929C17.8946 20.4804 18 20.7348 18 21Z"
      fill="#B010B6"
    />
  </svg>
  <h4 class="fs-5 mt-3">Cartão de crédito</h4>
</header>
<form class="px-3 border rounded">
  <div class="mt-2 mb-2">
    <label class="form-label mt-2 mb-1 fs-6">Você usa cartão de crédito?</label>
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          value="Sim"
          id="radio-1"
          name="creditCard"
          [(ngModel)]="dataState.creditCard"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-1">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          value="Não"
          id="radio-2"
          name="creditCard"
          [(ngModel)]="dataState.creditCard"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-2">Não</label>
      </div>
    </div>
  </div>

  <div class="border-top" *ngIf="dataState.creditCard == 'Sim'">
    <label class="form-label mt-3 fs-6"
      >Quantos cartões você possui atualmente?</label
    >
    <input
      class="form-control mt-2 p-2"
      id="credit-cards"
      type="text"
      name="card-quantity"
      placeholder="Quantidade de cartões"
      [(ngModel)]="dataState.cards"
      (ngModelChange)="setValuesForm()"
    />
  </div>

  <div *ngIf="dataState.creditCard == 'Sim'" class="mt-3 mb-2 border-top">
    <label class="form-label mt-2 mb-1 fs-6"
      >Você contabiliza milhas pelo cartão de crédito?</label
    >
    <div class="d-flex flex-row py-2">
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="true"
          id="radio-3"
          name="miles"
          [(ngModel)]="dataState.miles"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-3">Sim</label>
      </div>
      <div class="border hideradio">
        <input
          class="px-4 py-2 border d-none"
          type="radio"
          [value]="false"
          id="radio-4"
          name="miles"
          [(ngModel)]="dataState.miles"
          (ngModelChange)="setValuesForm()"
        />
        <label for="radio-4">Não</label>
      </div>
    </div>
  </div>
</form>
