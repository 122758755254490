import { createReducer, on } from '@ngrx/store';
import { ICollectDataState } from './collect-data.state';
import {
  initCollectDataAction,
  setCollectDataAction,
} from './collect-data.actions';

export const initialCollectDataState: ICollectDataState = {
  check: false,
};

export const collectDataReduce = createReducer(
  initialCollectDataState,
  on(initCollectDataAction, (state) => ({
    ...state,
    check: false,
  })),
  on(setCollectDataAction, (state, { check }) => ({
    ...state,
    check: check,
  }))
);
