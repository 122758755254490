import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPensionState } from './pension.state';

export const getPensionSelectorsState =
  createFeatureSelector<IPensionState>('pensionReduce');

export const getPensionSelectors = createSelector(
  getPensionSelectorsState,
  (store: IPensionState) => {
    return store;
  }
);
