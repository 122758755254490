<section>
  <div class="first-title-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M14.625 3.3125H3.375C3.07663 3.3125 2.79048 3.43103 2.5795 3.642C2.36853 3.85298 2.25 4.13913 2.25 4.4375V8.56977C2.25 14.8705 7.58109 16.9609 8.64844 17.3159C8.8764 17.3935 9.1236 17.3935 9.35156 17.3159C10.4203 16.9609 15.75 14.8705 15.75 8.56977V4.4375C15.75 4.13913 15.6315 3.85298 15.4205 3.642C15.2095 3.43103 14.9234 3.3125 14.625 3.3125ZM14.625 8.57117C14.625 14.0851 9.95977 15.9273 9 16.2479C8.04867 15.9308 3.375 14.09 3.375 8.57117V4.4375H14.625V8.57117Z"
        fill="#0B0C0E"
      />
    </svg>
    <h1 class="heading-h1">Proteções</h1>
  </div>

  <div class="pdf-protection">
    <div class="pdf-protection__health-insurance">
      <div class="title-health-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.5 5.5H10.5V2.5C10.5 2.23478 10.3946 1.98043 10.2071 1.79289C10.0196 1.60536 9.76522 1.5 9.5 1.5H6.5C6.23478 1.5 5.98043 1.60536 5.79289 1.79289C5.60536 1.98043 5.5 2.23478 5.5 2.5V5.5H2.5C2.23478 5.5 1.98043 5.60536 1.79289 5.79289C1.60536 5.98043 1.5 6.23478 1.5 6.5V9.5C1.5 9.76522 1.60536 10.0196 1.79289 10.2071C1.98043 10.3946 2.23478 10.5 2.5 10.5H5.5V13.5C5.5 13.7652 5.60536 14.0196 5.79289 14.2071C5.98043 14.3946 6.23478 14.5 6.5 14.5H9.5C9.76522 14.5 10.0196 14.3946 10.2071 14.2071C10.3946 14.0196 10.5 13.7652 10.5 13.5V10.5H13.5C13.7652 10.5 14.0196 10.3946 14.2071 10.2071C14.3946 10.0196 14.5 9.76522 14.5 9.5V6.5C14.5 6.23478 14.3946 5.98043 14.2071 5.79289C14.0196 5.60536 13.7652 5.5 13.5 5.5ZM13.5 9.5H10C9.86739 9.5 9.74021 9.55268 9.64645 9.64645C9.55268 9.74021 9.5 9.86739 9.5 10V13.5H6.5V10C6.5 9.86739 6.44732 9.74021 6.35355 9.64645C6.25979 9.55268 6.13261 9.5 6 9.5H2.5V6.5H6C6.13261 6.5 6.25979 6.44732 6.35355 6.35355C6.44732 6.25979 6.5 6.13261 6.5 6V2.5H9.5V6C9.5 6.13261 9.55268 6.25979 9.64645 6.35355C9.74021 6.44732 9.86739 6.5 10 6.5H13.5V9.5Z"
            fill="#6813AA"
          />
        </svg>
        <h2 class="heading-h2">Plano de saúde</h2>
      </div>

      <div class="health-insurance__content">
        <div>
          <h3 class="heading-h3">Possui plano de saúde</h3>
          <p class="paragraph">{{ healthInsurance }}</p>
        </div>

        <div *ngIf="dataStoreHealthInsurance.insurance">
          <h3 class="heading-h3">Plano(s)</h3>
          <p class="paragraph">
            {{ dataStoreHealthInsurance.insuranceName }}
          </p>
        </div>

        <!-- <div>
          <h3 class="heading-h3">
            Interesse em receber um estudo personalizado visando reduzir o seu
            custo em planos de saúde
          </h3>
          <p class="paragraph">{{ healthInsuranceInterest }}</p>
        </div> -->
      </div>
    </div>

    <div class="pdf-protection__insurance">
      <div class="title-insurance-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.0001 7.91447C14.8859 6.60565 14.4072 5.35505 13.6182 4.30458C12.8291 3.25412 11.7615 2.44586 10.5363 1.97154C9.31113 1.49723 7.97753 1.37584 6.68686 1.62117C5.39619 1.8665 4.20013 2.46871 3.23443 3.35947C1.94522 4.54384 1.14872 6.16986 1.00318 7.91447C0.991318 8.05254 1.00828 8.19158 1.053 8.32275C1.09771 8.45392 1.16921 8.57437 1.26294 8.67645C1.35667 8.77853 1.4706 8.86001 1.59749 8.91572C1.72439 8.97144 1.86147 9.00017 2.00006 9.00009H7.50006V12.5001C7.50006 13.0305 7.71077 13.5392 8.08585 13.9143C8.46092 14.2894 8.96963 14.5001 9.50006 14.5001C10.0305 14.5001 10.5392 14.2894 10.9143 13.9143C11.2893 13.5392 11.5001 13.0305 11.5001 12.5001C11.5001 12.3675 11.4474 12.2403 11.3536 12.1465C11.2598 12.0528 11.1327 12.0001 11.0001 12.0001C10.8675 12.0001 10.7403 12.0528 10.6465 12.1465C10.5527 12.2403 10.5001 12.3675 10.5001 12.5001C10.5001 12.7653 10.3947 13.0197 10.2072 13.2072C10.0196 13.3947 9.76528 13.5001 9.50006 13.5001C9.23484 13.5001 8.98049 13.3947 8.79295 13.2072C8.60542 13.0197 8.50006 12.7653 8.50006 12.5001V9.00009H14.0001C14.1389 9.0006 14.2763 8.97219 14.4036 8.91667C14.5309 8.86115 14.6452 8.77973 14.7392 8.6776C14.8333 8.57548 14.9051 8.45488 14.95 8.32349C14.9949 8.1921 15.0119 8.0528 15.0001 7.91447ZM2.00006 8.00009C2.11087 6.71208 2.6341 5.49414 3.49204 4.52709C4.34999 3.56004 5.49692 2.89544 6.76256 2.63197C6.03006 3.62509 5.11568 5.38197 5.01068 8.00009H2.00006ZM6.00943 8.00009C6.09631 6.07697 6.66756 4.69947 7.15318 3.86009C7.39263 3.44312 7.67659 3.05335 8.00006 2.69759C8.32293 3.0534 8.60625 3.44318 8.84506 3.86009C9.67506 5.29384 9.93756 6.83009 9.98881 8.00009H6.00943ZM10.9876 8.00009C10.8826 5.38197 9.96818 3.62509 9.23756 2.62884C10.504 2.89173 11.6517 3.55657 12.5099 4.52439C13.368 5.4922 13.8907 6.71126 14.0001 8.00009H10.9876Z"
            fill="#6813AA"
          />
        </svg>
        <h2 class="heading-h2">Seguros</h2>
      </div>
      <div class="insurance__content">
        <div class="content-grid">
          <div>
            <h3 class="heading-h3">Possui seguro(s)</h3>
            <p class="paragraph">{{ insurance }}</p>
          </div>

          <div *ngIf="dataStoreInsurance.insurance" class="anotation">
            <h3 class="heading-h3">Seguro(s)</h3>
            <p class="paragraph">
              {{ dataStoreInsurance.insuranceName }}
            </p>
          </div>

          <div *ngIf="dataStoreInsurance.insurance">
            <h3 class="heading-h3">
              Sente que estes seguros estão adequados as suas necessidades
            </h3>
            <p class="paragraph">{{ dataStoreInsurance.safeWithInsurance }}</p>
          </div>
          <div *ngIf="dataStoreInsurance.insurance">
            <h3 class="heading-h3">
              Interesse em receber um estudo personalizado visando reduzir o seu
              custo em seguros
            </h3>
            <p class="paragraph">{{ insuranceInterest }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="pdf-protection__pension">
      <div class="title-pension-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M13.5 4.5H3.5C3.36739 4.5 3.24021 4.44732 3.14645 4.35355C3.05268 4.25979 3 4.13261 3 4C3 3.86739 3.05268 3.74021 3.14645 3.64645C3.24021 3.55268 3.36739 3.5 3.5 3.5H12C12.1326 3.5 12.2598 3.44732 12.3536 3.35355C12.4473 3.25979 12.5 3.13261 12.5 3C12.5 2.86739 12.4473 2.74021 12.3536 2.64645C12.2598 2.55268 12.1326 2.5 12 2.5H3.5C3.10218 2.5 2.72064 2.65804 2.43934 2.93934C2.15804 3.22064 2 3.60218 2 4V12C2 12.3978 2.15804 12.7794 2.43934 13.0607C2.72064 13.342 3.10218 13.5 3.5 13.5H13.5C13.7652 13.5 14.0196 13.3946 14.2071 13.2071C14.3946 13.0196 14.5 12.7652 14.5 12.5V5.5C14.5 5.23478 14.3946 4.98043 14.2071 4.79289C14.0196 4.60536 13.7652 4.5 13.5 4.5ZM13.5 12.5H3.5C3.36739 12.5 3.24021 12.4473 3.14645 12.3536C3.05268 12.2598 3 12.1326 3 12V5.41437C3.16055 5.47129 3.32966 5.50025 3.5 5.5H13.5V12.5ZM10.5 8.75C10.5 8.60166 10.544 8.45666 10.6264 8.33332C10.7088 8.20999 10.8259 8.11386 10.963 8.05709C11.1 8.00033 11.2508 7.98547 11.3963 8.01441C11.5418 8.04335 11.6754 8.11478 11.7803 8.21967C11.8852 8.32456 11.9566 8.4582 11.9856 8.60368C12.0145 8.74917 11.9997 8.89997 11.9429 9.03701C11.8861 9.17406 11.79 9.29119 11.6667 9.3736C11.5433 9.45601 11.3983 9.5 11.25 9.5C11.0511 9.5 10.8603 9.42098 10.7197 9.28033C10.579 9.13968 10.5 8.94891 10.5 8.75Z"
            fill="#6B7680"
          />
        </svg>
        <h2 class="heading-h2">Previdência</h2>
      </div>

      <div class="pension__content">
        <div class="content-grid">
          <div>
            <h3 class="heading-h3">Possui previdência</h3>
            <p class="paragraph">{{ pension }}</p>
          </div>

          <div class="border-top" *ngIf="dataStorePension.pension">
            <h3 class="heading-h3">Tipo</h3>
            <p class="paragraph">{{ dataStorePension.typePension }}</p>
            <div class="anotation">
              <h3 class="heading-h3">
                Valor investido em sua Previdência complementar?
              </h3>
              <p class="paragraph">
                {{ amoutPensionFormated }}
              </p>
            </div>
            <div>
              <h3 class="heading-h3">Aporte mensal</h3>
              <p class="paragraph">{{ amountPensionMonthFormated }}</p>
            </div>
          </div>
          <div class="border-top" *ngIf="dataStorePension.pension">
            <h3 class="heading-h3">
              Interesse em receber gratuitamente uma proposta de redução do
              custo de seu financiamento?
            </h3>
            <p class="paragraph">{{ pensionInterest }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
