<section>
  <form>
    <div class="mb-2 mt-2">
      <label class="form-label mt-2 fs-6" for="properties">
        Qual o valor estimado dele?</label
      >
      <app-prefixed-input
        [elementID]="estimatedValuePropertyKey"
        [pref]="'R$'"
        (parentValue)="updateDataValue($event, 'estimatedValue')"
        [callback]="dataLocal.estimatedValue"
      ></app-prefixed-input>
    </div>

    <div class="mb-2 mt-4 border-top">
      <label class="form-label mt-4 fs-6"> Esse imóvel é financiado? </label>

      <div class="d-flex flex-row py-2">
        <div class="border hideradio">
          <input
            type="radio"
            class="px-4 py-2 border d-none"
            name="propertyFinanced"
            [id]="propertyFinancedTrueKey"
            [value]="true"
            [(ngModel)]="dataLocal.propertyFinanced"
            (change)="
              updateDataValue(dataLocal.propertyFinanced, 'propertyFinanced')
            "
          />
          <label class="form-check-label" [for]="propertyFinancedTrueKey"
            >Sim</label
          >
        </div>

        <div class="border hideradio">
          <input
            type="radio"
            class="px-4 py-2 border d-none"
            name="propertyFinanced"
            [id]="propertyFinancedFalseKey"
            [value]="false"
            [(ngModel)]="dataLocal.propertyFinanced"
            (change)="
              updateDataValue(dataLocal.propertyFinanced, 'propertyFinanced')
            "
          />
          <label class="form-check-label" [for]="propertyFinancedFalseKey"
            >Não</label
          >
        </div>
      </div>
    </div>

    <div class="mb-3 mt-2 pt-2 border-top">
      <label class="form-label mt-2 fs-6" [for]="notesKey">Anotações</label>
      <input
        [id]="notesKey"
        type="text"
        class="form-control mt-1"
        maxlength="500"
        placeholder="Aqui você pode colocar qualquer anotação pertinente para a seção."
        name="properties.notes"
        [(ngModel)]="dataLocal.notes"
        (change)="updateDataValue(dataLocal.notes, 'notes')"
      />
      <div class="form-text">Máximo 500 caracteres.</div>
    </div>
  </form>
</section>
