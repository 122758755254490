import { createReducer, on } from '@ngrx/store';
import { ISettingJobState } from './setting-job.state';
import { setSettingJobAction } from './setting-job.action';

const initialSettingJobState: ISettingJobState = {
  showFollowUp: true,
  automaticCalculation: true,
  percentageAutomaticCalculation: 3,
};

export const SettingJobReduce = createReducer(
  initialSettingJobState,
  on(setSettingJobAction, (state, data: ISettingJobState) => ({
    ...state,
    showFollowUp: data.showFollowUp,
    automaticCalculation: data.automaticCalculation,
    percentageAutomaticCalculation: data.percentageAutomaticCalculation,
  }))
);
