import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setCreditCardAction } from './store/credit-card.actions';
import { getCreditCardSelectors } from './store/credit-card.selectors';
import { ICreditCardState } from './store/credit-card.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss'],
})
export class CreditCardComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<ICreditCardState>;
  dataState: ICreditCardState;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: ICreditCardState) {
    this.store.dispatch(setCreditCardAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getCreditCardSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.creditCard != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }
}
