import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPropertiesState } from './properties.state';

export const getPropertiesSelectorsState =
  createFeatureSelector<IPropertiesState>('propertiesReduce');

export const getPropertiesSelectors = createSelector(
  getPropertiesSelectorsState,
  (store: IPropertiesState) => {
    return store;
  }
);
