import { Component, OnInit } from '@angular/core';
import { IInsuranceState } from './store/insurance.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { setInsuranceAction } from './store/insurance.actions';
import { getInsuranceSelectors } from './store/insurance.selectors';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss'],
})
export class InsuranceComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IInsuranceState>;
  dataState: IInsuranceState;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IInsuranceState) {
    this.store.dispatch(setInsuranceAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getInsuranceSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.insurance != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }
}
