import { createReducer, on } from '@ngrx/store';

import { IAssetsState } from './assets.state';
import { initAssetsAction, setAssetsAction } from './assets.actions';

export const initialAssetsState: IAssetsState = {
  check: false,
};

export const assetsReduce = createReducer(
  initialAssetsState,
  on(setAssetsAction, (state, { check }) => ({
    ...state,
    check: check,
  }))
);
