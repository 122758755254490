import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-iframe-slide-investiments',
  templateUrl: './iframe-slide-investiments.component.html',
  styleUrls: ['./iframe-slide-investiments.component.scss'],
})
export class IframeSlideInvestimentsComponent implements OnInit {
  @ViewChild('meuIframe') meuIframe: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ativarModoTelaCheia() {
    const iframeElement = this.meuIframe.nativeElement;
    if (iframeElement.requestFullscreen) {
      iframeElement.requestFullscreen();
    } else if (iframeElement.mozRequestFullScreen) {
      iframeElement.mozRequestFullScreen();
    } else if (iframeElement.webkitRequestFullscreen) {
      iframeElement.webkitRequestFullscreen();
    }
  }
}
