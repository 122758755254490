import { createAction, props } from '@ngrx/store';
import { IIncomeTaxState } from './incomeTax.state';

export const initIncomeTaxAction = createAction(
  '[IncomeTax Page] IncomeTax page'
);

export const setIncomeTaxAction = createAction(
  '[IncomeTax Page] set data',
  props<IIncomeTaxState>()
);
