import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IInssState } from './store/inss.state';
import { setInssAction } from './store/inss.actions';
import { getInssSelectors } from './store/inss.selectors';

@Component({
  selector: 'app-inss-retirement',
  templateUrl: './inss-retirement.component.html',
  styleUrls: ['./inss-retirement.component.scss'],
})
export class InssRetirementComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IInssState>;
  dataState: IInssState;
  validateContribute: boolean = false;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IInssState) {
    this.store.dispatch(setInssAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getInssSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.retirement != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
    this.validateContribute = !this.dataState.contribute;
  }

  updateAmount(event: number, field: string): void {
    switch (field) {
      case 'retirement':
        this.dataState.amountRetirement = event;
        break;

      case 'month':
        this.dataState.amountMonth = event;
        break;
    }
    this.setValuesForm();
  }
}
