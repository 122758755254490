<section class="cover-container">
  <div class="cover-header">
    <h1 class="heading-h1">{{ dataStoreSetting.clientName }}</h1>
    <h3 class="heading-h3">
      Relatório do Guia gerado em {{ currentDay }} de {{ currentMonth }} de
      {{ currentYear }}
    </h3>
  </div>
  <div class="cover-img">
    <svg
      width="595"
      height="842"
      viewBox="0 0 595 842"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.04">
        <path
          d="M415.372 377.945L401.295 390.945H355.183L375.184 372.474L313.775 266.766V694.659L179.577 463.692L193.654 450.692H239.766L219.765 469.163L281.174 574.871V147L415.372 377.945Z"
          fill="#0B0C0E"
        />
        <path
          d="M262.697 405.767L130.715 405.767L245.159 349.058L262.697 365.243V322.67L251.095 311.956L1 435.874L262.697 435.874V405.767Z"
          fill="#0B0C0E"
        />
        <path
          d="M594 405.774L343.917 529.68L332.303 518.977V476.394L349.841 492.59L464.272 435.881H332.303V405.774L594 405.774Z"
          fill="#0B0C0E"
        />
        <path
          d="M141.125 221.779L118.073 243.067L164.012 285.491L187.065 264.203L141.125 221.779Z"
          fill="#0B0C0E"
        />
        <path
          d="M453.871 221.765L407.931 264.19L430.984 285.478L476.923 243.054L453.871 221.765Z"
          fill="#0B0C0E"
        />
        <path
          d="M164.012 541.764L118.073 584.188L141.125 605.476L187.065 563.052L164.012 541.764Z"
          fill="#0B0C0E"
        />
        <path
          d="M431.092 541.784L408.04 563.073L453.979 605.497L477.032 584.208L431.092 541.784Z"
          fill="#0B0C0E"
        />
      </g>
      <path
        d="M208.827 413.983L206.548 416.261H199.083L202.321 413.024L192.38 394.494V469.5L170.656 429.013L172.935 426.735H180.4L177.162 429.972L187.103 448.502V373.5L208.827 413.983Z"
        fill="#0C0F27"
      />
      <path
        d="M184.111 418.86H162.746L181.272 408.919L184.111 411.756V404.293L182.233 402.415L141.748 424.137H184.111V418.86Z"
        fill="#0C0F27"
      />
      <path
        d="M237.742 418.861L197.259 440.581L195.379 438.705V431.24L198.218 434.079L216.742 424.138H195.379V418.861H237.742Z"
        fill="#0C0F27"
      />
      <path
        d="M164.431 386.608L160.699 390.34L168.136 397.776L171.868 394.045L164.431 386.608Z"
        fill="#0092DE"
      />
      <path
        d="M215.058 386.606L207.621 394.042L211.353 397.774L218.79 390.337L215.058 386.606Z"
        fill="#0092DE"
      />
      <path
        d="M168.136 442.699L160.699 450.135L164.431 453.867L171.868 446.431L168.136 442.699Z"
        fill="#0092DE"
      />
      <path
        d="M211.371 442.702L207.639 446.434L215.076 453.87L218.808 450.139L211.371 442.702Z"
        fill="#0092DE"
      />
      <path
        d="M302.339 429.288H308.743C306.119 438.119 298.463 444.504 288.643 444.504C276.429 444.504 266.529 434.635 266.529 422.459C266.529 410.282 276.429 400.413 288.643 400.413C297.386 400.413 304.07 405.284 307.735 412.46L301.5 414.255C299.058 409.64 294.43 406.564 288.645 406.564C279.839 406.564 272.703 413.678 272.703 422.457C272.703 431.236 279.839 438.349 288.645 438.349C295.383 438.349 300.215 434.607 302.341 429.286L302.339 429.288ZM309.727 422.459C309.727 423.862 309.606 425.23 309.377 426.561H287.1V420.408L309.598 420.472C309.662 421.05 309.727 421.754 309.727 422.459Z"
        fill="#0C0F27"
      />
      <path
        d="M363.312 400.928V425.024C363.312 436.367 358.169 444.506 346.856 444.506C335.543 444.506 330.4 436.367 330.4 425.024V400.928H336.572V427.075C336.572 433.741 340.364 438.356 346.856 438.356C353.347 438.356 357.142 433.741 357.142 427.075V400.928H363.314H363.312Z"
        fill="#0C0F27"
      />
      <path
        d="M386.29 443.992V400.926H392.462V443.992H386.29Z"
        fill="#0C0F27"
      />
      <path
        d="M453.252 443.992H446.566L442.388 434.762H442.366L432.681 413.486L422.996 434.762H422.974L418.795 443.992H412.109L432.679 399.9L453.25 443.992H453.252Z"
        fill="#0C0F27"
      />
    </svg>
  </div>
  <div>
    <app-footer></app-footer>
  </div>
</section>
