<section>
  <h2 class="heading-h2">Recomendações</h2>
  <div
    class="recomendation-container"
    *ngFor="let item of dataStoreRecomendation.listContacts"
  >
    <div class="recomendation-grid">
      <h3 class="heading-h3">Nome</h3>
      <p>{{ item.name }}</p>
    </div>
    <div class="recomendation-grid">
      <h3 class="heading-h3">E-mail</h3>
      <p>{{ item.email }}</p>
    </div>
    <div class="recomendation-grid">
      <h3 class="heading-h3">Telefone</h3>
      <p>{{ item.phone }}</p>
    </div>
    <div class="recomendation-grid">
      <h3 class="heading-h3">Anotações</h3>
      <p>
        {{ item.obsevation }}
      </p>
    </div>
  </div>
</section>
