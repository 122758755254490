import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IInvestmentsState } from './store/investments.state';
import { Observable } from 'rxjs';
import { setInvestmentsAction } from './store/investments.actions';
import { getInvestmentsSelectors } from './store/investments.selectors';

@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss'],
})
export class InvestmentsComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IInvestmentsState>;
  dataState: IInvestmentsState;

  ngOnInit(): void {
    this.getState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IInvestmentsState) {
    this.store.dispatch(setInvestmentsAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getInvestmentsSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.investments != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }

  updateFeeModel(event: boolean): void {
    this.dataState.feeModel = event;
    this.setValuesForm();
  }

  updateAmount(event): void {
    this.dataState.amount = event;
    this.setValuesForm();
  }
}
