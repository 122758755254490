import { Component, OnInit } from '@angular/core';

import { getHealthInsuranceSelectors } from 'src/app/modules/collect-data/components/health-insurance/store/healthInsurance.selectors';
import { getInsuranceSelectors } from 'src/app/modules/collect-data/components/insurance/store/insurance.selectors';
import { getPensionSelectors } from 'src/app/modules/collect-data/components/pension/store/pension.selectors';

import { IHealthInsuranceState } from 'src/app/modules/collect-data/components/health-insurance/store/healthInsurance.state';
import { IInsuranceState } from 'src/app/modules/collect-data/components/insurance/store/insurance.state';
import { IPensionState } from 'src/app/modules/collect-data/components/pension/store/pension.state';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-protection',
  templateUrl: './pdf-protection.component.html',
  styleUrls: ['./pdf-protection.component.scss'],
})
export class PdfProtectionComponent implements OnInit {
  dataStoreHealthInsurance$: Observable<any>;
  dataStoreHealthInsurance: IHealthInsuranceState;
  healthInsurance: string;
  healthInsuranceInterest: string;

  dataStoreInsurance$: Observable<any>;
  dataStoreInsurance: IInsuranceState;
  insurance: string;
  insuranceInterest: string;

  dataStorePension$: Observable<any>;
  dataStorePension: IPensionState;
  pension: string;
  amoutPensionFormated: string;
  amountPensionMonthFormated: string;
  pensionInterest: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getStateHealthInsurance();
    this.getStateInsurance();
    this.getStatePension();
  }

  getStateHealthInsurance() {
    this.dataStoreHealthInsurance$ = this.store.select(
      getHealthInsuranceSelectors
    );
    this.dataStoreHealthInsurance$.subscribe((value) => {
      this.dataStoreHealthInsurance = {
        ...(this.dataStoreHealthInsurance = value),
      };
    });

    this.healthInsurance = this.dataStoreHealthInsurance.insurance
      ? 'Sim'
      : 'Não';
    this.healthInsuranceInterest = this.dataStoreHealthInsurance.interest
      ? 'Sim, tenho interese'
      : 'Não';
  }

  getStateInsurance() {
    this.dataStoreInsurance$ = this.store.select(getInsuranceSelectors);
    this.dataStoreInsurance$.subscribe((value) => {
      this.dataStoreInsurance = {
        ...(this.dataStoreInsurance = value),
      };
    });
    this.insurance = this.dataStoreInsurance.insurance ? 'Sim' : 'Não';
    this.insuranceInterest = this.dataStoreInsurance.interest ? 'Sim' : 'Não';
  }

  getStatePension() {
    this.dataStorePension$ = this.store.select(getPensionSelectors);
    this.dataStorePension$.subscribe((value) => {
      this.dataStorePension = {
        ...(this.dataStorePension = value),
      };
    });

    this.pension = this.dataStorePension.pension ? 'Sim' : 'Não';
    this.pensionInterest = this.dataStorePension.interest
      ? 'Sim, tenho interesse'
      : 'Não';

    this.amoutPensionFormated = this.dataStorePension.amount
      ? this.formatarMoeda(this.dataStorePension.amount)
      : '0';
    this.amountPensionMonthFormated = this.dataStorePension.notes
      ? this.formatarMoeda(this.dataStorePension.notes)
      : '0';
  }

  formatarMoeda(valor: number): string {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
