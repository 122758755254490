import { Component, OnInit, Input } from '@angular/core';
import { IHealthInsuranceState } from './store/healthInsurance.state';
import { getHealthInsuranceSelectors } from './store/healthInsurance.selectors';
import { setHealthInsuranceAction } from './store/healthInsurance.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IJobFormState } from '../job-form/store/job-form.state';
import { getJobFormSelectors } from '../job-form/store/job-form.selectors';

@Component({
  selector: 'app-health-insurance',
  templateUrl: './health-insurance.component.html',
  styleUrls: ['./health-insurance.component.scss'],
})
export class HealthInsuranceComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IHealthInsuranceState>;
  dataJob$: Observable<IJobFormState>;
  dataJob: IJobFormState;
  dataState: IHealthInsuranceState;
  showPropo = false;

  ngOnInit(): void {
    this.getState();
    this.getJobState();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IHealthInsuranceState) {
    this.store.dispatch(setHealthInsuranceAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getHealthInsuranceSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  getJobState() {
    this.dataJob$ = this.store.select(getJobFormSelectors);

    this.dataJob$.subscribe((value) => {
      this.dataJob = {
        ...(this.dataJob = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = false;
    if (this.dataState.insurance != undefined) {
      this.dataState.check = true;
    }
    this.setState(this.dataState);
  }

  setOption(item: string, option: string, value: boolean) {
    // Criar uma cópia do estado atual
    this.dataState = {
      ...this.dataState,
      [item]: {
        ...this.dataState[item],
        [option]: value,
      },
    };

    // Atualizar o estado usando o método `setState`
    this.setState(this.dataState);
    this.updateStatus();
  }

  updateStatus() {
    if (
      this.dataState.insurance &&
      this.dataJob.typeJob == 'Pessoa Jurídica / Empresário'
    ) {
      this.showPropo = true;
    } else {
      this.showPropo = false;
    }
  }
}
