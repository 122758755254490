import { Component, OnInit } from '@angular/core';
import { ISettingJobState } from './store/setting-job.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { setSettingJobAction } from './store/setting-job.action';
import { getSettingJobSelectors } from './store/setting-job.selector';

@Component({
  selector: 'app-settings-job-offer',
  templateUrl: './settings-job-offer.component.html',
  styleUrls: ['./settings-job-offer.component.scss'],
})
export class SettingsJobOfferComponent implements OnInit {
  dataStoreSettingJob$: Observable<ISettingJobState>;
  dataStoreSettingJob: ISettingJobState;

  showFollowUp!: boolean;
  automaticCalculation!: boolean;
  percentageAutomaticCalculation!: number;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreSettingJob$ = this.store.select(getSettingJobSelectors);
    this.dataStoreSettingJob$.subscribe((value) => {
      this.dataStoreSettingJob = {
        ...(this.dataStoreSettingJob = value),
      };

      this.showFollowUp = this.dataStoreSettingJob.showFollowUp;
      this.automaticCalculation = this.dataStoreSettingJob.automaticCalculation;
      this.percentageAutomaticCalculation =
        this.dataStoreSettingJob.percentageAutomaticCalculation;
    });
  }

  setSettingJob(data: ISettingJobState): void {
    this.store.dispatch(setSettingJobAction(data));
  }

  setShowFollowUp(showFollowUp: boolean): void {
    this.dataStoreSettingJob.showFollowUp = showFollowUp;
    this.setSettingJob(this.dataStoreSettingJob);
  }

  setAutomaticCalculation(automaticCalculation: boolean): void {
    this.dataStoreSettingJob.automaticCalculation = automaticCalculation;
    this.setSettingJob(this.dataStoreSettingJob);

    this.setPercentageAutomaticCalculation(3);
  }

  setPercentageAutomaticCalculation(
    percentageAutomaticCalculation: number
  ): void {
    this.dataStoreSettingJob.percentageAutomaticCalculation = Number(
      percentageAutomaticCalculation
    );
    this.setSettingJob(this.dataStoreSettingJob);
  }
}
