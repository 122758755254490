<section class="mt-3">
  <div>
    <label class="form-label mt-2 fs-6" for="vehicle"
      >Tipo e modelo do veículo</label
    >
    <input
      class="form-control mt-2"
      type="text"
      name="model"
      [id]="modelKey"
      [(ngModel)]="dataLocal.model"
      (ngModelChange)="updateDataValue(dataLocal.model, 'model')"
      placeholder="Modelo"
    />
  </div>

  <div>
    <label class="form-label mt-2 fs-6" for="vehicle-year">Ano de compra</label>

    <input
      class="form-control mt-2"
      type="number"
      name="model"
      [id]="yearOfPurchaseKey"
      [(ngModel)]="dataLocal.yearOfPurchase"
      (ngModelChange)="
        updateDataValue(dataLocal.yearOfPurchase, 'yearOfPurchase')
      "
      placeholder=""
    />
  </div>

  <div>
    <label class="form-label mt-2 fs-6">Valor estimado</label>
    <app-prefixed-input
      [elementID]="estimatedValueKey"
      [pref]="'R$'"
      (parentValue)="updateDataValue($event, 'estimatedValue')"
      [callback]="dataLocal.estimatedValue"
    ></app-prefixed-input>
  </div>
</section>
