import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IJobOfferState } from './store/job-offer.state';
import { Store } from '@ngrx/store';
import { setJobOfferAction } from './store/job-offer.actions';
import { getJobOfferSelectors } from './store/job-offer.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-offer',
  templateUrl: './job-offer.component.html',
  styleUrls: ['./job-offer.component.scss'],
})
export class JobOfferComponent implements OnInit {
  constructor(private store: Store, private router: Router) {}
  panelOpenState = true;
  dataState$: Observable<IJobOfferState>;
  dataState: IJobOfferState;

  selectedTab: string = 'deliveries';

  ngOnInit() {
    this.getState();
  }

  getState() {
    this.dataState$ = this.store.select(getJobOfferSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  toggleTab(tab: string) {
    this.selectedTab = tab;
  }

  goToNextPage(): void {
    if (this.selectedTab === 'investments') {
      this.dataState.check = true;
      this.setState(this.dataState);
      this.router.navigate(['/success']);
    } else {
      this.toggleTab('investments');
    }
  }

  goTolastPage(): void {
    this.dataState.check = true;
    this.setState(this.dataState);
  }

  backToPage(): void {
    this.dataState.check = false;
    this.setState(this.dataState);
  }

  setState(data: IJobOfferState): void {
    this.store.dispatch(setJobOfferAction(data));
  }
}
