import { createReducer, on } from '@ngrx/store';

import { IJobFormState } from './job-form.state';
import { initJobFormAction, setJobFormAction } from './job-form.actions';

export const initialJobFormState: IJobFormState = {
  check: false,
  job: '',
  typeJob: '',
};

export const jobFormReduce = createReducer(
  initialJobFormState,
  on(setJobFormAction, (state, { check, job, typeJob }) => ({
    ...state,
    check: check,
    job: job,
    typeJob: typeJob,
  }))
);
