import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IObjectivesFormState } from '../../../store/objective.state';
import { setObjectiveTripsAction } from '../../../store/objective.actions';
import { getObjectiveTripsSelectors } from '../../../store/objective.selectors';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss'],
})
export class TripsComponent implements OnInit {
  panelOpenState = true;
  dataStoreTrips$: Observable<any>;
  dataStoreTrips: IObjectivesFormState;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreTrips$ = this.store.select(getObjectiveTripsSelectors);

    this.dataStoreTrips$.subscribe((value) => {
      this.dataStoreTrips = {
        ...(this.dataStoreTrips = value),
      };
    });
  }

  unCheck(): void {
    this.dataStoreTrips = {
      ...this.dataStoreTrips,
      isSelected: !this.dataStoreTrips.isSelected,
    };
    this.setState(this.dataStoreTrips);
  }

  setState(data: IObjectivesFormState) {
    this.store.dispatch(setObjectiveTripsAction(data));
  }

  setValuesForm(): void {
    if (isNaN(this.dataStoreTrips.maximumInvestment)) {
      this.dataStoreTrips.maximumInvestment = 0;
    }

    if (isNaN(this.dataStoreTrips.minimumInvestment)) {
      this.dataStoreTrips.minimumInvestment = 0;
    }

    this.dataStoreTrips.check =
      this.dataStoreTrips.description &&
      this.dataStoreTrips.date &&
      this.dataStoreTrips.maximumInvestment
        ? true
        : false;
    this.setState(this.dataStoreTrips);
  }

  updateAmountMin(event): void {
    this.dataStoreTrips.minimumInvestment = event;
    this.setValuesForm();
  }

  updateAmountMax(event): void {
    this.dataStoreTrips.maximumInvestment = event;
    this.setValuesForm();
  }
}
