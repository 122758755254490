import { createAction, props } from '@ngrx/store';
import { IPropertiesState } from './properties.state';

export const initPropertiesAction = createAction(
  '[Properties Page] Properties page'
);

export const setPropertiesAction = createAction(
  '[Properties Page] set data',
  props<IPropertiesState>()
);
