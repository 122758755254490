import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISettingsState } from './settings.state';

export const getSettingsSelectorsState =
  createFeatureSelector<ISettingsState>('settingsReduce');

export const getSettingsSelectors = createSelector(
  getSettingsSelectorsState,
  (store: any) => {
    return store;
  }
);
