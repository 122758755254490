import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './modules/home/home.component';
import { PresentationComponent } from './modules/presentation/presentation.component';
import { ObjectiveComponent } from './modules/objective/objective.component';
import { CollectDataComponent } from './modules/collect-data/collect-data.component';
import { JobOfferComponent } from './modules/job-offer/job-offer.component';
import { FormObjectivesComponent } from './modules/objective/form-objectives/form-objectives.component';
import { EndComponent } from './modules/end/end.component';
import { EndOfCollectionComponent } from './modules/collect-data/components/end-of-collection/end-of-collection.component';
import { PdfReportComponent } from './modules/pdf-report/pdf-report.component';
import { HeaderComponent } from './modules/pdf-report/components/header/header.component';
import { FooterComponent } from './modules/pdf-report/components/footer/footer.component';
import { SettingsComponent } from './modules/settings/settings.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'presentation', component: PresentationComponent },
  { path: 'objective', component: ObjectiveComponent },
  { path: 'objective-forms', component: FormObjectivesComponent },
  { path: 'collect-data', component: CollectDataComponent },
  { path: 'job-offer', component: JobOfferComponent },
  { path: 'success', component: EndComponent },
  { path: 'recomendation', component: EndOfCollectionComponent },
  { path: 'report', component: PdfReportComponent },
  { path: 'report-header', component: HeaderComponent },
  { path: 'report-footer', component: FooterComponent },
  { path: 'settings', component: SettingsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
