import { createReducer, on } from '@ngrx/store';
import { initInsuranceAction, setInsuranceAction } from './insurance.actions';
import { IInsuranceState } from './insurance.state';

export const initialInsuranceState: IInsuranceState = {
  check: false,
  insurance: undefined,
  insuranceName: '',
  interest: undefined,
  safeWithInsurance: '',
};

export const insuranceReduce = createReducer(
  initialInsuranceState,
  on(
    setInsuranceAction,
    (
      state,
      { check, insurance, insuranceName, interest, safeWithInsurance }
    ) => ({
      ...state,
      check: check,
      insurance: insurance,
      insuranceName: insuranceName,
      interest: interest,
      safeWithInsurance: safeWithInsurance,
    })
  )
);
