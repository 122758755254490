import { Component, OnInit, AfterViewInit, EventEmitter } from '@angular/core';

import { getSettingSelectors } from 'src/app/modules/settings/settings-general/store/setting.selector';
import { ISettingState } from 'src/app/modules/settings/settings-general/store/setting.state';

import { getPropertiesSelectors } from 'src/app/modules/collect-data/components/properties/store/properties.selectors';
import { getVehiclesSelectors } from 'src/app/modules/collect-data/components/vehicles/store/vehicles.selectors';
import { getInvestmentsSelectors } from 'src/app/modules/collect-data/components/investments/store/investments.selectors';

import { IPropertiesState } from 'src/app/modules/collect-data/components/properties/store/properties.state';
import { IVehiclesState } from 'src/app/modules/collect-data/components/vehicles/store/vehicles.state';
import { IInvestmentsState } from 'src/app/modules/collect-data/components/investments/store/investments.state';

import {
  getObjectiveEntrepreneurshipSelectors,
  getObjectiveMarriageSelectors,
  getObjectiveOthersSelectors,
  getObjectivePropertySelectors,
  getObjectiveRetirementSelectors,
  getObjectiveTripsSelectors,
  getObjectiveVehiclesSelectors,
} from '../objective/store/objective.selectors';

import {
  IObjectivesFormState,
  IObjectivesRetirementFormState,
} from '../objective/store/objective.state';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-report',
  templateUrl: './pdf-report.component.html',
  styleUrls: ['./pdf-report.component.scss'],
})
export class PdfReportComponent implements OnInit, AfterViewInit {
  emitter: EventEmitter<void> = new EventEmitter();

  currentDay: Number;
  currentMonth: String;
  currentYear: Number;
  nameMonth: string[] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  amount: number = 0;
  amountFormated: string;

  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingState;

  dataStoreProperty$: Observable<any>;
  dataStoreProperty: IPropertiesState;

  dataStoreVehicles$: Observable<any>;
  dataStoreVehicles: IVehiclesState;

  dataStoreInvestiments$: Observable<any>;
  dataStoreInvestiments: IInvestmentsState;

  isSelectVehicles: IObjectivesFormState;
  isSelectTrips: IObjectivesFormState;
  isSelectMarriage: IObjectivesFormState;
  isSelectRetirement: IObjectivesRetirementFormState;
  isSelectProperty: IObjectivesFormState;
  isSelectOthers: IObjectivesFormState;
  isSelectEntrepreneurship: IObjectivesFormState;

  isSelectVehicles$: Observable<any>;
  isSelectTrips$: Observable<any>;
  isSelectMarriage$: Observable<any>;
  isSelectRetirement$: Observable<any>;
  isSelectProperty$: Observable<any>;
  isSelectOthers$: Observable<any>;
  isSelectEntrepreneurship$: Observable<any>;

  constructor(private store: Store) {
    this.currentDay = new Date().getDate();
    this.currentMonth = this.nameMonth[new Date().getMonth()];
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit(): void {
    this.getStateSetting();
    this.getStateProperty();
    this.getStateVehicles();
    this.getStateInvestiments();
    this.amountSum();

    this.getStateObEntrepreneurship();
    this.getStateObMarriage();
    this.getStateObOthers();
    this.getStateObProperty();
    this.getStateObRetirement();
    this.getStateObTrips();
    this.getStateObVehicles();
  }

  ngAfterViewInit(): void {
    this.emitter.emit();
  }

  getStateSetting() {
    this.dataStoreSetting$ = this.store.select(getSettingSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };
    });
  }

  getStateProperty() {
    this.dataStoreProperty$ = this.store.select(getPropertiesSelectors);
    this.dataStoreProperty$.subscribe((value) => {
      this.dataStoreProperty = {
        ...(this.dataStoreProperty = value),
      };
    });
  }

  getStateVehicles() {
    this.dataStoreVehicles$ = this.store.select(getVehiclesSelectors);
    this.dataStoreVehicles$.subscribe((value) => {
      this.dataStoreVehicles = {
        ...(this.dataStoreVehicles = value),
      };
    });
  }

  getStateInvestiments() {
    this.dataStoreInvestiments$ = this.store.select(getInvestmentsSelectors);
    this.dataStoreInvestiments$.subscribe((value) => {
      this.dataStoreInvestiments = {
        ...(this.dataStoreInvestiments = value),
      };
    });
  }

  // OBJETIVOS SELECIONADOS //
  getStateObVehicles() {
    this.isSelectVehicles$ = this.store.select(getObjectiveVehiclesSelectors);
    this.isSelectVehicles$.subscribe((value) => {
      this.isSelectVehicles = {
        ...(this.isSelectVehicles = value),
      };
    });
  }

  getStateObTrips() {
    this.isSelectTrips$ = this.store.select(getObjectiveTripsSelectors);
    this.isSelectTrips$.subscribe((value) => {
      this.isSelectTrips = {
        ...(this.isSelectTrips = value),
      };
    });
  }

  getStateObMarriage() {
    this.isSelectMarriage$ = this.store.select(getObjectiveMarriageSelectors);
    this.isSelectMarriage$.subscribe((value) => {
      this.isSelectMarriage = {
        ...(this.isSelectMarriage = value),
      };
    });
  }

  getStateObProperty() {
    this.isSelectProperty$ = this.store.select(getObjectivePropertySelectors);
    this.isSelectProperty$.subscribe((value) => {
      this.isSelectProperty = {
        ...(this.isSelectProperty = value),
      };
    });
  }

  getStateObOthers() {
    this.isSelectOthers$ = this.store.select(getObjectiveOthersSelectors);
    this.isSelectOthers$.subscribe((value) => {
      this.isSelectOthers = {
        ...(this.isSelectOthers = value),
      };
    });
  }

  getStateObEntrepreneurship() {
    this.isSelectEntrepreneurship$ = this.store.select(
      getObjectiveEntrepreneurshipSelectors
    );
    this.isSelectEntrepreneurship$.subscribe((value) => {
      this.isSelectEntrepreneurship = {
        ...(this.isSelectEntrepreneurship = value),
      };
    });
  }

  getStateObRetirement() {
    this.isSelectRetirement$ = this.store.select(
      getObjectiveRetirementSelectors
    );
    this.isSelectRetirement$.subscribe((value) => {
      this.isSelectRetirement = {
        ...(this.isSelectRetirement = value),
      };
    });
  }

  calcVehicles(): number {
    let amount = 0;

    for (const item of this.dataStoreVehicles.listVehicles) {
      amount += item.estimatedValue;
    }

    return amount;
  }

  calProperties(): number {
    let amount = 0;

    for (const item of this.dataStoreProperty.listProperties) {
      amount += item.estimatedValue;
    }

    return amount;
  }

  amountSum() {
    let amountVehicles = isNaN(this.calcVehicles()) ? 0 : this.calcVehicles();
    let amountProperties = isNaN(this.calProperties())
      ? 0
      : this.calProperties();
    let amountInvestments = isNaN(this.dataStoreInvestiments.amount)
      ? 0
      : this.dataStoreInvestiments.amount;

    this.amount = amountProperties + amountVehicles + amountInvestments;

    this.amount = isNaN(this.amount) ? 0 : this.amount;

    this.amountFormated = this.formatarMoeda(this.amount);
  }

  formatarMoeda(valor: number): string {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
