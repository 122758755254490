import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHealthInsuranceState } from './healthInsurance.state';

export const getHealthInsuranceSelectorsState =
  createFeatureSelector<IHealthInsuranceState>('healthInsuranceReduce');

export const getHealthInsuranceSelectors = createSelector(
  getHealthInsuranceSelectorsState,
  (store: IHealthInsuranceState) => {
    return store;
  }
);
