import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IContactState } from './contact.state';

export const getContactSelectorsState =
  createFeatureSelector<IContactState>('ContactReduce');

export const getContactSelectors = createSelector(
  getContactSelectorsState,
  (store: IContactState) => {
    return store;
  }
);
