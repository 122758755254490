import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getBudgetSelectors } from 'src/app/modules/collect-data/components/budget/store/budget.selectors';
import { IBudgetState } from 'src/app/modules/collect-data/components/budget/store/budget.state';
import { setJobOfferAction } from 'src/app/modules/job-offer/store/job-offer.actions';
import { getJobOfferSelectors } from 'src/app/modules/job-offer/store/job-offer.selectors';
import { IJobOfferState } from 'src/app/modules/job-offer/store/job-offer.state';
import { getSettingJobSelectors } from 'src/app/modules/settings/settings-job-offer/store/setting-job.selector';
import { ISettingJobState } from 'src/app/modules/settings/settings-job-offer/store/setting-job.state';
import { getSettingPlansSelectors } from 'src/app/modules/settings/settings-plans/store/setting-plans.selector';

@Component({
  selector: 'app-consulting-project',
  templateUrl: './consulting-project.component.html',
  styleUrls: ['./consulting-project.component.scss'],
})
export class ConsultingProjectComponent implements OnInit {
  dataJobOfferState$: Observable<IJobOfferState>;
  dataJobOfferState: IJobOfferState;

  dataStoreSettingJob$: Observable<ISettingJobState>;
  dataStoreSettingJob: ISettingJobState;

  dataBudgetState$: Observable<IBudgetState>;
  dataBudgetState: IBudgetState;

  dataSettingPlansState$: Observable<any>;
  dataSettingPlansState: any;

  creditCardValueFormated!: string;
  creditCardValueWithDiscount!: string;

  inCashValueFormated!: string;
  inCashValueWithDiscount!: string;

  ticketApplied: boolean = false;
  ticketEnabled: boolean = false;

  discountLabelEnable: boolean = false;

  inputValue: string = '';
  ticketValue!: number;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getJobOfferState();
    this.getSettingsPlanstates();
    this.getStateSettingsJob();
    this.getBudgetState();
    this.setInitialValues();
  }

  setInitialValues() {
    if (this.dataJobOfferState.ticketValue != 0) {
      this.inputValue = String(this.dataJobOfferState.ticketValue);
      this.ticketApplied = true;
      this.ticketEnabled = true;
    }
  }

  setJobOfferStates(data: IJobOfferState) {
    this.store.dispatch(setJobOfferAction(data));
  }

  getJobOfferState() {
    this.dataJobOfferState$ = this.store.select(getJobOfferSelectors);
    this.dataJobOfferState$.subscribe((value) => {
      this.dataJobOfferState = {
        ...(this.dataJobOfferState = value),
      };
    });
  }

  getBudgetState() {
    this.dataBudgetState$ = this.store.select(getBudgetSelectors);
    this.dataBudgetState$.subscribe((value) => {
      this.dataBudgetState = {
        ...(this.dataBudgetState = value),
      };
    });

    this.applyAutomaticCalculation();
  }

  getStateSettingsJob(): void {
    this.dataStoreSettingJob$ = this.store.select(getSettingJobSelectors);
    this.dataStoreSettingJob$.subscribe((value) => {
      this.dataStoreSettingJob = {
        ...(this.dataStoreSettingJob = value),
      };
    });
  }

  getSettingsPlanstates() {
    this.dataSettingPlansState$ = this.store.select(getSettingPlansSelectors);
    this.dataSettingPlansState$.subscribe((value) => {
      this.dataSettingPlansState = {
        ...(this.dataSettingPlansState = value),
      };
    });

    if (this.dataSettingPlansState.automaticSelected) {
      this.discountLabelEnable = true;
    }
  }

  applyAutomaticCalculation() {
    const setValues = (creditCardValue, inCashValue) => {
      this.dataJobOfferState.creditCardValue =
        this.convertValue(creditCardValue);
      this.dataJobOfferState.inCashValue = this.convertValue(inCashValue);
      this.dataJobOfferState.creditCardValueWithDiscount =
        this.convertValue(creditCardValue);
      this.dataJobOfferState.inCashValueWithDiscount =
        this.convertValue(inCashValue);
    };

    const setAutomaticCalculationValues = (value) => {
      if (value > 3000) {
        const valueWith10Percent = value * 0.9;
        setValues(value, valueWith10Percent);
      } else {
        setValues(3000, 2700);
      }
    };

    const setManualCalculationValues = () => {
      setValues(
        this.dataSettingPlansState.valuePlanOne,
        this.dataSettingPlansState.valuePlanTwo
      );
    };

    if (this.dataStoreSettingJob.automaticCalculation) {
      if (this.dataJobOfferState.consultancyProject === '') {
        const value =
          this.dataBudgetState.annualRecipe *
          (this.dataStoreSettingJob.percentageAutomaticCalculation / 100);
        setAutomaticCalculationValues(value);
      }
    } else {
      if (this.dataJobOfferState.consultancyProject === '') {
        setManualCalculationValues();
      }
    }

    this.setJobOfferStates(this.dataJobOfferState);
  }

  convertValue(value: number) {
    let response = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value));

    return response;
  }

  convertFormattedValueToNumber(formattedValue: string): number {
    let cleanedValue = formattedValue.replace(/[^\d,-]/g, '');

    cleanedValue = cleanedValue.replace(',', '.');

    return parseFloat(cleanedValue);
  }

  onInputChange() {
    this.dataJobOfferState.consultancyProject = '';
    this.dataJobOfferState.consultancyProject = '';
    this.dataJobOfferState.creditCardValueWithDiscount =
      this.dataJobOfferState.creditCardValue;
    this.dataJobOfferState.inCashValueWithDiscount =
      this.dataJobOfferState.inCashValue;
    this.ticketApplied = false;
  }

  enableTicket() {
    this.ticketEnabled = !this.ticketEnabled;
    this.ticketApplied = false;
    this.inputValue = '';
    this.dataJobOfferState.ticketValue = this.inputValue;
    this.dataJobOfferState.consultancyProject = '';
    this.dataJobOfferState.creditCardValueWithDiscount =
      this.dataJobOfferState.creditCardValue;
    this.dataJobOfferState.inCashValueWithDiscount =
      this.dataJobOfferState.inCashValue;
    this.setJobOfferStates(this.dataJobOfferState);
  }

  applyTicket() {
    if (this.inputValue) {
      let numericValue = this.inputValue.replace(/[^0-9]/g, '');
      this.ticketValue = Number(numericValue);

      if (this.ticketValue > 100) {
        this.ticketValue = 100;
        this.inputValue = '100';
      }

      if (this.ticketValue < 0) {
        this.ticketValue = 0;
        this.inputValue = '0';
      }

      const valueInPercent = this.ticketValue / 100;

      let creditCardValue = this.convertFormattedValueToNumber(
        String(this.dataJobOfferState.creditCardValue)
      );

      let valueCreditWithDiscount =
        creditCardValue - creditCardValue * valueInPercent;
      if (valueCreditWithDiscount > 3000) {
        this.dataJobOfferState.creditCardValueWithDiscount = this.convertValue(
          valueCreditWithDiscount
        );
      } else {
        this.dataJobOfferState.creditCardValueWithDiscount =
          this.convertValue(3000);
      }

      let inCashValue = this.convertFormattedValueToNumber(
        String(this.dataJobOfferState.inCashValue)
      );

      let valueInCashWithDiscount = inCashValue - inCashValue * valueInPercent;

      if (valueInCashWithDiscount > 3000) {
        this.dataJobOfferState.inCashValueWithDiscount = this.convertValue(
          valueInCashWithDiscount
        );
      } else {
        this.dataJobOfferState.inCashValueWithDiscount =
          this.convertValue(2700);
      }

      this.dataJobOfferState.ticketValue = this.ticketValue + '%';
      this.setJobOfferStates(this.dataJobOfferState);

      this.ticketApplied = !this.ticketApplied;
    }
  }

  validateInput(event: KeyboardEvent) {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '%'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  selectCreditCard() {
    if (this.dataJobOfferState.consultancyProject == 'Credit Card') {
      this.dataJobOfferState.consultancyProject = '';
    } else {
      this.dataJobOfferState.consultancyProject = 'Credit Card';

      this.dataJobOfferState.amount = this.dataJobOfferState.creditCardValue;
      this.dataJobOfferState.discountedPrice =
        this.dataJobOfferState.creditCardValueWithDiscount;

      this.setJobOfferStates(this.dataJobOfferState);
    }
  }

  selectInCash() {
    if (this.dataJobOfferState.consultancyProject == 'In Cash') {
      this.dataJobOfferState.consultancyProject = '';
    } else {
      this.dataJobOfferState.consultancyProject = 'In Cash';

      this.dataJobOfferState.amount = this.dataJobOfferState.inCashValue;
      this.dataJobOfferState.discountedPrice =
        this.dataJobOfferState.inCashValueWithDiscount;

      this.setJobOfferStates(this.dataJobOfferState);
    }
  }
}
