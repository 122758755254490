import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IObjectivesFormState } from '../../../store/objective.state';
import { setObjectiveMarriageAction } from '../../../store/objective.actions';
import { getObjectiveMarriageSelectors } from '../../../store/objective.selectors';

@Component({
  selector: 'app-marriage',
  templateUrl: './marriage.component.html',
  styleUrls: ['./marriage.component.scss'],
})
export class MarriageComponent implements OnInit {
  panelOpenState = true;
  dataStoreMarriage$: Observable<any>;
  dataStoreMarriage: IObjectivesFormState;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreMarriage$ = this.store.select(getObjectiveMarriageSelectors);
    this.dataStoreMarriage$.subscribe((value) => {
      this.dataStoreMarriage = {
        ...(this.dataStoreMarriage = value),
      };
    });
  }

  unCheck(): void {
    this.dataStoreMarriage = {
      ...this.dataStoreMarriage,
      isSelected: !this.dataStoreMarriage.isSelected,
    };
    this.setState(this.dataStoreMarriage);
  }

  setState(data: IObjectivesFormState) {
    this.store.dispatch(setObjectiveMarriageAction(data));
  }

  setValuesForm(): void {
    if (isNaN(this.dataStoreMarriage.maximumInvestment)) {
      this.dataStoreMarriage.maximumInvestment = 0;
    }

    if (isNaN(this.dataStoreMarriage.minimumInvestment)) {
      this.dataStoreMarriage.minimumInvestment = 0;
    }

    this.dataStoreMarriage.check =
      this.dataStoreMarriage.description &&
      this.dataStoreMarriage.date &&
      this.dataStoreMarriage.maximumInvestment
        ? true
        : false;
    this.setState(this.dataStoreMarriage);
  }

  updateAmountMin(event): void {
    this.dataStoreMarriage.minimumInvestment = event;
    this.setValuesForm();
  }

  updateAmountMax(event): void {
    this.dataStoreMarriage.maximumInvestment = event;
    this.setValuesForm();
  }
}
