<div class="objectives-content__vehicles">
  <div class="title-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15 7H14.325L12.5887 3.09375C12.5102 2.91702 12.382 2.76686 12.2199 2.66148C12.0577 2.55609 11.8684 2.5 11.675 2.5H4.325C4.13159 2.5 3.94233 2.55609 3.78014 2.66148C3.61796 2.76686 3.48982 2.91702 3.41125 3.09375L1.675 7H1C0.867392 7 0.740215 7.05268 0.646447 7.14645C0.552678 7.24021 0.5 7.36739 0.5 7.5C0.5 7.63261 0.552678 7.75979 0.646447 7.85355C0.740215 7.94732 0.867392 8 1 8H1.5V13C1.5 13.2652 1.60536 13.5196 1.79289 13.7071C1.98043 13.8946 2.23478 14 2.5 14H4C4.26522 14 4.51957 13.8946 4.70711 13.7071C4.89464 13.5196 5 13.2652 5 13V12H11V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14H13.5C13.7652 14 14.0196 13.8946 14.2071 13.7071C14.3946 13.5196 14.5 13.2652 14.5 13V8H15C15.1326 8 15.2598 7.94732 15.3536 7.85355C15.4473 7.75979 15.5 7.63261 15.5 7.5C15.5 7.36739 15.4473 7.24021 15.3536 7.14645C15.2598 7.05268 15.1326 7 15 7ZM4.325 3.5H11.675L13.2306 7H2.76938L4.325 3.5ZM4 13H2.5V12H4V13ZM12 13V12H13.5V13H12ZM13.5 11H2.5V8H13.5V11ZM3.5 9.5C3.5 9.36739 3.55268 9.24021 3.64645 9.14645C3.74021 9.05268 3.86739 9 4 9H5C5.13261 9 5.25979 9.05268 5.35355 9.14645C5.44732 9.24021 5.5 9.36739 5.5 9.5C5.5 9.63261 5.44732 9.75979 5.35355 9.85355C5.25979 9.94732 5.13261 10 5 10H4C3.86739 10 3.74021 9.94732 3.64645 9.85355C3.55268 9.75979 3.5 9.63261 3.5 9.5ZM10.5 9.5C10.5 9.36739 10.5527 9.24021 10.6464 9.14645C10.7402 9.05268 10.8674 9 11 9H12C12.1326 9 12.2598 9.05268 12.3536 9.14645C12.4473 9.24021 12.5 9.36739 12.5 9.5C12.5 9.63261 12.4473 9.75979 12.3536 9.85355C12.2598 9.94732 12.1326 10 12 10H11C10.8674 10 10.7402 9.94732 10.6464 9.85355C10.5527 9.75979 10.5 9.63261 10.5 9.5Z"
        fill="#C72F41"
      />
    </svg>
    <h2 class="heading-h2">Veículos</h2>
  </div>

  <div class="vehicles-reason">
    <h3 class="heading-h3">Por que escolheu esse objetivo?</h3>
    <p>
      {{ dataStoreVehicles.description }}
    </p>

    <h3 class="heading-h3">Quando deseja realizar esse objetivo?</h3>
    <p>{{ intendDate }}</p>
  </div>

  <div class="vehicles-reason">
    <h3 class="heading-h3">Quanto pretende investir nesse objetivo?</h3>
    <div class="pretend-invest-container">
      <div class="invest__min">
        <h4 class="heading-h4">Minimo</h4>
        <p>{{ minInvestimentFormated }} /mês</p>
      </div>
      <div class="invest__max">
        <h4 class="heading-h4">Maximo</h4>
        <p>{{ maxInvestimentFormated }} /mês</p>
      </div>
    </div>
    <div>
      <h3 class="heading-h3">Observações</h3>
      <p>
        {{ dataStoreVehicles.observation }}
      </p>
    </div>
  </div>
</div>
