import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IOnboardingState } from './home.state';

export const getOnboardingSelectorsState =
  createFeatureSelector<IOnboardingState>('onboardingReduce');

export const getOnboardingSelectors = createSelector(
  getOnboardingSelectorsState,
  (store: any) => {
    return store;
  }
);
