import { createAction, props } from '@ngrx/store';
import {
  IObjectiveState,
  IObjectivesFormState,
  IObjectivesRetirementFormState,
} from './objective.state';

export const initObjectiveAction = createAction(
  '[Objective Page] Objective page'
);

export const setObjectiveAction = createAction(
  '[Objective Page] set data',
  props<IObjectiveState>()
);

// Retirement
export const initObjectiveRetirementAction = createAction(
  '[Retirement Page] Retirement page'
);
export const setObjectiveRetirementAction = createAction(
  '[Retirement Page] set data',
  props<IObjectivesRetirementFormState>()
);

// Trips
export const initObjectiveTripsAction = createAction('[Trips Page] Trips page');
export const setObjectiveTripsAction = createAction(
  '[Trips Page] set data',
  props<IObjectivesFormState>()
);

//Property
export const initObjectivePropertyAction = createAction(
  '[Property Page] Property page'
);
export const setObjectivePropertyAction = createAction(
  '[Property Page] set data',
  props<IObjectivesFormState>()
);

//Vehicles
export const initObjectiveVehiclesAction = createAction(
  '[Vehicles Page] Vehicles page'
);
export const setObjectiveVehiclesAction = createAction(
  '[Vehicles Page] set data',
  props<IObjectivesFormState>()
);

//Entrepreneurship
export const initObjectiveEntrepreneurshipAction = createAction(
  '[Entrepreneurship Page] Entrepreneurship page'
);
export const setObjectiveEntrepreneurshipAction = createAction(
  '[Entrepreneurship Page] set data',
  props<IObjectivesFormState>()
);

//Marriage
export const initObjectiveMarriageAction = createAction(
  '[Marriage Page] Marriage page'
);
export const setObjectiveMarriageAction = createAction(
  '[Marriage Page] set data',
  props<IObjectivesFormState>()
);

//Others
export const initObjectiveOthersAction = createAction(
  '[Others Page] Others page'
);
export const setObjectiveOthersAction = createAction(
  '[Others Page] set data',
  props<IObjectivesFormState>()
);
