import { Component, OnInit } from '@angular/core';

import { getPropertiesSelectors } from 'src/app/modules/collect-data/components/properties/store/properties.selectors';
import { getVehiclesSelectors } from 'src/app/modules/collect-data/components/vehicles/store/vehicles.selectors';
import { getInssSelectors } from 'src/app/modules/collect-data/components/inss-retirement/store/inss.selectors';
import { getInvestmentsSelectors } from 'src/app/modules/collect-data/components/investments/store/investments.selectors';
import { getConsultingSelectors } from 'src/app/modules/collect-data/components/investments/component/consulting-model/store/consulting.selectors';

import { IPropertiesState } from 'src/app/modules/collect-data/components/properties/store/properties.state';
import { IVehiclesState } from 'src/app/modules/collect-data/components/vehicles/store/vehicles.state';
import { IInssState } from 'src/app/modules/collect-data/components/inss-retirement/store/inss.state';
import { IInvestmentsState } from 'src/app/modules/collect-data/components/investments/store/investments.state';
import { IConsultingState } from 'src/app/modules/collect-data/components/investments/component/consulting-model/store/consulting.state';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-pdf-patrimony',
  templateUrl: './pdf-patrimony.component.html',
  styleUrls: ['./pdf-patrimony.component.scss'],
})
export class PdfPatrimonyComponent implements OnInit {
  amount: number;
  amountFormated: string;

  dataStoreProperty$: Observable<any>;
  dataStoreProperty: IPropertiesState;
  property: string;
  propertyFinanced: string;
  propertyInterest: string;
  amountPropertyFormated: string;

  dataStoreVehicles$: Observable<any>;
  dataStoreVehicles: IVehiclesState;
  vehicles: string;

  dataStoreInss$: Observable<any>;
  dataStoreInss: IInssState;
  inss: string;
  inssRetirement: string;
  amountInssMonthFormated: string;
  amountRetirementFormated: string;

  dataStoreInvestiments$: Observable<any>;
  dataStoreInvestiments: IInvestmentsState;
  dataStoreConsulting$: Observable<any>;
  dataStoreConsulting: IConsultingState;
  investiments: string;
  accessorPayment: string;
  investimentsInterest: string;
  amountCurrentPortfolioFormated: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getStateProperty();
    this.getStateVehicles();
    this.getStateInss();
    this.getStateInvestiments();
    this.getStateConsulting();
    this.amountSum();
  }

  getStateProperty() {
    this.dataStoreProperty$ = this.store.select(getPropertiesSelectors);
    this.dataStoreProperty$.subscribe((value) => {
      this.dataStoreProperty = {
        ...(this.dataStoreProperty = value),
      };
    });

    this.property = this.dataStoreProperty.properties ? 'Sim' : 'Não';
    this.propertyInterest = this.dataStoreProperty.interest
      ? 'Sim, tenho interesse'
      : 'Não';
  }

  getStateVehicles() {
    this.dataStoreVehicles$ = this.store.select(getVehiclesSelectors);
    this.dataStoreVehicles$.subscribe((value) => {
      this.dataStoreVehicles = {
        ...(this.dataStoreVehicles = value),
      };
    });

    this.vehicles = this.dataStoreVehicles.vehicles ? 'Sim' : 'Não';
  }

  getStateInss() {
    this.dataStoreInss$ = this.store.select(getInssSelectors);
    this.dataStoreInss$.subscribe((value) => {
      this.dataStoreInss = {
        ...(this.dataStoreInss = value),
      };
    });

    this.inss = this.dataStoreInss.contribute ? 'Sim' : 'Não';
    this.inssRetirement = this.dataStoreInss.retirement ? 'Sim' : 'Não';

    this.amountInssMonthFormated = this.dataStoreInss.amountMonth
      ? this.formatarMoeda(this.dataStoreInss.amountMonth)
      : '0';
    this.amountRetirementFormated = this.dataStoreInss.amountRetirement
      ? this.formatarMoeda(this.dataStoreInss.amountRetirement)
      : '0';
  }

  getStateInvestiments() {
    this.dataStoreInvestiments$ = this.store.select(getInvestmentsSelectors);
    this.dataStoreInvestiments$.subscribe((value) => {
      this.dataStoreInvestiments = {
        ...(this.dataStoreInvestiments = value),
      };
    });

    this.investiments = this.dataStoreInvestiments.investments ? 'Sim' : 'Não';
    this.accessorPayment = this.dataStoreInvestiments.accessorPayment
      ? 'Sim'
      : 'Não';

    this.amountCurrentPortfolioFormated = this.dataStoreInvestiments.amount
      ? this.formatarMoeda(this.dataStoreInvestiments.amount)
      : '0';
  }

  getStateConsulting() {
    this.dataStoreConsulting$ = this.store.select(getConsultingSelectors);
    this.dataStoreConsulting$.subscribe((value) => {
      this.dataStoreConsulting = {
        ...(this.dataStoreConsulting = value),
      };
    });

    this.investimentsInterest = this.dataStoreConsulting.interest
      ? 'Sim, gostaria de conhecer'
      : 'Não';
  }

  calcVehicles(): number {
    let amount = 0;

    for (const item of this.dataStoreVehicles.listVehicles) {
      amount += item.estimatedValue;
    }

    return amount;
  }

  calProperties(): number {
    let amount = 0;

    for (const item of this.dataStoreProperty.listProperties) {
      amount += item.estimatedValue;
    }

    return amount;
  }

  amountSum() {
    let amountVehicles = isNaN(this.calcVehicles()) ? 0 : this.calcVehicles();
    let amountProperties = isNaN(this.calProperties())
      ? 0
      : this.calProperties();
    let amountInvestments = isNaN(this.dataStoreInvestiments.amount)
      ? 0
      : this.dataStoreInvestiments.amount;

    this.amount = amountProperties + amountVehicles + amountInvestments;

    this.amount = isNaN(this.amount) ? 0 : this.amount;

    this.amountFormated = this.formatarMoeda(this.amount);
  }

  formatarMoeda(valor: number): string {
    return valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}
