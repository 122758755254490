import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-prefixed-input',
  templateUrl: './prefixed-input.component.html',
  styleUrls: ['./prefixed-input.component.scss'],
})
export class PrefixedInputComponent implements OnInit {
  @Input() elementID: string;
  @Input() pref: string;
  @Input() callback: string;

  @Output() parentValue = new EventEmitter<number>();

  valueInput: string;
  amount: any;

  constructor() {}

  ngOnInit(): void {
    this.valueInput = this.callback;
  }

  formatarMoeda(valor: number): string {
    const formatoMoeda = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return formatoMoeda.format(valor);
  }

  rewrite() {
    let currValue = this.valueInput.replace(/\D/g, '');
    this.amount = parseFloat(currValue);

    if (this.pref == '%') {
      currValue = this.valueInput;
      this.amount = currValue.replace(',', '.');
    }

    this.toParent();

    if (this.pref != '%') {
      this.valueInput = this.amount.toLocaleString('pt-BR');
    }
  }

  toParent() {
    this.amount = isNaN(this.amount) ? 0 : this.amount;
    this.parentValue.emit(this.amount);
  }

  focusElement(id: string): void {
    const x = document.getElementById(id);
    x.focus();
  }
}
