import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  IObjectiveState,
  IObjectivesRetirementFormState,
} from './objective.state';

export const getObjectiveSelectorsState =
  createFeatureSelector<IObjectiveState>('objectiveReduce');

export const getObjectiveSelectors = createSelector(
  getObjectiveSelectorsState,
  (store: any) => {
    return store;
  }
);

// Retirement
export const getObjectiveRetirementSelectorsState =
  createFeatureSelector<IObjectivesRetirementFormState>(
    'objectiveRetirementReduce'
  );

export const getObjectiveRetirementSelectors = createSelector(
  getObjectiveRetirementSelectorsState,
  (store: any) => {
    return store;
  }
);

// Trips
export const getObjectiveTripsSelectorsState =
  createFeatureSelector<IObjectiveState>('objectiveTripsReduce');

export const getObjectiveTripsSelectors = createSelector(
  getObjectiveTripsSelectorsState,
  (store: any) => {
    return store;
  }
);

// Property
export const getObjectivePropertySelectorsState =
  createFeatureSelector<IObjectiveState>('objectivePropertyReduce');

export const getObjectivePropertySelectors = createSelector(
  getObjectivePropertySelectorsState,
  (store: any) => {
    return store;
  }
);

// Vehicles
export const getObjectiveVehiclesSelectorsState =
  createFeatureSelector<IObjectiveState>('objectiveVehiclesReduce');

export const getObjectiveVehiclesSelectors = createSelector(
  getObjectiveVehiclesSelectorsState,
  (store: any) => {
    return store;
  }
);

// Entrepreneurship
export const getObjectiveEntrepreneurshipSelectorsState =
  createFeatureSelector<IObjectiveState>('objectiveEntrepreneurshipReduce');

export const getObjectiveEntrepreneurshipSelectors = createSelector(
  getObjectiveEntrepreneurshipSelectorsState,
  (store: any) => {
    return store;
  }
);

// Marriage
export const getObjectiveMarriageSelectorsState =
  createFeatureSelector<IObjectiveState>('objectiveMarriageReduce');

export const getObjectiveMarriageSelectors = createSelector(
  getObjectiveMarriageSelectorsState,
  (store: any) => {
    return store;
  }
);

// Others
export const getObjectiveOthersSelectorsState =
  createFeatureSelector<IObjectiveState>('objectiveOthersReduce');

export const getObjectiveOthersSelectors = createSelector(
  getObjectiveOthersSelectorsState,
  (store: any) => {
    return store;
  }
);

//
