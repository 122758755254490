import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setJobOfferAction } from 'src/app/modules/job-offer/store/job-offer.actions';
import { getJobOfferSelectors } from 'src/app/modules/job-offer/store/job-offer.selectors';
import { IJobOfferState } from 'src/app/modules/job-offer/store/job-offer.state';
import { getSettingJobSelectors } from 'src/app/modules/settings/settings-job-offer/store/setting-job.selector';
import { ISettingJobState } from 'src/app/modules/settings/settings-job-offer/store/setting-job.state';

@Component({
  selector: 'app-follow-up-service',
  templateUrl: './follow-up-service.component.html',
  styleUrls: ['./follow-up-service.component.scss'],
})
export class FollowUpServiceComponent implements OnInit {
  panelOpenState = true;

  dataJobOfferState$: Observable<IJobOfferState>;
  dataJobOfferState: IJobOfferState;

  dataStoreSettingJob$: Observable<ISettingJobState>;
  dataStoreSettingJob: ISettingJobState;

  haveFollowUp: boolean = true;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getJobOfferState();
    this.getStateSettingsJob();
  }

  getJobOfferState() {
    this.dataJobOfferState$ = this.store.select(getJobOfferSelectors);
    this.dataJobOfferState$.subscribe((value) => {
      this.dataJobOfferState = {
        ...(this.dataJobOfferState = value),
      };
    });
  }

  setJobOfferStates(data: IJobOfferState) {
    this.store.dispatch(setJobOfferAction(data));
  }

  selectBasic() {
    if (this.dataJobOfferState.followupService == 'basic') {
      this.dataJobOfferState.followupService = '';
    } else {
      this.dataJobOfferState.followupService = 'basic';
      this.setJobOfferStates(this.dataJobOfferState);
    }
  }

  selectSmart() {
    if (this.dataJobOfferState.followupService == 'smart') {
      this.dataJobOfferState.followupService = '';
    } else {
      this.dataJobOfferState.followupService = 'smart';
      this.setJobOfferStates(this.dataJobOfferState);
    }
  }

  getStateSettingsJob(): void {
    this.dataStoreSettingJob$ = this.store.select(getSettingJobSelectors);
    this.dataStoreSettingJob$.subscribe((value) => {
      this.dataStoreSettingJob = {
        ...(this.dataStoreSettingJob = value),
      };

      this.haveFollowUp = true;
      if (this.dataStoreSettingJob.showFollowUp == false) {
        this.haveFollowUp = false;
      }
    });
  }
}
