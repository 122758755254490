import { createReducer, on } from '@ngrx/store';
import { initIncomeTaxAction, setIncomeTaxAction } from './incomeTax.actions';
import { IIncomeTaxState } from './incomeTax.state';

export const initialIncomeTaxState: IIncomeTaxState = {
  check: false,
  typeTax: undefined,
  refunded: undefined,
  amount: 0,
};

export const incomeTaxReduce = createReducer(
  initialIncomeTaxState,
  on(setIncomeTaxAction, (state, { check, amount, refunded, typeTax }) => ({
    ...state,
    check: check,
    amount: amount,
    refunded: refunded,
    typeTax: typeTax,
  }))
);
