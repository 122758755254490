import { createReducer, on } from '@ngrx/store';

import { IFamilyFormState } from './family-form.state';
import { setFamilyFormAction } from './family-form.actions';

export const initialFamilyFormState: IFamilyFormState = {
  check: false,
  married: {
    isSelected: undefined,
    notes: '',
  },
  children: {
    isSelected: undefined,
    notes: '',
  },
  childrenInvestment: {
    isSelected: undefined,
    notes: '',
  },
};

export const familyFormReduce = createReducer(
  initialFamilyFormState,
  on(
    setFamilyFormAction,
    (state, { check, married, children, childrenInvestment }) => ({
      ...state,
      check: check,
      married: married,
      children: children,
      childrenInvestment: childrenInvestment,
    })
  )
);
