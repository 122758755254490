import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface IIndividualDebtsState {
  check: boolean;
  typeDebt: string;
  contractValue: number;
  installmentValue: number;
  interestRate: string;
  interestRateChoose: string;
}

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  @Input() data: IIndividualDebtsState;
  @Output() value: EventEmitter<IIndividualDebtsState> =
    new EventEmitter<IIndividualDebtsState>();

  constructor() {}

  ngOnInit(): void {
    this.dataLocal = { ...this.data };
  }

  dataLocal: IIndividualDebtsState;

  typeDebtKey = this.getKey();
  contractValueKey = this.getKey();
  installmentValueKey = this.getKey();
  interestRateKey = this.getKey();
  interestRateChooseKey = this.getKey();

  getKey(): string {
    return 'componente_' + Math.random().toString(36).substring(7);
  }

  updateDataValue(value, property: string): void {
    this.dataLocal = {
      ...this.dataLocal,
      [property]: value,
    };
    this.value.emit(this.dataLocal);
  }
}
