import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getObjectiveEntrepreneurshipSelectorsState,
  getObjectiveMarriageSelectorsState,
  getObjectiveOthersSelectorsState,
  getObjectivePropertySelectorsState,
  getObjectiveRetirementSelectors,
  getObjectiveRetirementSelectorsState,
  getObjectiveTripsSelectorsState,
  getObjectiveVehiclesSelectorsState,
} from '../store/objective.selectors';
import {
  setObjectiveAction,
  setObjectivePropertyAction,
  setObjectiveRetirementAction,
} from '../store/objective.actions';
import {
  IObjectivesFormState,
  IObjectiveState,
} from '../store/objective.state';

@Component({
  selector: 'app-form-objectives',
  templateUrl: './form-objectives.component.html',
  styleUrls: ['./form-objectives.component.scss'],
})
export class FormObjectivesComponent implements OnInit {
  constructor(private store: Store) {}

  checkRetirementState$: Observable<any>;
  checkTripsState$: Observable<any>;
  checkPropertyState$: Observable<any>;
  checkVehiclesState$: Observable<any>;
  checkEntrepreneurshipState$: Observable<any>;
  checkMarriageState$: Observable<any>;
  checkOthersState$: Observable<any>;

  dataRetirementState: IObjectivesFormState;
  dataTripsState: IObjectivesFormState;
  dataPropertyState: IObjectivesFormState;
  dataVehiclesState: IObjectivesFormState;
  dataEntrepreneurshipState: IObjectivesFormState;
  dataMarriageState: IObjectivesFormState;
  dataOthersState: IObjectivesFormState;

  checkObjectiveState$: Observable<IObjectiveState>;
  objectiveState: IObjectiveState;

  ngOnInit(): void {
    this.getStates();
  }

  getStates() {
    this.checkRetirementState$ = this.store.select(
      getObjectiveRetirementSelectorsState
    );
    this.checkRetirementState$.subscribe((value) => {
      this.dataRetirementState = value;
    });

    this.checkTripsState$ = this.store.select(getObjectiveTripsSelectorsState);
    this.checkTripsState$.subscribe((value) => {
      this.dataTripsState = value;
    });

    this.checkPropertyState$ = this.store.select(
      getObjectivePropertySelectorsState
    );
    this.checkPropertyState$.subscribe((value) => {
      this.dataPropertyState = value;
    });

    this.checkVehiclesState$ = this.store.select(
      getObjectiveVehiclesSelectorsState
    );
    this.checkVehiclesState$.subscribe((value) => {
      this.dataVehiclesState = value;
    });

    this.checkEntrepreneurshipState$ = this.store.select(
      getObjectiveEntrepreneurshipSelectorsState
    );
    this.checkEntrepreneurshipState$.subscribe((value) => {
      this.dataEntrepreneurshipState = value;
    });

    this.checkMarriageState$ = this.store.select(
      getObjectiveMarriageSelectorsState
    );
    this.checkMarriageState$.subscribe((value) => {
      this.dataMarriageState = value;
    });

    this.checkOthersState$ = this.store.select(
      getObjectiveOthersSelectorsState
    );
    this.checkOthersState$.subscribe((value) => {
      this.dataOthersState = value;
    });

    this.checkObjectiveState$ = this.store.select(
      getObjectiveOthersSelectorsState
    );
    this.checkObjectiveState$.subscribe((value) => {
      this.objectiveState = {
        ...(this.objectiveState = value),
      };
    });
  }

  setState(data: IObjectiveState) {
    this.store.dispatch(setObjectiveAction(data));
  }

  checkObjective() {
    this.objectiveState.check = true;
    this.setState(this.objectiveState);
  }
}
