import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IJobOfferState } from 'src/app/modules/job-offer/store/job-offer.state';
import { getJobOfferSelectors } from 'src/app/modules/job-offer/store/job-offer.selectors';

import { getSettingsSelectors } from 'src/app/modules/settings/store/settings.selectors';
import { ISettingsState } from 'src/app/modules/settings/store/settings.state';

@Component({
  selector: 'app-job-offer-pdf',
  templateUrl: './job-offer-pdf.component.html',
  styleUrls: ['./job-offer-pdf.component.scss'],
})
export class JobOfferPdfComponent implements OnInit {
  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  dataJobOfferState$: Observable<IJobOfferState>;
  dataJobOfferState: IJobOfferState;

  consultantProjectValueBr: string;
  financialConsultantTotalValueBr: string;
  financialConsultantInstallmentValueBr: string;
  financialConsultantWithDiscountBr: string;

  followUpServiceValueBr: string;
  advisorTotalValueBr: string;
  advisorInstallmentValueBr: string;
  advisorWithDiscountBr: string;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getJobOfferState();
    this.getState();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = {
        ...(this.dataStoreSetting = value),
      };

      this.consultantProjectValueBr = this.formatToBRL(
        this.dataStoreSetting.financialConsultantValue
      );
      this.financialConsultantTotalValueBr = this.formatToBRL(
        this.dataStoreSetting.financialConsultantValue
      );

      this.financialConsultantInstallmentValueBr = this.formatToBRL(
        this.dataStoreSetting.installmentFinancialConsultantValue
      );
      this.financialConsultantWithDiscountBr = this.formatToBRL(
        this.dataStoreSetting.financialConsultantValueWithDiscount
      );

      this.followUpServiceValueBr = this.formatToBRL(
        this.dataStoreSetting.followUpServiceValue
      );
      this.advisorTotalValueBr = this.formatToBRL(
        this.dataStoreSetting.financialConsultantValue +
          this.dataStoreSetting.followUpServiceValue
      );

      this.advisorInstallmentValueBr = this.formatToBRL(
        this.dataStoreSetting.installmentAdvisorValue
      );
      this.advisorWithDiscountBr = this.formatToBRL(
        this.dataStoreSetting.advisorValueWithDiscount
      );
    });
  }

  formatToBRL(value: number): string {
    if (isNaN(value)) {
      value = 0;
    }

    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  getJobOfferState() {
    this.dataJobOfferState$ = this.store.select(getJobOfferSelectors);

    this.dataJobOfferState$.subscribe((value) => {
      this.dataJobOfferState = {
        ...(this.dataJobOfferState = value),
      };
    });
  }
}
