import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IInvestmentsState } from './investments.state';

export const getInvestmentsSelectorsState =
  createFeatureSelector<IInvestmentsState>('investmentsReduce');

export const getInvestmentsSelectors = createSelector(
  getInvestmentsSelectorsState,
  (store: IInvestmentsState) => {
    return store;
  }
);
