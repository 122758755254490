import { Component, OnInit } from '@angular/core';
import { IAssetsState } from './store/assets.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { setAssetsAction } from './store/assets.actions';
import { getAssetsSelectors } from './store/assets.selectors';

@Component({
  selector: 'app-asset-projection',
  templateUrl: './asset-projection.component.html',
  styleUrls: ['./asset-projection.component.scss'],
})
export class AssetProjectionComponent implements OnInit {
  constructor(private store: Store) {}

  dataState$: Observable<IAssetsState>;
  dataState: IAssetsState;

  ngOnInit(): void {
    this.getState();
    this.setValuesForm();
  }

  startTrial(): void {
    this.setState(this.dataState);
  }

  setState(data: IAssetsState) {
    this.store.dispatch(setAssetsAction(data));
  }

  getState() {
    this.dataState$ = this.store.select(getAssetsSelectors);

    this.dataState$.subscribe((value) => {
      this.dataState = {
        ...(this.dataState = value),
      };
    });
  }

  setValuesForm(): void {
    this.dataState.check = true;
    this.setState(this.dataState);
  }
}
