import { createReducer, on } from '@ngrx/store';
import { setSettingsAction } from './settings.actions';
import { ISettingsState } from './settings.state';

export const initialSettingsState: ISettingsState = {
  check: false,
  defaultPricing: true,
  hideDeliverables: false,
  manualValue: 3000,
  percentValue: 3,
  followUpServiceValue: 1200,
  financialConsultantValue: 3000,
  advisorValue: 4200,
  simulatedValue: 0,
  financialConsultantSimulatedValue: 0,
  installments: 12,
  installmentFinancialConsultantValue: 500,
  installmentAdvisorValue: 700,
  percentualDiscount: 10,
  financialConsultantValueWithDiscount: 2700,
  advisorValueWithDiscount: 3780,
  jobOfferCardSelect: '',
};

export const settingsReduce = createReducer(
  initialSettingsState,
  on(setSettingsAction, (state, data: ISettingsState) => ({
    ...state,
    ...data,
  }))
);
