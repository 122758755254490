import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IInssState } from './inss.state';

export const getInssSelectorsState =
  createFeatureSelector<IInssState>('inssReduce');

export const getInssSelectors = createSelector(
  getInssSelectorsState,
  (store: IInssState) => {
    return store;
  }
);
