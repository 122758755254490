import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ISettingsState } from '../store/settings.state';
import { getSettingsSelectors } from '../store/settings.selectors';
import { IOfferState } from './store/offer.state';
import { IBudgetState } from '../../collect-data/components/budget/store/budget.state';
import { getBudgetSelectors } from '../../collect-data/components/budget/store/budget.selectors';
import { setSettingsAction } from '../store/settings.actions';
import { MatDialog } from '@angular/material/dialog';
import { ConsultantProjectComponent } from '../info/consultant-project/consultant-project.component';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { InputToastComponent } from 'src/app/components/input-toast/input-toast.component';

import { setOfferAction } from './store/offer.actions';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
})
export class OfferComponent implements OnInit {
  check: boolean = true;

  dataStoreSetting$: Observable<any>;
  dataStoreSetting: ISettingsState;

  dataBudgetState$: Observable<IBudgetState>;
  dataBudgetState: IBudgetState;

  financialConsultantValue: string;
  advisorValue: string;

  _manualValueString: string;
  _percentValueString: string;
  _followUpServiceValueString: string;
  _simulatedValueString: string;
  financialConsultantValueSimulated: string;
  advisorValueSimulated: string;

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 2;

  constructor(
    private store: Store,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getBudgetState();
    this.getState();
  }

  getState(): void {
    this.dataStoreSetting$ = this.store.select(getSettingsSelectors);
    this.dataStoreSetting$.subscribe((value) => {
      this.dataStoreSetting = { ...value };
      this.validateValueSimulated();
      this.updateValues();
    });
    this.setState(this.dataStoreSetting);
  }

  getBudgetState() {
    this.dataBudgetState$ = this.store.select(getBudgetSelectors);
    this.dataBudgetState$.subscribe((value) => {
      this.dataBudgetState = { ...value };
    });
  }

  setState(data: ISettingsState) {
    this.store.dispatch(setSettingsAction(data));
  }

  setOfferState(data: IOfferState) {
    this.store.dispatch(setOfferAction(data));
  }

  openDialogConsultantProject() {
    const dialogRef = this.dialog.open(ConsultantProjectComponent);
    dialogRef.afterClosed().subscribe();
  }

  // GETTERS AND SETTERS

  get manualValueString(): string {
    return this._manualValueString;
  }

  set manualValueString(value: string) {
    this._manualValueString = this.applyNumberMask(value);
    this.dataStoreSetting.manualValue = this.convertCurrencyToInteger(
      this._manualValueString
    );
    this.validateManualValue();
  }

  get percentValueString(): string {
    return this._percentValueString;
  }

  set percentValueString(value: string) {
    this._percentValueString = this.applyPercentMask(value);
    this.dataStoreSetting.percentValue = this.convertPercentToInteger(
      this._percentValueString
    );
    this.validatePercentValue();
  }

  get followUpServiceValueString(): string {
    return this._followUpServiceValueString;
  }

  set followUpServiceValueString(value: string) {
    this._followUpServiceValueString = this.applyNumberMask(value);
    this.dataStoreSetting.followUpServiceValue = this.convertCurrencyToInteger(
      this._followUpServiceValueString
    );
    this.validateFollowUpValue();
  }

  get simulatedValueString(): string {
    return this._simulatedValueString;
  }

  set simulatedValueString(value: string) {
    this._simulatedValueString = this.applyNumberMask(value);
    this.dataStoreSetting.simulatedValue = this.convertCurrencyToInteger(
      this.simulatedValueString
    );

    this.validateValueSimulated();
    this.setState(this.dataStoreSetting);
  }

  //FUNÇÕES DE VALIDAÇÃO

  validateManualValue(): void {
    if (this.dataStoreSetting.manualValue < 3000) {
      // this.dataStoreSetting.manualValue = 3000;
    }

    this.updateAllValues();
  }

  validatePercentValue(): void {
    if (this.dataStoreSetting.percentValue < 0) {
      this.dataStoreSetting.percentValue = 0;
    } else if (this.dataStoreSetting.percentValue > 100) {
      this.dataStoreSetting.percentValue = 100;
    }
    this.updateAllValues();
  }

  validateFollowUpValue(): void {
    if (this.dataStoreSetting.followUpServiceValue < 0) {
      this.dataStoreSetting.followUpServiceValue = 0;
    }
    this.updateAllValues();
  }

  validateValueSimulated(): void {
    this.dataStoreSetting.financialConsultantSimulatedValue =
      this.dataStoreSetting.simulatedValue *
      (this.dataStoreSetting.percentValue / 100);

    let totalValue = this.dataStoreSetting.financialConsultantSimulatedValue;

    this.financialConsultantValueSimulated = this.convertValue(
      this.dataStoreSetting.manualValue
    );
    this.advisorValueSimulated = this.convertValue(
      this.dataStoreSetting.manualValue +
        this.dataStoreSetting.followUpServiceValue
    );
    this.check = false;

    if (this.dataStoreSetting.manualValue < totalValue) {
      this.financialConsultantValueSimulated = this.convertValue(totalValue);

      this.advisorValueSimulated = this.convertValue(
        totalValue + this.dataStoreSetting.followUpServiceValue
      );
      this.check = true;
    }
  }

  validateFinancialConsultantValue(): void {
    let annualRecipeValue = this.dataBudgetState.annualRecipe;
    let totalValue =
      annualRecipeValue * (this.dataStoreSetting.percentValue / 100);

    if (totalValue > this.dataStoreSetting.manualValue) {
      this.financialConsultantValue = this.convertValue(totalValue);
    } else {
      this.financialConsultantValue = this.convertValue(
        this.dataStoreSetting.manualValue
      );
    }

    this.updateFinancialConsultantValue();
  }

  validateAdvisorValue(): void {
    this.advisorValue = this.convertValue(
      this.convertFormattedValueToNumber(this.financialConsultantValue) +
        this.dataStoreSetting.followUpServiceValue
    );
    this.updateAdvisorValue();
  }

  //FUNÇÕES DE ATUALIZAÇÃO

  updateValues(): void {
    this.financialConsultantValue = this.convertValue(
      this.dataStoreSetting.financialConsultantValue
    );
    this.advisorValue = this.convertValue(
      this.dataStoreSetting.financialConsultantValue +
        this.dataStoreSetting.followUpServiceValue
    );
    this._manualValueString = this.applyNumberMask(
      this.dataStoreSetting.manualValue.toString()
    );
    this._percentValueString = this.applyPercentMask(
      this.dataStoreSetting.percentValue.toString()
    );
    this._followUpServiceValueString = this.applyNumberMask(
      this.dataStoreSetting.followUpServiceValue.toString()
    );
    this._simulatedValueString = this.applyNumberMask(
      this.dataStoreSetting.simulatedValue.toString()
    );
  }

  async updateAllValues() {
    await this.validateFinancialConsultantValue();
    await this.validateAdvisorValue();
    await this.validateValueSimulated();
    await this.setState(this.dataStoreSetting);
  }

  updateFinancialConsultantValue(): void {
    this.dataStoreSetting.financialConsultantValue =
      this.convertFormattedValueToNumber(this.financialConsultantValue);

    this.updateCreditCardValues();
    this.updateInCashValues();
  }

  updateAdvisorValue(): void {
    this.dataStoreSetting.advisorValue = this.convertFormattedValueToNumber(
      this.advisorValue
    );
    this.updateCreditCardValues();
    this.updateInCashValues();
  }

  updateCreditCardValues(): void {
    this.dataStoreSetting.installmentFinancialConsultantValue =
      this.dataStoreSetting.financialConsultantValue /
      this.dataStoreSetting.installments;
    this.dataStoreSetting.installmentAdvisorValue =
      this.dataStoreSetting.advisorValue / this.dataStoreSetting.installments;
  }

  updateInCashValues(): void {
    this.dataStoreSetting.financialConsultantValueWithDiscount =
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.financialConsultantValue
      );
    this.dataStoreSetting.advisorValueWithDiscount =
      this.applyingDiscountPercentage(
        this.dataStoreSetting.percentualDiscount,
        this.dataStoreSetting.advisorValue
      );
  }

  // FUNÇÕES DE CONVERÇÃO

  convertValue(value: number): string {
    let response = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value));

    return response;
  }

  convertFormattedValueToNumber(formattedValue: string): number {
    let cleanedValue = formattedValue.replace(/[^\d,-]/g, '');
    cleanedValue = cleanedValue.replace(',', '.');
    return parseFloat(cleanedValue);
  }

  convertCurrencyToInteger(numberString: string): number {
    const cleanedString = numberString.replace(/\D/g, '');
    return parseInt(cleanedString, 10);
  }

  convertPercentToInteger(percentString: string): number {
    const cleanedString = percentString.replace(/\D/g, '');
    return parseInt(cleanedString, 10);
  }

  //FUNÇÕES DE APLICAÇÃO

  applyingDiscountPercentage(percentage: number, value: number): number {
    if (percentage < 0 || percentage > 100) {
      return value;
    }
    if (value < 0) {
      return value;
    }
    const discount = (percentage / 100) * value;
    const discountedValue = value - discount;
    return discountedValue;
  }

  applyNumberMask(value: string): string {
    value = value.replace(/\D/g, '');
    const parts = value
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g);
    const formattedValue = parts
      ? parts.join('.').split('').reverse().join('')
      : value;
    return `R$ ${formattedValue}`;
  }

  applyPercentMask(value: string): string {
    value = value.replace(/\D/g, '');
    return `${value}%`;
  }

  //ALERTAS

  manualValueAlert() {
    if (this.dataStoreSetting.manualValue < 3000) {
      let title = 'Preço do Projeto de Consultoria inválido';
      let message =
        'O valor preenchido deve ser igual ou maior que R$ 3.000,00.';
      this._snackBar.openFromComponent(InputToastComponent, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.durationInSeconds * 1000,
        panelClass: 'alert-snackbar',
        data: { title, message },
      });
      this.dataStoreSetting.manualValue = 3000;
      this.setState(this.dataStoreSetting);
    }
  }

  followUpValueAlert() {
    if (this.dataStoreSetting.followUpServiceValue < 1200) {
      let title = 'Preço do Serviço de Acompanhamento inválido';
      let message =
        'O valor preenchido deve ser igual ou maior que R$ 1.200,00.';
      this._snackBar.openFromComponent(InputToastComponent, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.durationInSeconds * 1000,
        panelClass: 'alert-snackbar',
        data: { title, message },
      });
      this.dataStoreSetting.followUpServiceValue = 1200;
      this.setState(this.dataStoreSetting);
    }
  }
}
