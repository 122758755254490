import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISettingJobState } from './setting-job.state';

export const getSettingJobSelectorState =
  createFeatureSelector<ISettingJobState>('SettingJobReduce');

export const getSettingJobSelectors = createSelector(
  getSettingJobSelectorState,
  (store: any) => {
    return store;
  }
);
