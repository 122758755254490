import { createReducer, on } from '@ngrx/store';
import { setPensionAction } from './pension.actions';
import { IPensionState } from './pension.state';

export const initialPensionState: IPensionState = {
  check: false,
  pension: undefined,
  typePension: undefined,
  amount: 0,
  notes: undefined,
  interest: false,
};

export const pensionReduce = createReducer(
  initialPensionState,
  on(
    setPensionAction,
    (state, { check, pension, typePension, amount, notes, interest }) => ({
      ...state,
      check: check,
      pension: pension,
      typePension: typePension,
      amount: amount,
      notes: notes,
      interest: interest,
    })
  )
);
