<div class="card border-light-subtle shadow-sm p-3">
  <div class="d-flex flex-column gap-3">
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0001 1V9C28.0001 9.26522 27.8947 9.51957 27.7072 9.70711C27.5196 9.89464 27.2653 10 27.0001 10C26.7349 10 26.4805 9.89464 26.293 9.70711C26.1054 9.51957 26.0001 9.26522 26.0001 9V3.41375L15.7076 13.7075C15.6147 13.8005 15.5044 13.8742 15.383 13.9246C15.2616 13.9749 15.1315 14.0008 15.0001 14.0008C14.8687 14.0008 14.7385 13.9749 14.6171 13.9246C14.4957 13.8742 14.3854 13.8005 14.2926 13.7075L10.0001 9.41375L1.70757 17.7075C1.51993 17.8951 1.26543 18.0006 1.00007 18.0006C0.734704 18.0006 0.480209 17.8951 0.292568 17.7075C0.104927 17.5199 -0.000488281 17.2654 -0.000488281 17C-0.000488281 16.7346 0.104927 16.4801 0.292568 16.2925L9.29257 7.2925C9.38544 7.19952 9.49573 7.12576 9.61713 7.07544C9.73853 7.02512 9.86865 6.99921 10.0001 6.99921C10.1315 6.99921 10.2616 7.02512 10.383 7.07544C10.5044 7.12576 10.6147 7.19952 10.7076 7.2925L15.0001 11.5863L24.5863 2H19.0001C18.7349 2 18.4805 1.89464 18.293 1.70711C18.1054 1.51957 18.0001 1.26522 18.0001 1C18.0001 0.734784 18.1054 0.48043 18.293 0.292893C18.4805 0.105357 18.7349 0 19.0001 0H27.0001C27.2653 0 27.5196 0.105357 27.7072 0.292893C27.8947 0.48043 28.0001 0.734784 28.0001 1Z"
        fill="#0FC4DC"
      />
    </svg>
    <h2 class="mt-3 title">Consultoria Financeira</h2>
  </div>

  <div class="mb-4">
    <p class="m-0 fw-medium">Inclui:</p>
    <div class="d-flex justify-content-between mt-2 mb-3">
      <span>Projeto de Consultoria</span>
      <strong>{{ financialConsultantValueBr }}</strong>
    </div>
    <div class="d-flex justify-content-between mt-5 pt-2 mb-3 border-top">
      <span>Total</span>
      <strong>{{ financialConsultantValueBr }}</strong>
    </div>
  </div>

  <div class="d-flex flex-column gap-3 pt-2">
    <div
      style="min-height: 138px; cursor: pointer"
      class="card p-3 border-light-subtle shadow-sm"
      [ngClass]="isCreditCardSelected ? 'option-selected' : 'card-money'"
      (click)="
        isCreditCardSelected = !isCreditCardSelected; creditCardSelected($event)
      "
    >
      <div class="d-flex justify-content-between">
        <div class="d-flex gap-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 4.5H3C2.60218 4.5 2.22064 4.65804 1.93934 4.93934C1.65804 5.22064 1.5 5.60218 1.5 6V18C1.5 18.3978 1.65804 18.7794 1.93934 19.0607C2.22064 19.342 2.60218 19.5 3 19.5H21C21.3978 19.5 21.7794 19.342 22.0607 19.0607C22.342 18.7794 22.5 18.3978 22.5 18V6C22.5 5.60218 22.342 5.22064 22.0607 4.93934C21.7794 4.65804 21.3978 4.5 21 4.5ZM21 6V8.25H3V6H21ZM21 18H3V9.75H21V18ZM19.5 15.75C19.5 15.9489 19.421 16.1397 19.2803 16.2803C19.1397 16.421 18.9489 16.5 18.75 16.5H15.75C15.5511 16.5 15.3603 16.421 15.2197 16.2803C15.079 16.1397 15 15.9489 15 15.75C15 15.5511 15.079 15.3603 15.2197 15.2197C15.3603 15.079 15.5511 15 15.75 15H18.75C18.9489 15 19.1397 15.079 19.2803 15.2197C19.421 15.3603 19.5 15.5511 19.5 15.75ZM13.5 15.75C13.5 15.9489 13.421 16.1397 13.2803 16.2803C13.1397 16.421 12.9489 16.5 12.75 16.5H11.25C11.0511 16.5 10.8603 16.421 10.7197 16.2803C10.579 16.1397 10.5 15.9489 10.5 15.75C10.5 15.5511 10.579 15.3603 10.7197 15.2197C10.8603 15.079 11.0511 15 11.25 15H12.75C12.9489 15 13.1397 15.079 13.2803 15.2197C13.421 15.3603 13.5 15.5511 13.5 15.75Z"
              fill="#00658F"
            />
          </svg>

          <h4 class="fw-medium">Cartão de Crédito</h4>
        </div>
        <input
          style="width: 20px"
          [(ngModel)]="isCreditCardSelected"
          (change)="creditCardSelected($event)"
          type="checkbox"
          name=""
          id="creditCardSelected"
        />
      </div>

      <div>
        <span class="fs-5">
          Até {{ this.dataStoreSetting.installments }}x de
          <span class="fs-3 fw-medium">{{
            financialConsultantInstallmentValueBr
          }}</span></span
        >
      </div>
    </div>

    <div
      style="min-height: 138px; cursor: pointer"
      class="card p-3 border-light-subtle shadow-sm"
      [ngClass]="isInCashSelected ? 'option-selected' : 'card-money'"
      (click)="isInCashSelected = !isInCashSelected; inCashSelected($event)"
    >
      <div class="d-flex justify-content-between">
        <div class="d-flex gap-2">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 8.25C11.2583 8.25 10.5333 8.46993 9.91661 8.88199C9.29993 9.29404 8.81928 9.87971 8.53545 10.5649C8.25162 11.2502 8.17736 12.0042 8.32205 12.7316C8.46675 13.459 8.8239 14.1272 9.34835 14.6517C9.8728 15.1761 10.541 15.5333 11.2684 15.6779C11.9958 15.8226 12.7498 15.7484 13.4351 15.4645C14.1203 15.1807 14.706 14.7001 15.118 14.0834C15.5301 13.4667 15.75 12.7417 15.75 12C15.75 11.0054 15.3549 10.0516 14.6517 9.34835C13.9484 8.64509 12.9946 8.25 12 8.25ZM12 14.25C11.555 14.25 11.12 14.118 10.75 13.8708C10.38 13.6236 10.0916 13.2722 9.92127 12.861C9.75097 12.4499 9.70642 11.9975 9.79323 11.561C9.88005 11.1246 10.0943 10.7237 10.409 10.409C10.7237 10.0943 11.1246 9.88005 11.561 9.79323C11.9975 9.70642 12.4499 9.75097 12.861 9.92127C13.2722 10.0916 13.6236 10.38 13.8708 10.75C14.118 11.12 14.25 11.555 14.25 12C14.25 12.5967 14.0129 13.169 13.591 13.591C13.169 14.0129 12.5967 14.25 12 14.25ZM22.5 5.25H1.5C1.30109 5.25 1.11032 5.32902 0.96967 5.46967C0.829018 5.61032 0.75 5.80109 0.75 6V18C0.75 18.1989 0.829018 18.3897 0.96967 18.5303C1.11032 18.671 1.30109 18.75 1.5 18.75H22.5C22.6989 18.75 22.8897 18.671 23.0303 18.5303C23.171 18.3897 23.25 18.1989 23.25 18V6C23.25 5.80109 23.171 5.61032 23.0303 5.46967C22.8897 5.32902 22.6989 5.25 22.5 5.25ZM18.1547 17.25H5.84531C5.5935 16.3984 5.13263 15.6233 4.50467 14.9953C3.87671 14.3674 3.10162 13.9065 2.25 13.6547V10.3453C3.10162 10.0935 3.87671 9.63263 4.50467 9.00467C5.13263 8.37671 5.5935 7.60162 5.84531 6.75H18.1547C18.4065 7.60162 18.8674 8.37671 19.4953 9.00467C20.1233 9.63263 20.8984 10.0935 21.75 10.3453V13.6547C20.8984 13.9065 20.1233 14.3674 19.4953 14.9953C18.8674 15.6233 18.4065 16.3984 18.1547 17.25ZM21.75 8.75344C20.8504 8.36662 20.1334 7.64959 19.7466 6.75H21.75V8.75344ZM4.25344 6.75C3.86662 7.64959 3.14959 8.36662 2.25 8.75344V6.75H4.25344ZM2.25 15.2466C3.14959 15.6334 3.86662 16.3504 4.25344 17.25H2.25V15.2466ZM19.7466 17.25C20.1334 16.3504 20.8504 15.6334 21.75 15.2466V17.25H19.7466Z"
              fill="#00658F"
            />
          </svg>
          <h4 class="fw-medium">À vista (PIX ou boleto)</h4>
        </div>
        <input
          style="width: 20px"
          [(ngModel)]="isInCashSelected"
          (change)="inCashSelected($event)"
          type="checkbox"
          name=""
          id=""
        />
      </div>
      <div>
        <span
          class="fs-6 fw-medium text-dark-emphasis text-decoration-line-through"
        >
          {{ financialConsultantValueBr }}</span
        >
      </div>
      <div class="d-flex align-content-center align-items-center gap-2 mt-2">
        <span class="fs-3 fw-medium">
          <p class="discountedvalue">{{financialConsultantValueWithDiscountBr}}</p>
          
        </span>
        <p  class="m-0 border economity px-2 py-1 rounded fw-bold">
          Economize {{ dataStoreSetting.percentualDiscount }}%
        </p>
      </div>
    </div>
  </div>
</div>
