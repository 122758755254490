import { createReducer, on } from '@ngrx/store';

import { ICreditCardState } from './credit-card.state';
import {
  initCreditCardAction,
  setCreditCardAction,
} from './credit-card.actions';

export const initialCreditCardState: ICreditCardState = {
  check: false,
  creditCard: undefined,
  cards: 0,
  miles: undefined,
};

export const creditCardReduce = createReducer(
  initialCreditCardState,
  on(setCreditCardAction, (state, { check, creditCard, cards, miles }) => ({
    ...state,
    check: check,
    creditCard: creditCard,
    cards: cards,
    miles: miles,
  }))
);
