import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IObjectivesFormState } from '../../../store/objective.state';
import { setObjectiveOthersAction } from '../../../store/objective.actions';
import { getObjectiveOthersSelectors } from '../../../store/objective.selectors';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss'],
})
export class OthersComponent implements OnInit {
  panelOpenState = true;
  dataStoreOthers$: Observable<any>;
  dataStoreOthers: IObjectivesFormState;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getState();
  }

  getState(): void {
    this.dataStoreOthers$ = this.store.select(getObjectiveOthersSelectors);
    this.dataStoreOthers$.subscribe((value) => {
      this.dataStoreOthers = {
        ...(this.dataStoreOthers = value),
      };
    });
  }

  unCheck(): void {
    this.dataStoreOthers = {
      ...this.dataStoreOthers,
      isSelected: !this.dataStoreOthers.isSelected,
    };
    this.setState(this.dataStoreOthers);
  }

  setState(data: IObjectivesFormState) {
    this.store.dispatch(setObjectiveOthersAction(data));
  }

  setValuesForm(): void {
    if (isNaN(this.dataStoreOthers.maximumInvestment)) {
      this.dataStoreOthers.maximumInvestment = 0;
    }

    if (isNaN(this.dataStoreOthers.minimumInvestment)) {
      this.dataStoreOthers.minimumInvestment = 0;
    }

    this.dataStoreOthers.check =
      this.dataStoreOthers.description &&
      this.dataStoreOthers.date &&
      this.dataStoreOthers.maximumInvestment
        ? true
        : false;
    this.setState(this.dataStoreOthers);
  }

  updateAmountMin(event): void {
    this.dataStoreOthers.minimumInvestment = event;
    this.setValuesForm();
  }

  updateAmountMax(event): void {
    this.dataStoreOthers.maximumInvestment = event;
    this.setValuesForm();
  }
}
