import { createAction, props } from '@ngrx/store';
import { IFamilyFormState } from './family-form.state';

export const initFamilyFormAction = createAction(
  '[FamilyForm Page] FamilyForm page'
);

export const setFamilyFormAction = createAction(
  '[FamilyForm Page] set data',
  props<IFamilyFormState>()
);
