<div class="" style="max-width: 590px">
  <div class="d-flex justify-content-between mb-3">
    <h2 class="m-0 p-0" mat-dialog-title>Projeto de Consultoria</h2>

    <svg
      mat-dialog-close
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-x-lg"
      viewBox="0 0 16 16"
    >
      <path
        d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"
      />
    </svg>
  </div>
  <mat-dialog-content class="mat-typography">
    <p class="fw-medium">
      Para trazer equilíbrio entre o que é justo para você e seu cliente na
      precificação do Projeto de Consultoria, trouxemos as possibilidades de
      preenchimento: <strong> Valor Fixo </strong>ou<strong>
        Cálculo por Renda Bruta Anual (RBA).
      </strong>
    </p>
    <p class="fw-medium">
      O preço final do item Projeto de Consultoria será sempre o resultado do
      maior valor entre as duas possibilidades de preenchimento, tendo como
      valor mínimo R$ 3.000,00.
    </p>

    <div class="border my-3 p-2">
      <strong>Exemplo: </strong>
      <ul class="mt-2 fw-medium">
        <li>
          Preencho o valor <strong>Valor Fixo</strong> de
          <strong>R$4.000</strong> ;
        </li>
        <li>
          Porém, já realizei a etapa de Orçamento com o Cliente e a RBA foi de
          R$100.000;
        </li>
        <li>
          Considerei <strong>5% da RBA</strong>,
          <strong>resultando em R$5.000</strong>, e esse é o valor final exibido
          já que Cálculo por RBA é <strong>maior</strong> que o Valor Fixo.
        </li>
      </ul>
    </div>

    <div>
      <strong>Observações: </strong>
      <p class="fw-normal">
        Ao zerar a porcentagem da Renda Bruta Anual, o sistema selecionará o
        <strong>Valor Fixo</strong> automaticamente.
      </p>
    </div>
  </mat-dialog-content>

  <div class="d-grid justify-content-end w-100 mt-2">
    <button
      class="btn btn-primary order-2 order-md-0 order-lg-0"
      mat-dialog-close
    >
      Entendi
    </button>
  </div>
</div>
