import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ISettingState } from './setting.state';

export const getSettingSelectorState =
  createFeatureSelector<ISettingState>('settingReduce');

export const getSettingSelectors = createSelector(
  getSettingSelectorState,
  (store: any) => {
    return store;
  }
);
