<div class="mt-3">
  <mat-accordion>
    <mat-expansion-panel
      to
      id="intelligence-panel"
      class="w-100 mat-elevation-z0"
      style="background-color: transparent"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      expanded="false"
    >
      <mat-expansion-panel-header
        class="custom-border custom-border-left bg-white shadow-sm"
      >
        <mat-panel-title
          class="d-flex align-items-center align-content-center gap-2"
        >
          <h2 class="fw-semibold m-0">Adesão</h2>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row mt-3">
        <div class="col-sm-12 col-md-6 mb-4" *ngFor="let card of cards">
          <div
            class="card mb-3 border-1 border-light-subtle shadow-sm"
            style="min-height: 245px; cursor: pointer"
          >
            <div class="row g-0 m-2">
              <div class="col-md-4" *ngIf="card.image">
                <img
                  [src]="card.image"
                  class="img-fluid rounded-start"
                  [alt]="card.title"
                />
              </div>
              <div class="col-auto">
                <div class="card-body">
                  <div class="row">
                    <div
                      class="flex justify-content-start mt-2 mb-4"
                      [innerHTML]="card.icon"
                    ></div>
                  </div>
                  <h3 class="card-title fw-semibold">{{ card.title }}</h3>
                  <p class="card-text" *ngFor="let text of card.content_text">
                    {{ text }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
