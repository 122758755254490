import { Component, OnInit } from '@angular/core';

import { getContactSelectors } from 'src/app/modules/collect-data/components/end-of-collection/store/contact.selectors';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IContactState } from 'src/app/modules/collect-data/components/end-of-collection/store/contact.state';

@Component({
  selector: 'app-recomendation-pdf',
  templateUrl: './recomendation-pdf.component.html',
  styleUrls: ['./recomendation-pdf.component.scss'],
})
export class RecomendationPdfComponent implements OnInit {
  dataStoreRecomendation$: Observable<any>;
  dataStoreRecomendation: IContactState;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getStateRecomendation();
  }

  getStateRecomendation() {
    this.dataStoreRecomendation$ = this.store.select(getContactSelectors);
    this.dataStoreRecomendation$.subscribe((value) => {
      this.dataStoreRecomendation = {
        ...(this.dataStoreRecomendation = value),
      };
    });
  }
}
