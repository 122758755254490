import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IframeSlideInvestimentsComponent } from '../iframe-slide-investiments/iframe-slide-investiments.component';

import { getConsultingSelectors } from './store/consulting.selectors';
import { setConsultingAction } from './store/consulting.actions';
import { IConsultingState } from './store/consulting.state';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-consulting-model',
  templateUrl: './consulting-model.component.html',
  styleUrls: ['./consulting-model.component.scss'],
})
export class ConsultingModelComponent implements OnInit {
  @ViewChild('meuIframe') meuIframe: ElementRef;

  consulting: boolean;
  iframeVisivel: boolean = false;
  consultingState: IConsultingState;
  consultingState$: Observable<any>;

  constructor(public dialog: MatDialog, private store: Store) {}

  openDialog() {
    const dialogRef = this.dialog.open(IframeSlideInvestimentsComponent, {
      panelClass: 'iframe-dialog',
    });
    dialogRef.afterClosed().subscribe();
  }

  ativarModoTelaCheia() {
    this.iframeVisivel = true;
    const iframeElement = this.meuIframe.nativeElement;
    if (iframeElement.requestFullscreen) {
      iframeElement.requestFullscreen();
    } else if (iframeElement.mozRequestFullScreen) {
      iframeElement.mozRequestFullScreen();
    } else if (iframeElement.webkitRequestFullscreen) {
      iframeElement.webkitRequestFullscreen();
    }
  }

  @Output() ConsultingInterest = new EventEmitter<boolean>();

  updateConsulting() {
    this.ConsultingInterest.emit(this.consulting);
    this.consultingState.interest = this.consulting;
    this.setState(this.consultingState);
  }

  getState() {
    this.consultingState$ = this.store.select(getConsultingSelectors);
    this.consultingState$.subscribe((value) => {
      this.consultingState = {
        ...(this.consultingState = value),
      };
    });
  }

  setState(data: IConsultingState) {
    this.store.dispatch(setConsultingAction(data));
  }

  ngOnInit(): void {
    this.getState();
    this.consulting = this.consultingState.interest;
  }
}
