import { createReducer, on } from '@ngrx/store';
import { setVehiclesAction } from './vehicles.actions';
import { IVehiclesState } from './vehicles.state';

export const initialVehiclesState: IVehiclesState = {
  check: false,
  vehicles: undefined,
  listVehicles: [],
};

export const vehiclesReduce = createReducer(
  initialVehiclesState,
  on(setVehiclesAction, (state, { check, vehicles, listVehicles }) => ({
    ...state,
    check: check,
    vehicles: vehicles,
    listVehicles: listVehicles,
  }))
);
