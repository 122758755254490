<div class="modal-end">
  <span class="" style="font-weight: bold"
    >Para iniciar uma novo Guia, os dados do último Guia realizado precisam ser
    apagados. Após esta ação, você não poderá recuperar esses dados.</span
  >

  <div class="d-grid d-md-flex justify-content-md-between w-100">
    <button
      class="btn btn-outline-primary order-2 order-md-0 order-lg-0"
      mat-dialog-close
    >
      CANCELAR
    </button>
    <button
      class="btn btn-danger mb-2 mb-sm-2 mb-md-0"
      mat-dialog-close
      [routerLink]="'/'"
      (click)="restartApplication()"
    >
      APAGAR E INICIAR
    </button>
  </div>
</div>
